import React, { useState } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
// import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../../AllCssFile.css"; 
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link,useNavigate } from "react-router-dom";
import { addBlogcategoryformData} from  "../../../Component/Controller/Blog"; 
import Addblogcategorypopup from "./Addblogcategorypopup"; 
function BlogcategoryCreate() { 
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState('');
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen); 
  };
  const [formData, setFormData] = useState({
    blog_category_name: "",
    
  });

  
  const handleInputChange = (value, field) => {
    if (field === 'blog_category_name') {
      setFormData((prevVal) => ({
        ...prevVal,
        blog_category_name: value,
      }));
    } else {
      const name = field;
      const inputValue = value;
    
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: '',
      }));
    }
  };
  
  
  
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.blog_category_name) {
        setErrors((preError) => ({
          ...preError,
          blog_category_name: 'Category is required.',
        }));
      }else{
        setIsSubmitting(true);
        const updateResult = await addBlogcategoryformData(formData, adminInformation.token);
        if(updateResult.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
                blog_category_name: "",
            }));
            setIsSubmitting(false);
            togglePopUp();
        }else{
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
   /*************************************************************
          End Function
   *************************************************************/
  // const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Add Blog Category</h5>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form onSubmit={handleSubmit} encType="multipart/form-data">
                          <div className="form-group">
                            <label htmlFor="exampleInputText">Category Name<span style={{ color: 'red' }}>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              id="blog_category_name"
                              name="blog_category_name"
                              onChange={(e) => handleInputChange(e.target.value, 'blog_category_name')}
                            />
                              {errors?.blog_category_name?(<p style={{color:"red"}}>{errors?.blog_category_name}</p>):''}
                          </div>
                          <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span> 
                          Processing...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                           <Link to="/bLogCategoryListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Addblogcategorypopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
        </div>
      </div>
    </div>
  );
}
export default BlogcategoryCreate;
