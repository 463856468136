import axios from "axios";
/*****************************************************
 * This function is use to generate GET Request
 *****************************************************/
export const getRequest = async (options)=>{
    try {
        const { url } = options;
        const headers = { };
        const response = await axios.get(url, { headers });
        const data = response.data.response.result;
        return data;
    } catch (error) {
        return false;
    }
} //End of the function

/*****************************************************
 * This function is use to generate POST Request
 *****************************************************/
export const postRequest = async (options) => {
    try {
        const { url, postData = {}, headers = {} } = options;
        return await axios.post(url, postData, { headers });
    } catch (error) {
        return error;
    }
}; //End of the function
/*****************************************************
 * This function is used to generate DELETE Request
 *****************************************************/
export const deleteRequest = async (options) => {
    try {
      const { url, postData = {}, headers = {} } = options;
      return await axios.delete(url, {
        data: postData, 
        headers: headers
      });
    } catch (error) {
      return error;
    }
  };
  