import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../AllCssFile.css";
import { FaWrench } from "react-icons/fa";

function SideBar() {
  
  const [isOpenDashboard, setOpenDashboard] = useState(true);
  const [isOpenCategory, setOpenCategory] = useState(false);
  const [isOpenBlog, setOpenBlog] = useState(false);
  const [isOpenContactUs, setOpenContactUs] = useState(false); // Add state for Contact us

  const toggleDashboard = () => {
    setOpenDashboard(!isOpenDashboard);
  };

  const toggleCategory = () => {
    setOpenCategory(!isOpenCategory);
    setOpenBlog(false);
    setOpenContactUs(false); // Close other sections
  };

  const toggleBlog = () => {
    setOpenBlog(!isOpenBlog);
    setOpenCategory(false);
    setOpenContactUs(false); // Close other sections
  };

  const toggleContactUs = () => {
    setOpenContactUs(!isOpenContactUs);
    setOpenCategory(false);
    setOpenBlog(false); // Close other sections
  };

  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle = () => {
    if (
      style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <ul
            className={`${style} my_dashboard_main_div`}
            id="accordionSidebar"
          >
            <a
              className="sidebar-brand d-flex align-items-center justify-content-center"
              href="#"
            >
              <div className="sidebar-brand-icon">
                <img
                  className="dashboardimag"
                  src="../img/sportking-dark.png"
                  alt="Dashboard Icon"
                />
              </div>
              <div className="sidebar-brand-text mx-3"></div>
              <div className="text-center d-none d-md-inline">
                <button
                  className="rounded-circle border-0"
                  id="sidebarToggle"
                  onClick={changeStyle}
                ></button>
              </div>
            </a>

            <hr className="sidebar-divider my-0" />

            <li className={`nav-item ${isOpenDashboard ? "active" : ""}`}>
              <NavLink
                to="/dashboard"
                className="nav-link"
                onClick={toggleDashboard}
              >
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>

            <hr className="sidebar-divider" />

            <div className="sidebar-heading">NAVIGATION</div>

            <li className={`nav-item ${isOpenCategory ? "active" : ""}`}>
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapsePages"
                aria-expanded={isOpenCategory}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior
                  toggleCategory();
                }}
              >
                <i className="fas fa-fw fa-wrench"></i>
                <span>Master Data</span>
              </a>
              <div
                id="collapsePages"
                className={`collapse ${isOpenCategory ? "show" : ""}`}
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Master Data:</h6>
                  <NavLink
                    to="/categoryList"
                    className="collapse-item"
                    activeClassName="active-link"
                  >
                    Category
                  </NavLink>
                  <NavLink
                    to="/subcategoryList"
                    className="collapse-item"
                    activeClassName="active-link"
                  >
                   Sub Category
                  </NavLink>
                  <NavLink
                    to="/sizeList"
                    className="collapse-item"
                    activeClassName="active-link"
                  >
                   Size
                  </NavLink>
                  <NavLink
                    to="/genderList"
                    className="collapse-item"
                    activeClassName="active-link"
                  >
                   Gender
                  </NavLink>
                  <NavLink
                    to="/colourList"
                    className="collapse-item"
                    activeClassName="active-link"
                  >
                   Colour
                  </NavLink>
                  <NavLink
                    to="/collectionList"
                    className="collapse-item"
                    activeClassName="active-link"
                  >
                   Collection
                  </NavLink>
                  <NavLink to="/feactureListPage"  className="collapse-item"
                    activeClassName="active-link">
                      <span>Feacture</span>
                    </NavLink>
                </div>
              </div>
              </li>

            <li className={`nav-item ${isOpenBlog ? "active" : ""}`}>
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseBlogPage"
                aria-expanded={isOpenBlog}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior
                  toggleBlog();
                }}
              >
                <i className="fas fa-fw fa-newspaper"></i>
                <span>Product</span>
              </a>
              <div
                id="collapseBlogPage"
                className={`collapse ${isOpenBlog ? "show" : ""}`}
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <NavLink to="/productListPage" className="collapse-item">
                    Product
                  </NavLink>
                </div>
              </div>
            </li>

           <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapsePagesAboutUs"
                aria-expanded="true"
                aria-controls="collapsePagesAboutUs"
              >
                <i className="fas fa-fw fa-info-circle"></i>
                <span>Order</span>
              </a>
              <div
                id="collapsePagesAboutUs"
                className="collapse"
                aria-labelledby="headingPages"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <NavLink to="/orderList" className="collapse-item">
                    Order
                  </NavLink>
                  {/* <NavLink to="/orderShippingList" className="collapse-item">
                   Shipping Order
                  </NavLink> */}
                  
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i className="fas fa-fw fa-cog"></i>
                <span>General Data</span>
              </a>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">General Data:</h6>
                  <NavLink to="/socialIconList" className="collapse-item">
                    Social Icon
                  </NavLink>
                  <NavLink to="/newsletterList" className="collapse-item">
                    Newsletter
                  </NavLink>
                {/*}  <NavLink to="/createGetInTouch" className="collapse-item">
                    Get in Touch
          </NavLink>*/}
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseUtilities"
                aria-expanded="true"
                aria-controls="collapseUtilities"
              >
                <i className="fas fa-fw fa-wrench"></i>
                <span>CMS</span>
              </a>
              <div
                id="collapseUtilities"
                className="collapse"
                aria-labelledby="headingUtilities"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">CMS:</h6>
                  <NavLink to="/bannerList" className="collapse-item">
                    Banner
                  </NavLink>
                 
                  <NavLink to="/homeListPage" className="collapse-item">
                    Home Page
        </NavLink>
        <NavLink to="/brandListPage" className="collapse-item">
                    Brand Page
        </NavLink>
                   <NavLink to="/bLogCategoryListPage" className="collapse-item">
                    Blog Category
                  </NavLink>
                  <NavLink to="/bLogListPage" className="collapse-item">
                    Blog 
                  </NavLink>
                  <NavLink to="/testimonialListPage" className="collapse-item">
                    Testimonial
                 </NavLink>
                 <NavLink to="/refundListPage" className="collapse-item">
                    Refund & Shipping 
                 </NavLink>
                 <NavLink to="/trickerListPage" className="collapse-item">
                <span>Tricker</span>
              </NavLink>
              <NavLink to="/headingListPage" className="collapse-item">
                <span>Heading</span>
              </NavLink>
                 {/*} <NavLink to="/ourMissionListPage" className="collapse-item">
                    About Page(Our Mission)
                  </NavLink>
                  <NavLink
                    to="/mishrambuStartedListPage"
                    className="collapse-item"
                  >
                    About Page(How Started)
                  </NavLink>
                  <NavLink to="/behindPeopleListPage" className="collapse-item">
                    About Page(Behind People)
                  </NavLink>
                  <NavLink to="/faqListPage" className="collapse-item">
                    Faq
                  </NavLink>
                  <NavLink to="/testimonialListPage" className="collapse-item">
                    Testimonial
        </NavLink>*/}
                </div>
              </div>
            </li>

        {/*}    <li className="nav-item">
              <a
                className={`nav-link ${isOpenContactUs ? "collapsed" : ""}`}
                href="#"
                aria-expanded="true"
                onClick={toggleContactUs}
              >
                <i className="fas fa-fw fa-folder"></i>
                <span>Contact us</span>
              </a>
              <div
                id="collapsePagesConatctUs"
                className={`collapse ${isOpenContactUs ? "show" : ""}`}
                aria-labelledby="headingPages"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <NavLink to="/contactGetList" className="collapse-item">
                    Contact Get In Touch
                  </NavLink>
                  <NavLink to="/contactWithList" className="collapse-item">
                    Contact With Us
                  </NavLink>
                </div>
              </div>
            </li>*/}

            <li className="nav-item">
              <NavLink to="/userList" className="nav-link">
                <i className="fas fa-fw fa-chart-area"></i>
                <span>User</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/coupenListPage" className="nav-link">
                <i className="fas fa-fw fa-chart-area"></i>
                <span>Coupon</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/emailListPage" className="nav-link">
                <i className="fas fa-fw fa-table"></i>
                <span>Email Template </span>
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/discountListPage" className="nav-link">
                <i className="fas fa-fw fa-table"></i>
                <span>Discount</span>
              </NavLink>
            </li> */}
            <hr className="sidebar-divider d-none d-md-block" />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
