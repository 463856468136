import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Form } from "react-bootstrap";
import { addCoupenformData} from "../Controller/Tricker";
import AddCoupenpopup from "./AddTrickerpopup";
import "react-dates/lib/css/_datepicker.css";
import { Link,useNavigate } from "react-router-dom";
import "react-dates/initialize";
import "./Tricker.css";

function AddTrickerData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const navigate = useNavigate();
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    tricker: "",
    
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((preVal) => ({
      ...preVal,
      [name]: value,
    }));
    setErrors((preError) => ({
      ...preError,
      [name]: "",
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.tricker) {
        setErrors((preError) => ({
          ...preError,
          tricker: "Tricker is required.",
        }));
      } else {
        const updateResult = await addCoupenformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            tricker: "",
           
          })); 
          togglePopUp();
        } else {
           localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
          setErrors((preError) => ({
            ...preError,
            tricker:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Dragger } = Upload;
  
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Tricker </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-12">
                          <label htmlFor="tricker">Tricker<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="tricker"
                            name="tricker"
                            placeholder="Enter Tricker"
                            onChange={handleInputChange}
                          />
                          {errors?.tricker ? (
                            <p style={{ color: "red" }}>
                              {errors?.tricker}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                       </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        
                      >
                        Submit
                      </button>
                      <Link to="/trickerListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
             
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTrickerData;
