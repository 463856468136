import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import { useNavigate, Link } from "react-router-dom";
import Header from "../HeaderDashBoard/Header";
import { addColorformData } from "../Controller/Color";
import AddSizeModel from "./AddColorpopup";
import { SketchPicker } from "react-color";
import ntc from 'ntcjs'; // Import ntc.js library
import "./Color.css";

function AddColorData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    color_code: "",
    name: "",
  });

  const handleInputChange = (value, field) => {
    if (field === "name") {
      setFormData((prevVal) => ({
        ...prevVal,
        name: value,
      }));
      updateColorCode(value);
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    }
    setErrors((prevError) => ({
      ...prevError,
      [field]: "", // Clear error when input changes
    }));
  };

  const handleColorChange = (color) => {
    const n_match = ntc.name(color.hex);
    const closestColorName = n_match[1];
    setFormData({
      ...formData,
      color_code: color.hex,
      name: closestColorName,
    });
  };

  const updateColorCode = (name) => {
    const hexCode = getColorCodeByName(name);
    setFormData((prevVal) => ({
      ...prevVal,
      color_code: hexCode || "",
    }));
  };

  const getColorCodeByName = (name) => {
    const n_match = ntc.name(name);
    const allColors = ntc.names;
    const color = allColors.find(color => color[1].toLowerCase() === name.toLowerCase());
    return color ? color[0] : "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.name) {
        setErrors((prevError) => ({
          ...prevError,
          name: "Color name is required.",
        }));
      } else if (!formData.color_code) {
        setErrors((prevError) => ({
          ...prevError,
          color_code: "Color code is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await addColorformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setIsSubmitting(false);
          togglePopUp();
          setFormData({
            color_code: "",
            name: "",
          });
        } else {
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("ADMIN-INFO");
          navigate("/login");
          setErrors({
            name:
              "Color name already exists. Please choose a different name.",
          });
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Colour</h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className="form-group">
                        <label>Choose Color:</label>
                        <SketchPicker
                          color={formData.color_code}
                          onChange={handleColorChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="name">
                          Colour Name
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Enter Colour Name"
                          value={formData.name}
                          onChange={(e) =>
                            handleInputChange(e.target.value, "name")
                          }
                        />
                        {errors.name && !formData.name && (
                          <p style={{ color: "red" }}>{errors.name}</p>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="color_code">
                          Colour Code
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="color_code"
                          name="color_code"
                          placeholder="Enter Colour Code"
                          value={formData.color_code}
                          readOnly
                        />
                        {errors.color_code && !formData.color_code && (
                          <p style={{ color: "red" }}>{errors.color_code}</p>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Processing...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                      <Link to="/colourList">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "12px" }}
                        >
                          <span>Cancel</span>
                        </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddSizeModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddColorData;
