import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getintouchDatafetch api
 *********************************************************/
export const getintouchDatafetch = async (options , authToken)=>{
    try{
        const {page,email}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'contact/list-order',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const contactDatafetch = async (options , authToken)=>{
    try{
        const {page,email}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'contact/workWithus-list',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
/*********************************************************
 *  This function is use to addMainformData api
 *********************************************************/
 export const addMainformData = async (options, authToken) => {
    try {
      const { title, description, image_1,image_2,image_3 } = options;
  
      // Create a FormData object
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('image_3', image_3);
      formData.append('image_1', image_1);
      formData.append('image_2', image_2);
  
      
      const posrData = {
        url: API_ADMIN_URL + 'main/add-main',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
  
  
      const res = await postRequest(posrData);
  
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
   // End of Function
   /*********************************************************
 *  This function is use to updateMainformData api
 *********************************************************/
   export const orderStatus = async (options, authToken) => {
    try {
      const { id,delivery_status } = options;
      const postData = {
        url: API_ADMIN_URL + 'order/order-process',
        postData: options,
        headers: {
          authToken: authToken,
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  
 // End of Function

 export const orderCancel = async (options, authToken) => {
    try {
      const { id,delivery_status } = options;
      const postData = {
        url: API_ADMIN_URL + 'order/cancel-order',
        postData: options,
        headers: {
          authToken: authToken,
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  
 // End of Function
