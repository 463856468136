import React, { useState,useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu  } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { updatesubCategoryformData,getsubCategoryData,getSubCategoryDatafetch,getCategoryDatafetch} from '../Controller/Subcategory';
import AddCategorypopup from "./EditSubCategorypopup";
import {imageURL} from '../Config/constant';
// import { DateRangePicker } from "react-dates";
import { BsThreeDots } from "react-icons/bs";
import "react-dates/lib/css/_datepicker.css";
import ImgCrop from 'antd-img-crop';
import "react-dates/initialize";
import "./SubCategory.css";
import { Form } from "react-bootstrap";
import { Link,useNavigate ,useParams } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import CategoryDelete from "./SubCategoryDelete";
import ReactQuill from "react-quill";


function EditSubCategoryData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [categories, setCategoryData] = useState([]);
  const { edit } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const [popUpOpen, setPopUpOpen] = useState(false);
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      category_id: "",
      subcategory_name: "",
      subcategory_description: "",
      subategory_image: null, // Initialize category_image with null
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
    
     const handleFileChange1 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, subcategory_image: file }));
        setFileInputs((prevFileInputs) => ({ ...prevFileInputs, subcategory_image: file })); // Update fileInputs state
      }
    };
    
    
  
    const handleInputChange = (value, field) => {
      if (field === 'subcategory_name') {
        setFormData((prevVal) => ({
          ...prevVal,
          subcategory_name: value,
        }));
      } else if (field === 'subcategory_description') { // Change here
        setFormData((prevVal) => ({
          ...prevVal,
          subcategory_description: value, // Change here
        }));
      }else if (field === 'category_id') { // Change here
        setFormData((prevVal) => ({
          ...prevVal,
          category_id: value, // Change here
        }));
      } else {
        const name = field;
        const inputValue = value;
  
        setFormData((prevVal) => ({
          ...prevVal,
          [name]: inputValue,
        }));
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
      }
      console.log("Form Data:", formData);
    };
  
  
    const [fileInputs, setFileInputs] = useState({
      subcategory_image: null,
    });
   
    
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.subcategory_name) {
        setErrors((preError) => ({
          ...preError,
          subcategory_name: 'SubCategory name is required.',
        }));
      }else if (!formData.category_id) {
        setErrors((preError) => ({
          ...preError,
          category_id: 'Category name is required.',
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updatesubCategoryformData(formData, adminInformation.token);
        if(updateResult.status === true){
          setFormData((prevFormData) => ({
              ...prevFormData,
              category_id: "",
              subcategory_name: "",
              subcategory_description: "",
            subcategory_image: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
      }else{
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
      }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  

 /*************************************************************
 * Function Name  : getsubcategorydata
 * Purposs        : This function is used get data
 * Created Date   : 09-01-2024
 *************************************************************/
 const getsubcategorydata = async () => {
  const options = { id: edit };
  try {
    const result = await getsubCategoryData(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      // Replace _id with id in the response data
      const modifiedData = { ...responseData, id: responseData._id };
      delete modifiedData._id; // Remove the _id property
      setFormData((prevFormData) => ({
        ...prevFormData,
        subcategory_name: responseData.name, // Change here
        subcategory_description: responseData.subcategory_description,
        subcategory_image: responseData.subcategory_image,
        category_id: responseData.category_id,
        ...modifiedData,
      }));
      setFileInputs({
        subcategory_image: responseData.subcategory_image || null,
      });
    } else {
      localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};


  useEffect(() => {
    window.scrollTo(0, 0);
    getsubcategorydata();
    fetchCategories();
  }, []);
   /*************************************************************
          End Function
   *************************************************************/
        const fetchCategories = async () => {
          const options = {};
          try {
            const result = await getCategoryDatafetch(options, adminInformation.token);
            if (result.data && result.data.response) {
              const responseData = result.data.response.result;
              setCategoryData(responseData);
            } else {
              localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
              console.error("Unexpected response format:", result.data);
            }
          } catch (error) {
            console.error("Error fetching banner data:", error);
          }
        };
       
  const { Search } = Input;
  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Sub Category </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group">
                        <label htmlFor="fullname">Sub Category Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="subcategory_name"
                          name="subcategory_name"
                          placeholder="Enter Sub Category Name"
                          value={formData.subcategory_name}
                          onChange={(e) => handleInputChange(e.target.value, 'subcategory_name')}  // Modify this line
                        />
                        {errors?.subcategory_name ? (
                          <p style={{ color: "red" }}>
                            {errors?.subcategory_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Category Name</label>
                        <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e.target.value, 'category_id')}
                          >
                            <option value="">Select Category</option>
                            {categories.map((category) => (
                              <option
                                key={category._id}
                                value={category._id}
                                selected={category._id === formData.category_id} // Set selected based on the current formData
                              >
                                {category.name}
                              </option>
                            ))}
                          </select>
                        {errors?.category_id ? (
                          <p style={{ color: "red" }}>
                            {errors?.category_id}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description
                            </label>
                            <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="subcategory_description"
                            value={formData.subcategory_description}
                            name="subcategory_description"
                            onChange={(value) => handleInputChange(value, 'subcategory_description')}
                          />
                           {errors?.subcategory_description ? (
                          <p style={{ color: "red" }}>
                            {errors?.subcategory_description}
                          </p>
                        ) : (
                          ""
                        )}
                          </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="subcategory_image"
                          name="subcategory_image"
                          onChange={handleFileChange1}
                        />
                        {formData.subcategory_image && (
                          <p>Current File: {formData.subcategory_image}</p>
                        )}
                      </div> */}

<div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description
                            </label>
                            <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="subcategory_description"
                            name="Subcategory_description"
                            value={formData.subcategory_description}
                            onChange={(value) => handleInputChange(value, 'subcategory_description')}
                          />


                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="fullname">Image</label>
                                <input type="file" className="form-control"  id="subcategory_image" name="subcategory_image"
                                  onChange={handleFileChange1}
                            />
                            </div> */}
{/* <div className="form-group">
  <label htmlFor="subcategory_image">Image</label>
  <ImgCrop rotate>
    <Upload
      className="avatar-uploader"
      name="subcategory_image"
      onChange={({ fileList }) => handleFileChange1(fileList)}
      listType="picture-card"
      fileList={null}
      beforeUpload={() => false}
    >
      {formData.subcategory_image ? (
        <img src={URL.createObjectURL(formData.subcategory_image)} alt="subcategory" style={{ width: '100%' }} />
      ) : (
        <div>
          <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
          </svg>
          <div className="ant-upload-text">Upload</div>
        </div>
      )}
    </Upload>
  </ImgCrop>
</div> */}
<ImgCrop rotate aspect={1299 / 659.74}>
  <Upload
    className="avatar-uploader"
    name="subcategory_image"
    onChange={({ fileList }) => handleFileChange1(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.subcategory_image ? (
      <img
        src={typeof formData.subcategory_image === 'string' ? `${imageURL}${formData.subcategory_image}` : URL.createObjectURL(formData.subcategory_image)}
        alt="subcategory"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    ) : (
      <div>
        <svg
          className="icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="image"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>


<p style={{ color: "blue" }}>Recommended size: 1299x659.74(width x height)px</p>
<button type="submit" className="btn btn-primary" disabled={isSubmitting}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/subcategoryList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
              
            </div>
            <AddCategorypopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSubCategoryData;
