import React from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaDesktop } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import "./ContactUs.css";
import Pagination from "@mui/material/Pagination";

function ContatcUsAddressTable() {
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com"
        >
          <FaDesktop /> <span className="show_span">Show</span>
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com"
        >
          <FaEdit />
          <span className="show_span_edit">Edit</span>
        </a>
      </Menu.Item>
    </Menu>
  );
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/*  <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Contact Us Address</h1>
                <p className="mb-4">
                  DataTables is a third party plugin that is used to generate
                  the demo table below. For more information about DataTables,
                  please visit the{" "}
                  <a target="_blank" href="https://datatables.net">
                    official DataTables documentation
                  </a>
                  .
                </p>

                <div className="table_head_main_class">
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>From</Form.Label>
                        <DatePicker />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>To</Form.Label>
                        <DatePicker />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Filter By</Form.Label>
                        <Select
                          showSearch
                          placeholder="Select a person"
                          optionFilterProp="children"
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "lucy",
                              label: "Lucy",
                            },
                            {
                              value: "tom",
                              label: "Tom",
                            },
                          ]}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search placeholder="input search text" />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      DataTables Example
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Phone No</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Yuri Berry</td>
                            <td>Chief Marketing Officer (CMO)</td>
                            <td>New York</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>Caesar Vance</td>
                            <td>Pre-Sales Support</td>
                            <td>New York</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>Caesar Vance</td>
                            <td>Pre-Sales Support</td>
                            <td>New York</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>Caesar Vance</td>
                            <td>Pre-Sales Support</td>
                            <td>New York</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>Caesar Vance</td>
                            <td>Pre-Sales Support</td>
                            <td>New York</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>Caesar Vance</td>
                            <td>Pre-Sales Support</td>
                            <td>New York</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>Caesar Vance</td>
                            <td>Pre-Sales Support</td>
                            <td>New York</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>Caesar Vance</td>
                            <td>Pre-Sales Support</td>
                            <td>New York</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination count={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContatcUsAddressTable;
