import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from './API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getHomeDatafetch = async (options , authToken)=>{
    try{
        const {page,title}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'brand/list-brand',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getHomeData = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'brand/get-brand',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
 /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
 export const addHomeformData = async (options, authToken) => {
    try {
      const { brand_name1,description,button_title,product_id,title,product_color,type_name,image,page_name } = options;
  
      // Create a FormData object
      const formData = new FormData();
      formData.append('title', title);
      formData.append('product_id', product_id);
      formData.append('description', description);
      formData.append('button_title', button_title);
      formData.append('page_name', page_name);
     
      const [brand_name,subcategory_id] = brand_name1.trim().split('|');
      formData.append('brand_name', brand_name);
      formData.append('subcategory_slug', brand_name);
      formData.append('subcategory_id', subcategory_id);
      formData.append('product_color', product_color);
      const [type, collection_id] = type_name.trim().split('|');
     
      formData.append('type', type);
      formData.append('collection_id', collection_id);
      formData.append('image', image);
      const posrData = {
        url: API_ADMIN_URL + 'brand/add-brand',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
  
  
      const res = await postRequest(posrData);
      console.log(res);
  
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateHomeformData = async (options, authToken) => {
    try {
      const {  brand_name,description,button_title,product_id,title,product_color,type,image,page_name, id } = options;
      const postData = {
        url: API_ADMIN_URL + 'brand/edit-brand',
        postData: options,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  
 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteHomeData = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'brand/delete-brand',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};


export const getbrandDatafetch = async (options , authToken)=>{
    try{
        const {brand}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'banner/list-all-brand',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
  }; 

  
export const getproductDatafetch = async (options , authToken)=>{
  try{
      const {subcategory_slug}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/get-product-list',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 
 
export const getproductcolorDatafetch = async (options , authToken)=>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/get-product-color',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 
  export const statusCategoryData = async (options , authToken)=>{
    try{
        const {status,id}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'brand/status',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
  }; 