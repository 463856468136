import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getProductDatafetch = async (options , authToken)=>{
    try{
        const {page,product_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'product/list-product',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getProductdata = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/get-product',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
export const getFeacturedata = async (options , authToken) =>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'feacture/get-allFeacture',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
 /*********************************************************
 *  This function is use to addBlogformData api
 *********************************************************/
 export const addProductformData = async (options, authToken, color_size_details) => {
  try {
    const {
      product_name, hsn, sku, featured_products_type, gst, product_collection_id, show_home, return_period, cancellable,
      returnable, productstock, show, new_brand_collection, best_sellers, featured_products, latest_arrival, category_id,
      gender_id, subcategory_id, product_title, product_description, size_image, hot_list
    } = options;
    const formData = new FormData();
    formData.append('product_name', product_name);
    formData.append('subcategory_id', subcategory_id);
    formData.append('category_id', category_id);
    formData.append('product_collection_id', product_collection_id);
    formData.append('gender_id', gender_id);
    formData.append('gst', gst);
    formData.append('product_title', product_title);
    formData.append('product_description', product_description);
    formData.append('show_home', show_home);
    formData.append('return_period', return_period);
    formData.append('cancellable', cancellable);
    formData.append('returnable', returnable);
    formData.append('hot_list', hot_list);
    formData.append('sku', sku);
    formData.append('hsn', hsn);
    formData.append('latest_arrival', latest_arrival);
    formData.append('featured_products', featured_products);
    formData.append('featured_products_type', featured_products_type);
    formData.append('new_brand_collection', new_brand_collection);
    formData.append('best_sellers', best_sellers);
    if (options.size_image) {
      formData.append('size_image', options.size_image);
    }

    color_size_details.forEach((color_size_detail, index) => {
      const { color,sizes, size_details, productstock, show, image_1, image_2, image_3, image_4, image_5 } = color_size_detail;
      const [color_name, color_code] = color.trim().split('|');
       console.log("color_name",color_name);
       console.log("color_code",color_code);
      formData.append("color_name", color_name);
       formData.append("color_code", color_code);
       formData.append("productstock", productstock);
       formData.append("show", show);
      //  const cleanedSize = Array.isArray(size) ? size.reverse() : JSON.parse(size.replace(/\[|\]/g, '')).reverse();
     //  const co = cleanedSize.join(',');
       formData.append("size", sizes);
       formData.append("image_1", image_1);
       formData.append("image_2", image_2);
       formData.append("image_3", image_3);
       formData.append("image_4", image_4);
       formData.append("image_5", image_5);

       let mrp_prices = [];
       let selling_prices = [];
       let productsizesstock = [];
       let productsizeshow = [];
       Object.keys(size_details).forEach((sizeKey) => {
        const { mrpprice, sellingprice, productsizestock,productshow } = size_details[sizeKey];
        console.log(size_details[sizeKey]);
        mrp_prices.push(mrpprice);
        selling_prices.push(sellingprice);
        productsizesstock.push(productsizestock);
        productsizeshow.push(productshow);
      });
      console.log("mrp_prices",mrp_prices);
      console.log("selling_prices",selling_prices); 
      console.log("productsizesstock",productsizesstock);
      console.log("productsizeshow",productsizeshow);
        formData.append("mrp_price", mrp_prices);
        formData.append("selling_price", selling_prices);
        formData.append("productsizestock", productsizesstock);
        formData.append("productshow", productsizeshow);
    });

    const postData = {
      url: API_ADMIN_URL + 'product/add-product',
      postData: formData,
      headers: {
        authToken: authToken,
        'Content-Type': 'multipart/form-data',
      },
    };

    const res = await postRequest(postData);

    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};










//  export const addProductformData = async (options, authToken, color_size_details) => {
//   try {
//     const { product_name,hsn,sku,featured_products_type, gst,product_collection_id,show_home,return_period,cancellable,returnable,productstock,show,new_brand_collection,best_sellers,featured_products, latest_arrival,category_id, gender_id, subcategory_id,product_title,product_description,size_image,hot_list } = options;
//     const formData = new FormData();
//     formData.append('product_name', product_name);
//     formData.append('subcategory_id', subcategory_id);
//     formData.append('category_id', category_id);
//     formData.append('product_collection_id', product_collection_id);
//     formData.append('gender_id', gender_id);
//     formData.append('gst', gst);
//     formData.append('product_title', product_title);
//     formData.append('product_description', product_description);
//     formData.append('show_home', show_home);
//     formData.append('return_period', return_period);
//     formData.append('cancellable', cancellable);
//     formData.append('returnable', returnable);
//     formData.append('hot_list', hot_list);
//     formData.append('sku', sku);
//     formData.append('hsn', hsn);
//     formData.append('latest_arrival', latest_arrival);
//     formData.append('featured_products', featured_products);
//     formData.append('featured_products_type', featured_products_type);
//     formData.append('new_brand_collection', new_brand_collection);
//     formData.append('best_sellers', best_sellers);
//     if (options.size_image) {
//       formData.append('size_image', options.size_image);
//     }
//     color_size_details.forEach((color_size_detail, index) => {
//       const { color_name, size,productstock,mrp_price,selling_price, show,image_1, image_2,image_3,image_4,image_5 } = color_size_detail;
//       const [color, color_code] = color_name.trim().split('|');
//       formData.append("color_name", color);
//       formData.append("color_code", color_code);
//       formData.append("mrp_price", mrp_price);
//       formData.append("selling_price", selling_price);
//       formData.append("productstock", productstock);
//       formData.append("show", show);
//       const cleanedSize = Array.isArray(size) ? size.reverse() : JSON.parse(size.replace(/\[|\]/g, '')).reverse();
//       const co = cleanedSize.join(',');
//       formData.append("size", size);
//       formData.append("image_1", image_1);
//       formData.append("image_2", image_2);
//       formData.append("image_3", image_3);
//       formData.append("image_4", image_4);
//       formData.append("image_5", image_5);
//     });
//     const postData = {
//       url: API_ADMIN_URL + 'product/add-product',
//       postData: formData,
//       headers: {
//         authToken: authToken,
//         'Content-Type': 'multipart/form-data',
//       },
//     };

//     const res = await postRequest(postData);

//     if (res) {
//       return res;
//     } else {
//       return { status: false, message: res?.response?.data?.statusMessage };
//     }
//   } catch (error) {
//     console.error("Error:", error);
//     return { status: false, message: "Under Maintenance, Please try after some time." };
//   }
// };


   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateProductformData = async (options, authToken, color_size_details) => {
    try {
      const { id, product_name, hsn, sku, featured_products_type, gst, product_collection_id, show_home, return_period, cancellable,
        returnable, productstock, show, new_brand_collection, best_sellers, featured_products, latest_arrival, category_id,
        gender_id, subcategory_id, product_title, product_description, size_image, hot_list } = options;
  
      const formData = new FormData();
      formData.append('id', id);
      formData.append('product_name', product_name);
      formData.append('subcategory_id', subcategory_id);
      formData.append('category_id', category_id);
      formData.append('product_collection_id', product_collection_id);
      formData.append('gender_id', gender_id);
      formData.append('gst', gst);
      formData.append('product_title', product_title);
      formData.append('product_description', product_description);
      formData.append('return_period', return_period);
      formData.append('cancellable', cancellable);
      formData.append('returnable', returnable);
      formData.append('show_home', show_home);
      formData.append('hot_list', hot_list);
      formData.append('sku', sku);
      formData.append('hsn', hsn);
      formData.append('latest_arrival', latest_arrival);
      formData.append('featured_products', featured_products);
      formData.append('featured_products_type', featured_products_type);
      formData.append('new_brand_collection', new_brand_collection);
      formData.append('best_sellers', best_sellers);
  
      if (options.size_image) {
        formData.append('size_image', options.size_image);
      }
  
      if (Array.isArray(color_size_details)) {
        color_size_details.forEach((color_size_detail, index) => {
          const { color_name, color_code,size, sizes, size_details, productstock, show, image_1, image_2, image_3, image_4, image_5 } = color_size_detail;
  console.log("size_details",size_details);
          formData.append("color_name", color_name);
          formData.append("color_code", color_code);
          formData.append("productstock", productstock);
          formData.append("show", show);
         
  
          formData.append("image_1", image_1);
          formData.append("image_2", image_2);
          formData.append("image_3", image_3);
          formData.append("image_4", image_4);
          formData.append("image_5", image_5);
  
          let mrp_prices = [];
          let selling_prices = [];
          let productsizesstock = [];
          let productsizeshow = [];
          let productsize = [];
  
          if (Array.isArray(sizes)) {
            sizes.forEach((size_detail, index) => {
              const { mrpprice,size, sellingprice, productsizestock, productshow } = size_detail;
              console.log(size);
              mrp_prices.push(mrpprice);
              if (typeof size === 'string' && size.trim() !== '' && size !="undefined") {
                productsize.push(size.trim()); // Push trimmed size to productsize array
              }
              selling_prices.push(sellingprice);
              productsizesstock.push(productsizestock);
              productsizeshow.push(productshow);
            });
          }
  console.log("productsize",productsize);
          formData.append("mrp_price", mrp_prices);
          formData.append("selling_price", selling_prices);
          formData.append("productsizestock", productsizesstock);
          formData.append("productshow", productsizeshow);
          formData.append("size", productsize);
        });
      } else {
        throw new Error("color_size_details is not an array or is undefined");
      }
  
      const postData = {
        url: API_ADMIN_URL + 'product/edit-product',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
  
      const res = await postRequest(postData);
  
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage || "Unknown error" };
      }
    } catch (error) {
      console.error("Error:", error);
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
  
  
 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteProductDatafetch = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'product/delete-product',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
export const deletebulkProductDatafetch = async (options ,authToken) => {
  try {
    const { ids } = options;
    const postData = {
      ids: ids,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'product/bulk-delete',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
/*********************************************************
 *  This function is use to getCategoryDatafetch api
 *********************************************************/
export const getCategoryDatafetch = async (options , authToken)=>{
    try{
        const {}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'category/get-category-data',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const geStatusDatafetch = async (options , authToken)=>{
  try{
      const {id,out_stock_status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/outofstock-product',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
export const getSubCategoryDatafetch = async (options , authToken) =>{
  try{
      const {category_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'subcategory/get-subcategory-by-id',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
export const getsubCategoryDatafetch = async (options , authToken) =>{
  try{
      const {category_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'subcategory/all-subcategory',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};

export const getCollectionDatafetch = async (options , authToken) =>{
  try{
      const {category_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'collection/get-allcollection',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
export const getGenderDatafetch = async (options , authToken) =>{
  try{
      const {category_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'gender/get-allgender',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};

export const getColorDatafetch = async (options , authToken) =>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'color/get-allcolor',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};

export const getSizeDatafetch = async (options , authToken) =>{
  try{
      const {gender_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'size/get-size-byId',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};

export const statusCategoryData = async (options , authToken)=>{
  try{
      const {id,status }= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/status',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 


export const addProductBulkData = async (options, authToken) => {
  try {
    const { file} = options;
    const formData = new FormData();
    if (options.file) {
      formData.append('file', options.file);
    }
    const postData = {
      url: API_ADMIN_URL + 'product/bulk-upload',
      postData: formData,
      headers: {
        authToken: authToken,
        'Content-Type': 'multipart/form-data',
      },
    };

    const res = await postRequest(postData);

    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
export const getproducttotalDatafetch = async (options , authToken) =>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/get-total',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};