import React, {useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import { forgotPassword } from '../Controller/Account';

function Forgotpage() {
  const [formData, setFormData]= useState([]);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [errors, setErrors] = useState('');
  const [success, setSuccess]= useState('');

  const [countdown, setCountdown] = useState(60);
  const [isCounting, setIsCounting] = useState(false);


  /*************************************************************
 * Function Name  : handleChange
 * Purposs        : This function is used for store input value in states
 * Created Date   : 08-01-2024
 *************************************************************/
  const handleChange = (e)=>{
    setFormData((preVal)=>({
      ...preVal,
      [e.target.name] : e.target.value
    }));
    setErrors((...preError)=>({
      ...preError,
      [e.target.name] : ''
    }));
  } //End of FUnction
/*************************************************************
 * Function Name  : sentResetotp
 * Purposs        : This function is used reset OTP
 * Created Date   : 09-01-2024
 *************************************************************/
const sentResetotp = async ()=>{
  if(!formData?.email){
    setErrors((...preError)=>({
      ...preError,
      email : 'Email is required.'
    }));
  }else{
    const options = { email : formData?.email}
    const result = await forgotPassword(options)
    if(result.status === true){
      setIsOTPSent(true);
      setSuccess((preSuccess)=>({
        ...preSuccess,
        formSuccess : result.message
      }));
      setCountdown(60);
      setIsCounting(true);
    } else{
      setIsOTPSent(false);
      setErrors((...preError)=>({
        ...preError,
        email : 'Please enter valid email.'
      }));
    }
  }
} //End of FUnction

 /* const sentResetotp = ()=>{
    setCountdown(60);
    setIsCounting(true);
  }*/


  /*************************************************************
 * Function Name  : handleEmailChnage
 * Purposs        : This function is used for chnage email id
 * Created Date   : 08-01-2024
 *************************************************************/
  const handleEmailChnage = async ()=>{
    //Empty OTP Input Box
    setFormData((preVal)=>({
      ...preVal,
      otp : ""
    }))
    setIsOTPSent(false);
    setCountdown(0);
  } //End of Function

 /*************************************************************
 * Purposs        : This hooks is used for handle otp sent countdown
 * Created Date   : 08-01-2024
 *************************************************************/
  useEffect(() => {
    let countdownInterval;
    if (isCounting) {
      countdownInterval = setInterval(() => {
        if (countdown > 0) {
          setCountdown((prevCountdown) => prevCountdown - 1);
        } else {
          clearInterval(countdownInterval);
          setIsCounting(false);
        }
      }, 1000);
    } else {
      clearInterval(countdownInterval);
    }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown, isCounting]);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      {!isOTPSent?(<>
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-2">
                            Forgot Your Password?
                          </h1>
                          <p className="mb-4">
                            We get it, stuff happens. Just enter your email
                            address below and we'll send you One Time Password to reset your
                            password!
                          </p>
                        </div>
                        
                        

                        <form className="user">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              value={formData?.email}
                              className="form-control form-control-user"
                              id="exampleInputEmail"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email Address..."
                              onChange={handleChange}
                            />
                            {errors?.email?(<p style={{color:"red"}}>{errors?.email}</p>):''}
                          </div>
                          <NavLink
  to={false}
  className={`btn btn-primary btn-user btn-block ${isCounting ? 'disabled' : ''}`}
  onClick={isCounting ? null : sentResetotp}
  disabled={isCounting}
>
  Reset Password
</NavLink>



                        </form>

                        <hr />
                        <div className="text-center">
                          <NavLink className="small" to='/login'>
                            Already have an account? Login!
                          </NavLink>
                        </div>
                      </>):(
                      <>
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-2">
                            Forgot Your Password?
                          </h1>
                          <p className="mb-4">
                            {`We get it, stuff happens. Just enter your One Time Password below sent to your registered eamil ${formData?.email}`} <NavLink to={false} onClick={handleEmailChnage}>Change</NavLink>
                          </p>
                          {success?.formSuccess ? (<p style={{ color: "green" }}>{success?.formSuccess}</p>) : ''}
                        </div>
                        <form className="user">
                          <div className="form-group">
                            <input
                              type="password"
                              name="otp"
                              value={formData?.email}
                              className="form-control form-control-user"
                              id="exampleInputEmail"
                              aria-describedby="emailHelp"
                              placeholder="Enter One Time Password..."
                              onChange={handleChange}
                            />
                           {errors?.email?(<p style={{color:"red"}}>{errors?.email}</p>):''}
                          </div>
                       
                          <NavLink
  to={false}
  className={`btn btn-primary btn-user btn-block ${isCounting ? 'disabled' : ''}`}
  onClick={isCounting ? null : sentResetotp}
  disabled={isCounting}
>
  Reset Password
</NavLink>



 </form>
                        <hr />
                        <div className="text-center">
                          {isCounting ? (
                            <p style={{color:'red'}}> {' Time Left :'} {countdown} </p>
                            ) : (
                              <NavLink className="small" to={false} onClick={sentResetotp}> Sent OTP </NavLink>
                          )}
                        </div>
                      </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgotpage;
