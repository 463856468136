import React, { useState,useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import "../AllCssFile.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { addProductformData,getFeacturedata, getCategoryDatafetch,getSizeDatafetch,getSubCategoryDatafetch,getCollectionDatafetch,getGenderDatafetch,getColorDatafetch } from "../Controller/Product";
import AddProductpopup from "./AddProductpopup";
import { useParams ,useNavigate,Link} from "react-router-dom";
import ImgCrop from 'antd-img-crop';
function ProductCreate() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [subCategories, setSubCategoryData] = useState([]);
  const [categories, setCategoryData] = useState([]);
  const [collection, setCollectionData] = useState([]);
  const [gender, setGenderData] = useState([]);
  const [sizelist, setSizeData] = useState([]);
  const [color, setcolorData] = useState([]);
  const [feactureList, setFeactureData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    product_name: "",
    category_id: "",
    subcategory_id: "",
    product_collection_id: "",
    gender_id: "",
    size_image: null,
    product_title:"",
    return_period:"",
    cancellable:"",
    returnable:"",
    product_description:"",
    hot_list:"",
    show_home:"",
    latest_arrival:"",
    featured_products:"",
    featured_products_type:"",
    new_brand_collection:"",
    best_sellers:"",
    gst:"",
    hsn:"",
    sku:"",
    variants: [
      {
        color_name: "",
        size: [],
        mrpprice:[],
        sellingprice:[],
        productsizestock:[],
        productshow:[],
        productstock: "",
        show: "",
        image_1: "",
        image_2: "",
        image_3: "",
        image_4: "",
        image_5: "",
      },
    ],
  });


  const handleFileChange1 = (fileList, variantIndex) => {
    const file = fileList[0].originFileObj;
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],
        image_1: file,
      };
      return { ...prevFormData, variants: updatedVariants };
    });
  };
  
  const handleFileChange3 = (fileList, variantIndex) => {
    const file = fileList[0].originFileObj;
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],
        image_2: file,
      };
      return { ...prevFormData, variants: updatedVariants };
    });
  };
  const handleFileChange4 = (fileList, variantIndex) => {
    const file = fileList[0].originFileObj;
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],
        image_3: file,
      };
      return { ...prevFormData, variants: updatedVariants };
    });
  };
 
  const handleFileChange5 = (fileList, variantIndex) => {
    const file = fileList[0].originFileObj;
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],
        image_4: file,
      };
      return { ...prevFormData, variants: updatedVariants };
    });
  };

  const handleFileChange6 = (fileList, variantIndex) => {
    const file = fileList[0].originFileObj;
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],
        image_5: file,
      };
      return { ...prevFormData, variants: updatedVariants };
    });
  };

  
  const handleFileChange2 = (fileList) => {
    // Ensure fileList is not empty
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, size_image: file }));
    }
  };
   
  const [selectedSizes, setSelectedSizes] = useState(Array.from({ length: formData.variants.length }, () => []));
  
  // const handleInputChange = (selectedValue, field, index) => {
  //   if (field === "product_description") {
  //     setFormData((prevVal) => ({
  //       ...prevVal,
  //       [field]: selectedValue,
  //     }));
  //   } else if (
  //     field === "cancellable" ||
  //     field === "returnable" ||
  //     field === "hot_list" ||
  //     field === "latest_arrival" ||
  //     field === "featured_products" ||
  //     field === "new_brand_collection" ||
  //     field === "best_sellers" ||
  //     field === "show_home"
  //   ) {
  //     setFormData((prevVal) => ({
  //       ...prevVal,
  //       [field]: selectedValue === false ? "No" : "Yes",
  //     }));
  //   } else  if (field.startsWith("variant_")) {
  //     const variantIndex = parseInt(field.split("_")[1], 10);
  //     const fieldName = field.split("_")[2];
  
  //     setFormData((prevVal) => {
  //       const updatedVariants = [...prevVal.variants];
  
  //       if (!updatedVariants[variantIndex]) {
  //         updatedVariants[variantIndex] = {};
  //       }
  
  //       if (fieldName === "mrpprice" || fieldName === "sellingprice" || fieldName === "productsizestock" ||  fieldName ==="productshow") {
  //         const sizeName = field.split("_")[3];
  //         if (!updatedVariants[variantIndex].size_details) {
  //           updatedVariants[variantIndex].size_details = {};
  //         }
  //         if (!updatedVariants[variantIndex].size_details[sizeName]) {
  //           updatedVariants[variantIndex].size_details[sizeName] = {};
  //         }
  //         updatedVariants[variantIndex].size_details[sizeName][fieldName] = selectedValue;
  //       } else if (fieldName === "size") {
  //         if (!updatedVariants[variantIndex].sizes) {
  //           updatedVariants[variantIndex].sizes = [];
  //         }
  //         const existingIndex = updatedVariants[variantIndex].sizes.findIndex((size) => size === selectedValue);
  //         if (existingIndex === -1) {
  //           updatedVariants[variantIndex].sizes.push(selectedValue);
  //         } else {
  //           updatedVariants[variantIndex].sizes.splice(existingIndex, 1);
  //         }
  //       } else {
  //         updatedVariants[variantIndex][fieldName] = selectedValue;
  //       }
  
  //       return { ...prevVal, variants: updatedVariants };
  //     });
  //   } else {
  //     setFormData((prevVal) => ({
  //       ...prevVal,
  //       [field]: selectedValue,
  //     }));
  //     setErrors((prevError) => ({
  //       ...prevError,
  //       [field]: "",
  //     }));
  //   }
  
  //   if (field === "featured_products" && selectedValue === true) {
  //     if (!formData.featured_products_type) {
  //       setErrors((prevError) => ({
  //         ...prevError,
  //         featured_products_type: "Select Featured Products is required",
  //       }));
  //     }
  //   }
  // };
  const handleInputChange = (selectedValue, field, index) => {
    if (field === "product_description") {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
    } else if (
      field === "cancellable" ||
      field === "returnable" ||
      field === "hot_list" ||
      field === "latest_arrival" ||
      field === "featured_products" ||
      field === "new_brand_collection" ||
      field === "best_sellers" ||
      field === "show_home"
    ) {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue === false ? "No" : "Yes",
      }));
    } else if (field.startsWith("variant_")) {
      const variantIndex = parseInt(field.split("_")[1], 10);
      const fieldName = field.split("_")[2];
  
      setFormData((prevVal) => {
        const updatedVariants = [...prevVal.variants];
  
        if (!updatedVariants[variantIndex]) {
          updatedVariants[variantIndex] = {};
        }
  
        if (
          fieldName === "mrpprice" ||
          fieldName === "sellingprice" ||
          fieldName === "productsizestock" ||
          fieldName === "productshow"
        ) {
          const sizeName = field.split("_")[3];
          if (!updatedVariants[variantIndex].size_details) {
            updatedVariants[variantIndex].size_details = {};
          }
          if (!updatedVariants[variantIndex].size_details[sizeName]) {
            updatedVariants[variantIndex].size_details[sizeName] = {};
          }
          updatedVariants[variantIndex].size_details[sizeName][fieldName] = selectedValue;
        } else if (fieldName === "size") {
          if (!updatedVariants[variantIndex].sizes) {
            updatedVariants[variantIndex].sizes = [];
          }
          if (selectedValue) {
            updatedVariants[variantIndex].sizes.push(selectedValue);
          } else {
            updatedVariants[variantIndex].sizes = updatedVariants[variantIndex].sizes.filter(
              (size) => size !== field.split("_")[3]
            );
  
            // Remove corresponding MRP, selling price, stock data, and uncheck productshow
            const sizeName = field.split("_")[3];
            if (updatedVariants[variantIndex].size_details && updatedVariants[variantIndex].size_details[sizeName]) {
              delete updatedVariants[variantIndex].size_details[sizeName];
            }
          }
        } else {
          updatedVariants[variantIndex][fieldName] = selectedValue;
        }
  
        return { ...prevVal, variants: updatedVariants };
      });
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [field]: "",
      }));
    }
  
    if (field === "featured_products" && selectedValue === true) {
      if (!formData.featured_products_type) {
        setErrors((prevError) => ({
          ...prevError,
          featured_products_type: "Select Featured Products is required",
        }));
      }
    }
  };
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const fetchCollection = async () => {
    const options = {};
    try {
      const result = await getCollectionDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCollectionData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const fetchGender = async () => {
    const options = {};
    try {
      const result = await getGenderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setGenderData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const fetchColor = async () => {
    const options = {};
    try {
      const result = await getColorDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setcolorData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const fetchFeacture = async () => {
    const options = {};
    try {
      const result = await getFeacturedata(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setFeactureData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleSelectClick = () => {
    fetchCategories();
  };
  const handleSelectCollectionClick = () => {
    fetchCollection();
  };
  const handleSelectGenderClick = () => {
    fetchGender();
  };
  const handleSelectColorClick = () => {
    fetchColor();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    try {
      if (!formData.product_name) {
        setErrors((preError) => ({
          ...preError,
          product_name: "Product name is required.",
        }));
      }else if (!formData.product_title) {
        setErrors((preError) => ({
          ...preError,
          product_title: "Product title is required.",
        }));
      }else if (!formData.category_id) {
        setErrors((preError) => ({
          ...preError,
          category_id: "Category is required.",
        }));
      }else  if (!formData.subcategory_id) {
        setErrors((preError) => ({
          ...preError,
          subcategory_id: "Sub category is required.",
        }));
      }else if (!formData.product_collection_id) {
        setErrors((preError) => ({
          ...preError,
          product_collection_id: "Collection is required.",
        }));
      }else if (!formData.gender_id) {
        setErrors((preError) => ({
          ...preError,
          gender_id: "Gender is required.",
        }));
      }else if (!formData.product_description) {
        setErrors((preError) => ({
          ...preError,
          product_description: "Product description is required.",
        }));
      }else if (!formData.size_image) {
        setErrors((preError) => ({
          ...preError,
          size_image: "Image is required.",
        }));
      }else if (!formData.gst) {
        setErrors((preError) => ({
          ...preError,
          gst: "Gst is required.",
        }));
      }else if (!formData.sku) {
        setErrors((preError) => ({
          ...preError,
          sku: "sku No is required.",
        }));
      }else if (!formData.hsn) {
        setErrors((preError) => ({
          ...preError,
          hsn: "Hsn no is required.",
        }));
      }else if(formData.featured_products === "Yes" && formData.featured_products_type ===""){
        setErrors((preError) => ({
          ...preError,
          featured_products_type: "Select Featured Products is required",
        }));
      }else {
       setIsSubmitting(true); 
        formData.return_period = document.getElementById('return_period').value;
        formData.gst = document.getElementById('gst').value;
         const updateResult = await addProductformData(formData, adminInformation.token, formData.variants);
        if (updateResult.data.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            product_name: "",
            category: "",
            ingredients: "",
            recipes: "",
            page_show: "",
            page_show_main: "",
            product_description: "",
            product_image: "",
            variants: prevFormData.variants.map((variant) => ({
              ...variant,
            })),
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  const fetchSubCategories = async (categoryId) => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      category_id: categoryId,
    };
  
    try {
      const result = await getSubCategoryDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSubCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  
  const handleCategorySelect = (categoryId) => {
  
    fetchSubCategories(categoryId);
  };
  const handlesizeSelect = (genderId) => {
  
    fetchSizeCategories(genderId);
  };

  const fetchSizeCategories = async (genderId) => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      gender_id: genderId,
    };
  
    try {
      const result = await getSizeDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSizeData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      variants: [...prevVal.variants, { color_name: "", size: "",mrpprice: "",sellingprice:"", productstock: "", show: false,  image_1: "",
      image_2: "",
      image_3: "",
      image_4: "",
      image_5: ""}],
    }));
  };
  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.variants];
      updatedVariants.splice(index, 1);
      return { ...prevVal, variants: updatedVariants };
    });
  };
useEffect(() => {
  window.scrollTo(0, 0);
  fetchFeacture();
}, []);

  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column  product_create">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Add Product Content</h5>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="form-group row">
                        <div className="col-md-4">
                          <label htmlFor="exampleInputText">Product Name<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="product_name"
                            name="product_name"
                            value={formData.product_name}
                            onChange={(e) => handleInputChange(e.target.value, "product_name")}
                          />
                          {errors?.product_name ? <p style={{ color: "red" }}>{errors?.product_name}</p> : ""}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">Product Title<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="product_title"
                            name="product_title"
                            value={formData.product_title}
                            onChange={(e) => handleInputChange(e.target.value, "product_title")}
                          />
                          {errors?.product_title ? <p style={{ color: "red" }}>{errors?.product_title}</p> : ""}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">SKU No<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="sku"
                            name="sku"
                            value={formData.sku}
                            onChange={(e) => handleInputChange(e.target.value, "sku")}
                          />
                          {errors?.sku ? <p style={{ color: "red" }}>{errors?.sku}</p> : ""}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">HSN No<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="hsn"
                            name="hsn"
                            value={formData.hsn}
                            onChange={(e) => handleInputChange(e.target.value, "hsn")}
                          />
                          {errors?.hsn ? <p style={{ color: "red" }}>{errors?.hsn}</p> : ""}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">Return Period(In Days)<span style={{ color: 'red' }}>*</span></label>
                          <select
                                className="form-control"
                                onChange={(e) => {
                                  handleInputChange(e, "return_period"); // Pass event and field name
                                  handleCategorySelect(e.target.value);
                                }}
                                id="return_period"
                                name="return_period"
                              >
                                <option value="">Select Return Day</option>
                                <option value="7">7</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                              </select>
                          {errors?.return_period ? <p style={{ color: "red" }}>{errors?.return_period}</p> : ""}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">GST<span style={{ color: 'red' }}>*</span></label>
                          <select
                                className="form-control"
                                onChange={(e) => {
                                  handleInputChange(e, "gst"); // Pass event and field name
                                  handleCategorySelect(e.target.value);
                                }}
                                id="gst"
                                name="gst"
                              >
                                <option value="">Select GST</option>
                                <option value="5%">5 %</option>
                                <option value="9%">9 %</option>
                                <option value="12%">12 %</option>
                                <option value="18%">18 %</option>
                              
                              </select>
                          {errors?.gst ? <p style={{ color: "red" }}>{errors?.gst}</p> : ""}
                          </div>
                         <div className="col-md-6">
  <label htmlFor="exampleInputText">Category<span style={{ color: 'red' }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e.target.value, "category_id");
      handleCategorySelect(e.target.value.split("|")[0], e.target.value.split("|")[1]);
    }}
    onClick={handleSelectClick}
  >
    <option value="">Select Category</option>
    {categories.map((category) => (
      <option key={category._id} value={category._id}>
        {category.name}
      </option>
    ))}
  </select>
  {errors?.category_id ? <p style={{ color: "red" }}>{errors?.category_id}</p> : ""}
</div>

                              <div className="col-md-6">
                                <label htmlFor="exampleInputText">Sub Category<span style={{ color: 'red' }}>*</span></label>
                                <select
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e.target.value, "subcategory_id")}
                                >
                                  <option value="">Select Sub Category</option>
                                  {subCategories.map((subCategory) => (
                                    <option key={subCategory._id} value={subCategory._id}>
                                      {subCategory.name}
                                    </option>
                                  ))}
                                </select>
                                {errors?.subcategory_id ? <p style={{ color: "red" }}>{errors?.subcategory_id}</p> : ""}
                              </div>
                        </div>
                        <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">Collection<span style={{ color: 'red' }}>*</span></label>
                          <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e.target.value, "product_collection_id")}
                            onClick={handleSelectCollectionClick}
                          >
                            <option value="">Select Collection</option>
                            {collection.map((collections) => (
                              <option key={collections._id} value={collections._id}>
                                {collections.name}
                              </option>
                            ))}
                          </select>
                          {errors?.product_collection_id ? <p style={{ color: "red" }}>{errors?.product_collection_id}</p> : ""}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">Gender<span style={{ color: 'red' }}>*</span></label>
                          <select
                            className="form-control"
                             onChange={(e) => {
                                handleInputChange(e.target.value, "gender_id");
                                handlesizeSelect(e.target.value);
                              }}
                            onClick={handleSelectGenderClick}
                          >
                            <option value="">Select Gender</option>
                            {gender.map((genders) => (
                              <option key={genders._id} value={genders._id}>
                                {genders.name}
                              </option>
                            ))}
                          </select>
                          {errors?.gender_id ? <p style={{ color: "red" }}>{errors?.gender_id}</p> : ""}
                        </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Description<span style={{ color: 'red' }}>*</span></label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="product_description"
                            name="product_description"
                            value={formData.product_description}
                            onChange={(value) => handleInputChange(value, "product_description")}
                          />
                           {errors?.product_description ? <p style={{ color: "red" }}>{errors?.product_description}</p> : ""}
                        </div>
                        <div className="form-group product_detials_tables">
                        <table className="table">
  <thead>
    <tr>
      <th>#</th>
      <th>Colour</th>
      <th>Size/Yrs</th>
      <th>MR Price</th>
      <th>Selling Price</th>
      <th>Stock</th>
      <th>Show</th>
      <th>Total Stock</th>
      <th>Active</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
  {formData.variants.map((variant, index) => (
    <React.Fragment key={`variant_row_${index}`}>
      <tr>
        <td>{index + 1}</td>
        <td>
          <select
            className="form-control"
            id={`color_name_${index}`}
            name={`color_name_${index}`}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                `variant_${index}_color_name`,
                index
              )
            }
            onClick={handleSelectColorClick}
          >
            <option value="">Select colour</option>
            {color.map((colors) => (
              <option
                key={colors._id}
                value={`${colors.name}|${colors.color_code}`}
              >
                {colors.name}
              </option>
            ))}
          </select>
        </td>
       {/* <td>
            {sizelist.map((sizeOption, sizeIndex) => (
              <div key={`size_${index}_${sizeIndex}`}>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id={`size_${index}_${sizeOption.name}`}
                    name={`size_${index}_${sizeOption.name}`}
                    value={sizeOption.name}
                    checked={formData.variants[index]?.sizes?.includes(sizeOption.name) || false}
                    onChange={(e) =>
                      handleInputChange(
                        e.target.checked ? sizeOption.name : '',
                        `variant_${index}_size`,
                        index
                      )
                    }
                    className="form-check-input"
                  />
                  <label
                    htmlFor={`size_${index}_${sizeOption.name}`}
                    className="form-check-label"
                  >
                    {sizeOption.name}
                  </label>
                </div>
              </div>
            ))}
          </td> */}
 <td>
  {sizelist.map((sizeOption, sizeIndex) => (
    <div key={`size_${index}_${sizeIndex}`}>
      <div className="form-check">
        <input
          type="checkbox"
          id={`size_${index}_${sizeOption.name}`}
          name={`size_${index}_${sizeOption.name}`}
          value={sizeOption.name}
          checked={formData.variants[index]?.sizes?.includes(sizeOption.name) || false}
          onChange={(e) =>
            handleInputChange(
              e.target.checked ? sizeOption.name : "",
              `variant_${index}_size_${sizeOption.name}`,
              index
            )
          }
          className="form-check-input"
        />
        <label
          htmlFor={`size_${index}_${sizeOption.name}`}
          className="form-check-label"
        >
          {sizeOption.name}
        </label>
      </div>
    </div>
  ))}
</td>
<td>
  {sizelist.map((sizeOption, sizeIndex) => (
    <input
      key={`mrpprice_${index}_${sizeOption.name}`}
      type="number"
      className="form-control"
      id={`mrpprice_${index}_${sizeOption.name}`}
      name={`mrpprice_${index}_${sizeOption.name}`}
      value={formData.variants[index]?.size_details?.[sizeOption.name]?.mrpprice || ''}
      onChange={(e) =>
        handleInputChange(
          e.target.value,
          `variant_${index}_mrpprice_${sizeOption.name}`,
          index
        )
      }
    />
  ))}
</td>
<td>
  {sizelist.map((sizeOption, sizeIndex) => (
    <input
      key={`sellingprice_${index}_${sizeOption.name}`}
      type="number"
      className="form-control"
      id={`sellingprice_${index}_${sizeOption.name}`}
      name={`sellingprice_${index}_${sizeOption.name}`}
      value={formData.variants[index]?.size_details?.[sizeOption.name]?.sellingprice || ''}
      onChange={(e) =>
        handleInputChange(
          e.target.value,
          `variant_${index}_sellingprice_${sizeOption.name}`,
          index
        )
      }
    />
  ))}
</td>
<td>
  {sizelist.map((sizeOption, sizeIndex) => (
    <input
      key={`productsizestock_${index}_${sizeOption.name}`}
      type="number"
      className="form-control"
      id={`productsizestock_${index}_${sizeOption.name}`}
      name={`productsizestock_${index}_${sizeOption.name}`}
      value={formData.variants[index]?.size_details?.[sizeOption.name]?.productsizestock || ''}
      onChange={(e) =>
        handleInputChange(
          e.target.value,
          `variant_${index}_productsizestock_${sizeOption.name}`,
          index
        )
      }
    />
  ))}
</td>
<td>
  {sizelist.map((sizeOption, sizeIndex) => (
    <input
      type="checkbox"
      id={`productshow_${index}_${sizeOption.name}`}
      name={`productshow_${index}_${sizeOption.name}`}
      checked={formData.variants[index]?.size_details?.[sizeOption.name]?.productshow || false}
      onChange={(e) =>
        handleInputChange(
          e.target.checked,
          `variant_${index}_productshow_${sizeOption.name}`,
          index
        )
      }
      className="form-check"
    />
  ))}
</td>


        <td>
          <input
            type="number"
            className="form-control"
            id={`productstock_${index}`}
            name={`productstock_${index}`}
            value={variant.productstock || ''}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                `variant_${index}_productstock`,
                index
              )
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            className="form-check-input"
            id={`show_${index}`}
            name={`show_${index}`}
            checked={variant.show || false}
            onChange={(e) =>
              handleInputChange(
                e.target.checked,
                `variant_${index}_show`,
                index
              )
            }
          />
        </td>
        <td>
          {index > 0 && (
            <button
              type="button"
              className="btn btn-danger remove-variant"
              onClick={() => removeVariant(index)}
            >
              Remove
            </button>
          )}
        </td>
      </tr>
      <tr>
      <td colSpan="8">
  <div className="d-flex flex-row">
    <ImgCrop rotate aspect={1000 / 1500}>
      <Upload
        className="avatar-uploader"
        id={`image_1_${index}`}
        name={`image_1_${index}`}
        onChange={({ fileList }) => handleFileChange1(fileList, index)}
        listType="picture-card"
        fileList={null}
        beforeUpload={() => false}
        style={{ width: '100%', height: '100%' }}
      >
        {formData.variants[index].image_1 ? (
          <img src={URL.createObjectURL(formData.variants[index].image_1)} alt="size_image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        ) : (
          <div>
            <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 33 }}>
              <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
            </svg>
            <div className="ant-upload-text">Image1 </div>
          </div>
        )}
      </Upload>
    </ImgCrop>
    <ImgCrop rotate aspect={1000 / 1500}>
      <Upload
        className="avatar-uploader"
        id={`image_2_${index}`}
        name={`image_2_${index}`}
        onChange={({ fileList }) => handleFileChange3(fileList, index)}
        listType="picture-card"
        fileList={null}
        beforeUpload={() => false}
        style={{ width: '100%', height: '100%' }}
      >
        {formData.variants[index].image_2 ? (
          <img src={URL.createObjectURL(formData.variants[index].image_2)} alt="size_image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        ) : (
          <div>
            <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 33 }}>
              <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
            </svg>
            <div className="ant-upload-text">Image2 </div>
          </div>
        )}
      </Upload>
    </ImgCrop>
    <ImgCrop rotate aspect={1000 / 1500}>
      <Upload
        className="avatar-uploader"
        id={`image_3_${index}`}
        name={`image_3_${index}`}
        onChange={({ fileList }) => handleFileChange4(fileList, index)}
        listType="picture-card"
        fileList={null}
        beforeUpload={() => false}
        style={{ width: '100%', height: '100%' }}
      >
        {formData.variants[index].image_3 ? (
          <img src={URL.createObjectURL(formData.variants[index].image_3)} alt="size_image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        ) : (
          <div>
            <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 33 }}>
              <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
            </svg>
            <div className="ant-upload-text">Image3 </div>
          </div>
        )}
      </Upload>
    </ImgCrop>
    <ImgCrop rotate aspect={1000 / 1500}>
      <Upload
        className="avatar-uploader"
        id={`image_4_${index}`}
        name={`image_4_${index}`}
        onChange={({ fileList }) => handleFileChange5(fileList, index)}
        listType="picture-card"
        fileList={null}
        beforeUpload={() => false}
        style={{ width: '100%', height: '100%' }}
      >
        {formData.variants[index].image_4 ? (
          <img src={URL.createObjectURL(formData.variants[index].image_4)} alt="size_image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        ) : (
          <div>
            <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 33 }}>
              <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
            </svg>
            <div className="ant-upload-text">Image4 </div>
          </div>
        )}
      </Upload>
    </ImgCrop>
    <ImgCrop rotate aspect={1000 / 1500}>
      <Upload
        className="avatar-uploader"
        id={`image_5_${index}`}
        name={`image_5_${index}`}
        onChange={({ fileList }) => handleFileChange6(fileList, index)}
        listType="picture-card"
        fileList={null}
        beforeUpload={() => false}
        style={{ width: '100%', height: '100%' }}
      >
        {formData.variants[index].image_5 ? (
          <img src={URL.createObjectURL(formData.variants[index].image_5)} alt="size_image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        ) : (
          <div>
            <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 33 }}>
              <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
            </svg>
            <div className="ant-upload-text">Image5 </div>
          </div>
        )}
      </Upload>
    </ImgCrop>
  </div>
  <p style={{ color: "blue" }}>Recommended size: 1000x1500 pixels</p>
</td>
      </tr>
    </React.Fragment>
  ))}
</tbody>

</table>
  <div className="col-md-2">
    <button
      type="button"
      className="btn btn-primary"
      onClick={addVariant}
    >
      Add
    </button>
  </div>
</div>
<div className="form-group row">
  <div className="col-12">
  <h4 className="heading">Mark as</h4>
  <ul id="product_brand_wise" style={{ display: 'flex', flexWrap: 'wrap', listStyleType: 'none', padding: 0 }}>
  <li style={{ marginRight: '20px' }}>
    <div>
      <label htmlFor="exampleInputCheckbox">Hot List</label>
      <input
        type="checkbox"
        style={{ marginLeft: "14px", borderColor: "#df1616" }}
        className="form-check-input"
        id="hot_list"
        name="hot_list"
        checked={formData.hot_list === "Yes"}
        onChange={(e) => handleInputChange(e.target.checked, "hot_list")}
      />
    </div>
  </li>
  <li style={{ marginRight: '20px' }}>
    <div>
      <label htmlFor="exampleInputCheckbox">Latest Arrival</label>
      <input
        type="checkbox"
        className="form-check-input"
        id="latest_arrival"
        style={{ marginLeft: "14px", borderColor: "#df1616" }}
        name="latest_arrival"
        checked={formData.latest_arrival === "Yes"}
        onChange={(e) => handleInputChange(e.target.checked, "latest_arrival")}
      />
    </div>
  </li>
  <li style={{ marginRight: '20px' }}>
    <div>
      <label htmlFor="exampleInputCheckbox">New Brand Collection</label>
      <input
        type="checkbox"
        className="form-check-input"
        id="new_brand_collection"
        style={{ marginLeft: "14px", borderColor: "#df1616" }}
        name="new_brand_collection"
        checked={formData.new_brand_collection === "Yes"}
        onChange={(e) => handleInputChange(e.target.checked, "new_brand_collection")}
      />
    </div>
  </li>
  <li style={{ marginRight: '20px' }}>
    <div>
      <label htmlFor="exampleInputCheckbox">Best Sellers</label>
      <input
        type="checkbox"
        className="form-check-input"
        id="best_sellers"
        style={{ marginLeft: "14px", borderColor: "#df1616" }}
        name="best_sellers"
        checked={formData.best_sellers === "Yes"}
        onChange={(e) => handleInputChange(e.target.checked, "best_sellers")}
      />
    </div>
  </li>
  <li style={{ marginRight: '20px' }}>
    <div>
      <label htmlFor="exampleInputCheckbox">Returnable</label>
      <input
        type="checkbox"
        style={{ marginLeft: "14px", borderColor: "#df1616" }}
        className="form-check-input"
        id="returnable"
        name="returnable"
        checked={formData.returnable === "Yes"}
        onChange={(e) => handleInputChange(e.target.checked, "returnable")}
      />
    </div>
  </li>
  <li style={{ marginRight: '20px' }}>
    <div>
      <label htmlFor="exampleInputCheckbox">Cancellable</label>
      <input
        type="checkbox"
        style={{ marginLeft: "14px", borderColor: "#df1616" }}
        className="form-check-input"
        id="cancellable"
        name="cancellable"
        checked={formData.cancellable === "Yes"}
        onChange={(e) => handleInputChange(e.target.checked, "cancellable")}
      />
    </div>
  </li>
  <li style={{ marginRight: '20px' }}>
    <div>
      <label htmlFor="exampleInputCheckbox">Featured Products</label>
      <input
        type="checkbox"
        style={{ marginLeft: "14px", borderColor: "#df1616" }}
        className="form-check-input"
        id="featured_products"
        name="featured_products"
        checked={formData.featured_products === "Yes"}
        onChange={(e) => handleInputChange(e.target.checked, "featured_products")}
      />
    </div>
  </li>
</ul>

  {/* <ul id="product_brand_wise">
    <li>
      <div>
      <label htmlFor="exampleInputCheckbox">Hot List</label>
    <input
      type="checkbox"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      className="form-check-input"
      id="hot_list"
      name="hot_list"
      checked={formData.hot_list === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "hot_list")}
    />
      </div>
    </li>
    <li>
      <div>
      <label htmlFor="exampleInputCheckbox">Latest Arrival</label>
    <input
      type="checkbox"
      className="form-check-input"
      id="latest_arrival"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      name="latest_arrival"
      checked={formData.latest_arrival === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "latest_arrival")}
    />
      </div>
    </li>
    <li>
      <div>
      <label htmlFor="exampleInputCheckbox">New Brand Collection</label>
    <input
      type="checkbox"
      className="form-check-input"
      id="new_brand_collection"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      name="new_brand_collection"
      checked={formData.new_brand_collection === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "new_brand_collection")}
    />
      </div>
    </li>
    <li>
    <label htmlFor="exampleInputCheckbox">Best Sellers</label>
    <input
      type="checkbox"
      className="form-check-input"
      id="best_sellers"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      name="best_sellers"
      checked={formData.best_sellers === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "best_sellers")}
    />
    </li>
    <li>
    <label htmlFor="exampleInputCheckbox">Returnable</label>
    <input
      type="checkbox"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      className="form-check-input"
      id="returnable"
      name="returnable"
      checked={formData.returnable === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "returnable")}
    />
    </li>
    <li>
    <label htmlFor="exampleInputCheckbox">Cancellable</label>
    <input
      type="checkbox"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      className="form-check-input"
      id="cancellable"
      name="cancellable"
      checked={formData.cancellable === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "cancellable")}
    />
    </li>
    <li>
    <label htmlFor="exampleInputCheckbox">Featured Products</label>
  <input
    type="checkbox"
    style={{ marginLeft: "14px", borderColor: "#df1616" }}
    className="form-check-input"
    id="featured_products"
    name="featured_products"
    checked={formData.featured_products === "Yes"}
    onChange={(e) => handleInputChange(e.target.checked, "featured_products")}
  />
    </li>
  </ul> */}
  </div>

  {/* <div className="col-md-2">
    <label htmlFor="exampleInputCheckbox">Hot List</label>
    <input
      type="checkbox"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      className="form-check-input"
      id="hot_list"
      name="hot_list"
      checked={formData.hot_list === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "hot_list")}
    />
  </div>
  <div className="col-md-2">
    <label htmlFor="exampleInputCheckbox">Latest Arrival</label>
    <input
      type="checkbox"
      className="form-check-input"
      id="latest_arrival"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      name="latest_arrival"
      checked={formData.latest_arrival === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "latest_arrival")}
    />
  </div>
  <div className="col-md-2">
    <label htmlFor="exampleInputCheckbox">New Brand Collection</label>
    <input
      type="checkbox"
      className="form-check-input"
      id="new_brand_collection"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      name="new_brand_collection"
      checked={formData.new_brand_collection === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "new_brand_collection")}
    />
  </div>
  <div className="col-md-2">
    <label htmlFor="exampleInputCheckbox">Best Sellers</label>
    <input
      type="checkbox"
      className="form-check-input"
      id="best_sellers"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      name="best_sellers"
      checked={formData.best_sellers === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "best_sellers")}
    />
  </div>
  <div className="col-md-2">
    <label htmlFor="exampleInputCheckbox">Returnable</label>
    <input
      type="checkbox"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      className="form-check-input"
      id="returnable"
      name="returnable"
      checked={formData.returnable === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "returnable")}
    />
  </div>
  <div className="col-md-2">
    <label htmlFor="exampleInputCheckbox">Cancellable</label>
    <input
      type="checkbox"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      className="form-check-input"
      id="cancellable"
      name="cancellable"
      checked={formData.cancellable === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked, "cancellable")}
    />
  </div>

  <div className="col-md-2">
  <label htmlFor="exampleInputCheckbox">Featured Products</label>
  <input
    type="checkbox"
    style={{ marginLeft: "14px", borderColor: "#df1616" }}
    className="form-check-input"
    id="featured_products"
    name="featured_products"
    checked={formData.featured_products === "Yes"}
    onChange={(e) => handleInputChange(e.target.checked, "featured_products")}
  />
</div> */}

{formData.featured_products === "Yes" && (
  <div className="col-md-4">
  <label htmlFor="exampleInputCheckbox">Select Featured Products<span style={{ color: 'red' }}>*</span></label>
    <select
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e.target.value, "featured_products_type")}
                                >
    <option value="">Select Feacture </option>
                            {feactureList.map((genders) => (
                              <option key={genders._id} value={genders.feacture_slug}>
                                {genders.name}
                              </option>
                            ))}
                            </select>
                            {errors?.featured_products_type ? <p style={{ color: "red" }}>{errors?.featured_products_type}</p> : ""}
  </div>
)}
  {/* <div className="col-md-2">
    <label htmlFor="exampleInputCheckbox">Home Page(show)</label>
    <input
      type="checkbox"
      style={{ marginLeft: "14px", borderColor: "#df1616" }}
      className="form-check-input"
      id="show_home"
      name="show_home"
      checked={formData.show_home === "Yes"}
      onChange={(e) => handleInputChange(e.target.checked ? "Yes" : "No", "show_home")}
    />
  </div> */}
</div>

                               <div className="form-group">
                                <label htmlFor="fullname">Size Chart Image<span style={{ color: 'red' }}>*</span></label>
                              <ImgCrop rotate aspect={1200 / 900}>
  <Upload
    className="avatar-uploader"
    name="size_image"
    onChange={({ fileList }) => handleFileChange2(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.size_image ? (
      <img src={URL.createObjectURL(formData.size_image)} alt="size_image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 33 }}>
              <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
            </svg>
        <div className="ant-upload-text">Upload  </div>
      </div>
    )}
  </Upload>
</ImgCrop>
</div>
<p style={{ color: "blue" }}>Recommended size: 1200X900(width x height)px</p>
{errors?.size_image ? <p style={{ color: "red" }}>{errors?.size_image}</p> : ""}

                        {/* <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button> */}
         <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                  </>
                ) : (
                  'Submit'
                )}
              </button>
        <Link to="/productListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddProductpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default ProductCreate;
