import React, { useState } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { addBannerformData,getbrandDatafetch} from '../../Controller/Heading';
import AddbannerModel from "./AddHeadingpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import "react-dates/initialize";
import "./Heading.css";

function AddHeadingData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
    const [errors, setErrors] = useState('');
    const [subCategories, setSubCategoryData] = useState([]);
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [context, setContext] = useState(""); // State to hold the context (App or Web)
    const navigate = useNavigate();
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      page_name: "",
      title: "",
      description: "",
      brand_name:"",
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
     
    const handleInputChange = (e, field) => {
      const { name, value, files } = e.target;
      if (field === 'show') {
        setContext(value); // Update the context based on the selected page name
      }
      if (field === 'banner_image') {
        const file = files.length > 0 ? files[0] : null;
    
        if (file) {
          // Update the state with the file object
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: file,
          }));
        } else {
          // Handle the case where no file is selected (optional)
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: null,
          }));
        }
      } else {
        // Handling the case for brand_name separately
        if (field === 'brand_name') {
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: value, // Update the brand_name field with the selected value
          }));
        } else {
          setFormData((prevVal) => ({
            ...prevVal,
            [name]: value,
          }));
        }
    
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
      }
    };
    
    
    const fetchSubCategories = async (page_name) => {
      const options = {
        // Add any additional options needed for fetching sub-categories
        brand: page_name,
      };
    
      try {
        const result = await getbrandDatafetch(options, adminInformation.token);
        if (result.data && result.data.response) {
          const responseData = result.data.response.result;
          setSubCategoryData(responseData);
        } else {
          console.error("Unexpected response format:", result.data);
        }
      } catch (error) {
        console.error("Error fetching sub-category data:", error);
      }
    };
    const handleCategorySelect = (page_name) => {
  
      fetchSubCategories(page_name);
    };
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.page_name) {
        setErrors((preError) => ({
          ...preError,
          page_name: 'Page name is required.',
        }));
      } else if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: 'Title is required.',
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: 'Detail is required.',
        }));
      }else{
        const updateResult = await addBannerformData(formData, adminInformation.token);
        if(updateResult.status === 200){
            setFormData((prevFormData) => ({
                ...prevFormData,
               page_name: "",
              title: "",
              description: "",
            }));
          togglePopUp();
          }
        // }else{
        //   localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        // }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
 

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Heading </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                      {/*<div className="col-md-6">
                        <label htmlFor="fullname">Page Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="page_name"
                          name="page_name"
                          placeholder="Enter Social Icon Name"
                          onChange={handleInputChange}
                        />
                         {errors?.page_name?(<p style={{color:"red"}}>{errors?.page_name}</p>):''}
                      </div>*/}
                    <div className="col-md-6">
  <label htmlFor="fullname">Page Name <span style={{ color: "red" }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e, "page_name"); // Pass event and field name
      handleCategorySelect(e.target.value);
    }}
    id="page_name"
    name="page_name"
  >
    <option value="">Select Page Name</option>
    <option value="home">Home Page</option>
    <option value="list">Product List</option>
    <option value="brands">Brand Page</option>
  </select>
  {errors?.page_name ? <p style={{ color: "red" }}>{errors?.page_name}</p> : ''}
</div>


<div className="col-md-6">
  <label htmlFor="exampleInputText">Brand</label>
  <select
  className="form-control"
  id="brand_name"
  name="brand_name"
  onChange={(e) => handleInputChange(e, "brand_name")}
>
  <option value="">Select Brand Name</option>
  {subCategories.map((subCategory) => (
    <option key={subCategory._id} value={subCategory.subcategory_slug}>
      {subCategory.name}
    </option>
  ))}
</select>

 
</div>
<div className="col-md-6">
  <label htmlFor="fullname">Type <span style={{ color: "red" }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e, "type"); 
    }}
    id="type"
    name="type"
  >
    <option value="">Select Type</option>
    <option value="hot_list">Hot List</option>
    <option value="latest_arrival">Latest Arrival</option>
    <option value="featured_product">Featured Product</option>
    <option value="new_brand_collection">New Brand Collection</option>
    <option value="bestsellers">Bestsellers</option>
    
  </select>
  {errors?.type ? <p style={{ color: "red" }}>{errors?.type}</p> : ''}
</div>
   <div className="col-md-6">
                        <label htmlFor="fullname">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          name="title"
                          onChange={handleInputChange}
                          placeholder="Enter Title"
                        />
                         {errors?.title?(<p style={{color:"red"}}>{errors?.title}</p>):''}
                      </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleTextarea">Details</label>
                        <textarea
                          className="form-control"
                          id="description"
                          name="description"
                          onChange={handleInputChange}
                          rows="3"
                        ></textarea>
                           {errors?.description?(<p style={{color:"red"}}>{errors?.description}</p>):''}
                      </div>
                      
                     <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                      <Link to="/headingListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddbannerModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddHeadingData;
