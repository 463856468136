import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import "../../Contact Us/ContactUs.css";
import { deleteBlogcategoryDatafetch } from '../../Controller/Blog';

const BlogcategoryDeletePopUp = ({ popUpOpen, togglePopUp, itemIdToDelete }) => {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const navigate = useNavigate();
  const adminInformation = JSON.parse(adminInformationString);
  const handleCancel = () => {
    togglePopUp();
  };

 // In your SocialIcon component, modify the call to deleteSocialIconData
const handleDelete = async () => {
  const options = { id: itemIdToDelete }; // Ensure _id is present in options
  try {
    const result = await deleteBlogcategoryDatafetch(options  , adminInformation.token);
    if (result) {
      togglePopUp();
    } else {
      localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
      console.error("Unexpected response format:", result);
    }
  } catch (error) {
    console.error("Error deleting social icon data:", error);
  }
};


  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you really want to remove this item?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default BlogcategoryDeletePopUp;
