import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import OrderPopUp from "./OrderPopUp";
import OrderShippingPopUp from "./OrderShippingPopUp";
import OrdercancelPopUp from "./OrdercancelPopUp";
import Pagination from "@mui/material/Pagination";
import { getOrderShippingDatafetch } from "../Controller/Order";
import { BsThreeDots } from "react-icons/bs";

function ListShippingOrder() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdPass, setItemId] = useState(null);
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenshipping, setPopUpOpenshipping] = useState(false);
  const [itemIdPassShipping, setItemIdShipping] = useState(null);
  const [popUpOpenstatus, setPopUpOpenstatus] = useState(false);
  const [itemIdPasscancel, setItemIdcancel] = useState(null);
  const [itemIdTocancelstatus, setItemIdcancelstatus] = useState(null);
  const [orderList, setOrderData] = useState([]);
  const togglePopUp = (itemId, status) => {
    setPopUpOpen(!popUpOpen);
    setItemId(itemId);
    setItemIdStatus(status);
    getOrderdata();
  };

  const togglePopUpstatus = (itemId, status) => {
    setPopUpOpenstatus(!popUpOpenstatus);
    setItemIdcancel(itemId);
    setItemIdcancelstatus(status);
    getOrderdata();
  };

  const handleViewShipping = (item) => {
    setPopUpOpenshipping(!popUpOpenshipping);
    setItemIdShipping(item);
    getOrderdata();
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getOrderdata = async (page, product_name) => {
    const options = {
      page: page,
      product_name: product_name,
    };

    try {
      const result = await getOrderShippingDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.orders;
        console.log(responseData);
        const totalcount = result.data.response.result.totalCount;
        setOrderData(responseData);
        setTotalItems(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getOrderdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getOrderdata(currentPage, value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderdata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const handleViewDetail = (item) => {
    console.log(item);
    navigate('/orderDetail', { state: { selectedItem: item } });
  };

 
  
  const dropdownMenu = (itemId, status, item) => {
    let menuOptions;
  
      menuOptions = (
        <>
        <Menu.Item key="3" onClick={() => togglePopUpstatus(itemId, "Cancelled")}>
            <FaEdit />
            <span className="show_span_edit">Cancel</span>
          </Menu.Item>
          <Menu.Item key="4" onClick={() => handleViewDetail(item)}>
            <FaEdit />
            <span className="show_span_edit">View Details</span>
          </Menu.Item>
          <Menu.Item key="4" onClick={() => handleViewShipping(item)}>
            <FaEdit />
            <span className="show_span_edit">Order Shipping</span>
          </Menu.Item>
        </>
      );
    //}
  
    return <Menu>{menuOptions}</Menu>;
  };
  

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Order Shipping List </h1>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                    >
                      Order Shipping Table List
                    </h6>
                    {/* <div className="my_add_event_form_field" style={{ float: "right" }}>
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker" style={{ marginTop: "-30px" }}>
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search title"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                    </div> */}
                  </div>

                  <div className="card-body">
                    <div className="table-responsive" style={{ overflowX: 'auto' }}>
                      <table className="table table-bordered" style={{ minWidth: '100%' }}>
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Order Id</th>
                            <th scope="col">User Details</th>
                            <th scope="col">Shipping Address</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price (Rs)</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Payment Mode</th>
                            {/* <th scope="col">Payment Status</th> */}
                            <th scope="col">Order Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.length > 0 ? (
                            orderList.map((item, index) => (
                              
                              <tr key={index}>
                                <td>
                                 {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>{item.order_id}</td>
                                <td style={{ textTransform: 'capitalize' }}> 
                                {item.order_detail_id.firstName} {item.order_detail_id.lastName}, {item.order_detail_id.email},{item.order_detail_id.phone}</td>
                                <td style={{ textTransform: 'capitalize' }}>
                                {item.order_detail_id.landMark},{item.order_detail_id.address1},{item.order_detail_id.city}, {item.order_detail_id.state} {item.order_detail_id.country} , {item.order_detail_id.pincode} 
                               </td>
                                <td style={{ textTransform: 'capitalize' }}>
                                  {item.order_detail_id.product_name.join(", ")}
                                </td> 
                                <td>{item.order_detail_id.qty}</td>
                                <td>{item.order_detail_id.price} </td>
                                <td>{item.createdAt}</td>
                                <td style={{ textTransform: 'capitalize' }}>{item.order_detail_id.payment_mode}</td>
                                {/* <td
                                  style={{
                                    color:"#19a170",
                                  }}
                                >
                                  {item.order_detail_id.payment_status}
                                </td> */}
                                <td
                                  style={{
                                    color:"green",
                                  }}
                                >
                                 Shipping
                                </td>
                                <td>
                                    <Dropdown
                                      overlay={() =>
                                        dropdownMenu(
                                          item._id,
                                          item.status,item
                                        )
                                      }
                                      placement="bottomLeft"
                                      arrow
                                    >
                                      <BsThreeDots />
                                    </Dropdown>
                                    </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderPopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdPass={itemIdPass}
        itemIdToStatus={itemIdToStatus}
      />
       <OrderShippingPopUp
        popUpOpenshipping={popUpOpenshipping}
        handleViewShipping={handleViewShipping}
        itemIdPassShipping={itemIdPassShipping}
      />
      <OrdercancelPopUp
        popUpOpenstatus={popUpOpenstatus}
        togglePopUpstatus={togglePopUpstatus}
        itemIdPasscancel={itemIdPasscancel}
        itemIdTocancelstatus={itemIdTocancelstatus}
      />
    </div>
  );
}

export default ListShippingOrder;