import React, { useState,useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { updateBannerformData,getBannerData,getbrandDatafetch} from '../../Controller/Banner';
import {imageURL } from '../../Config/constant';
import AddbannerModel from "./Editbannerpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Banner.css";
import { useParams,useNavigate,Link} from "react-router-dom";
import ImgCrop from 'antd-img-crop';

function EditBannerData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [subCategories, setSubCategoryData] = useState([]);
  const [aspectRatio, setAspectRatio] = useState(2500 / 900); // Default aspect ratio for 'web'
  const [context, setContext] = useState(""); 
  const { edit } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const [popUpOpen, setPopUpOpen] = useState(false);
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      page_name: "",
      title: "",
      description: "",
      button_title: "",
      button_url: "",
      banner_image_1: null,
      banner_image_2: null,
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
     const [fileInputs, setFileInputs] = useState({
      banner_image_1: null,
      banner_image_2: null,
    });
    
    const handleFileChange1 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, banner_image_1: file }));
      }
    };
     const handleFileChange2 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, banner_image_2: file }));
      }
    };
    const handleFileChange3 = (e) => {
      const file = e.target.files[0];
      setFormData((prevFormData) => ({ ...prevFormData, video_url: file }));
    };
  
    //  const handleInputChange = (e) => {
    //   const { name, value} = e.target;
    //     setFormData((prevVal) => ({
    //       ...prevVal,
    //       [name]: value,
    //     }));
    //     setErrors((prevError) => ({
    //       ...prevError,
    //       [name]: '',
    //     }));
    // };
    const handleInputChange = (e, field) => {
      const { name, value, files } = e.target;
      if (field === 'show') {
        setContext(value); // Update the context based on the selected page name
      }
      if (value === 'app') {
        setAspectRatio(480 / 320); // Aspect ratio for 'app'
      } else if (value === 'web') {
        setAspectRatio(2500 / 900); // Aspect ratio for 'web'
      }
      if (field === 'banner_image') {
        const file = files.length > 0 ? files[0] : null;
    
        if (file) {
          // Update the state with the file object
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: file,
          }));
        } else {
          // Handle the case where no file is selected (optional)
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: null,
          }));
        }
      } else {
        // Handling the case for brand_name separately
        if (field === 'brand_name') {
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: value, // Update the brand_name field with the selected value
          }));
        } else {
          setFormData((prevVal) => ({
            ...prevVal,
            [name]: value,
          }));
        }
    
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
      }
    };
    
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.page_name) {
        setErrors((preError) => ({
          ...preError,
          page_name: 'Page name is required.',
        }));
      }else{
        setIsSubmitting(true);
        const updateResult = await updateBannerformData(formData, adminInformation.token);
        if(updateResult.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
               page_name: "",
              title: "",
              description: "",
              button_title: "",
              button_url: "",
              banner_image_1: null,
              banner_image_2: null,
            }));
            setIsSubmitting(false);
          togglePopUp();
        }else{
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
 /*************************************************************
 * Function Name  : getbannerdata
 * Purposs        : This function is used get data
 * Created Date   : 09-01-2024
 *************************************************************/
 const getbannerdata = async () => {
  const options = { id: edit };
  try {
    const result = await getBannerData(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      // Replace _id with id in the response data
      const modifiedData = { ...responseData, id: responseData._id };
      delete modifiedData._id; // Remove the _id property
      fetchSubCategories(responseData.page_name)
      setFormData(modifiedData);
      setFileInputs({
        banner_image_1: responseData.banner_image_1 || null,
        banner_image_2: responseData.banner_image_2 || null,
      });
    } else {
      localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};
const fetchSubCategories = async (page_name) => {
  const options = {
    // Add any additional options needed for fetching sub-categories
    brand: page_name,
  };

  try {
    const result = await getbrandDatafetch(options, adminInformation.token);
    console.log(result);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      setSubCategoryData(responseData);
    } else {
      localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching sub-category data:", error);
  }
};
const handleCategorySelect = (page_name) => {
  
  fetchSubCategories(page_name);
};

  useEffect(() => {
    window.scrollTo(0, 0);
    getbannerdata();
  }, []);
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
 

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Banner </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
  <div className="form-group row">
    <div className="col-md-6">
      <label htmlFor="fullname">Page Name <span style={{ color: "red" }}>*</span></label>
      <select
        className="form-control"
        onChange={(e) => {
          handleInputChange(e, "page_name"); // Pass event and field name
          handleCategorySelect(e.target.value);
        }}
        id="page_name"
        name="page_name"
        value={formData.page_name} // Set value to match formData
      >
        <option value="">Select Page Name</option>
        <option value="home">Home Page</option>
        <option value="list">Product List</option>
        <option value="brands">Brand Page</option>
      </select>
      {errors?.page_name ? <p style={{ color: "red" }}>{errors?.page_name}</p> : ''}
    </div>
    <div className="col-md-6">
      <label htmlFor="exampleInputText">Brand</label>
      <select
        className="form-control"
        id="brand_name"
        name="brand_name"
        onChange={(e) => handleInputChange(e, "brand_name")}
        value={formData.brand_name} // Set value to match formData
      >
        <option value="">Select Brand Name</option>
        {subCategories.map((subCategory) => (
          <option key={subCategory._id} value={subCategory.subcategory_slug}>
            {subCategory.name}
          </option>
        ))}
      </select>
    </div>
  
    <div className="col-md-6">
      <label htmlFor="fullname">Title</label>
      <input
        type="text"
        className="form-control"
        id="title"
        name="title"
        onChange={handleInputChange}
        value={formData.title} // Set value to match formData
        placeholder="Enter Title"
      />
      {errors?.title ? (<p style={{ color: "red" }}>{errors?.title}</p>) : ''}
    </div>
  </div>
  <div className="form-group">
    <label htmlFor="exampleTextarea">Details</label>
    <textarea
      className="form-control"
      id="description"
      name="description"
      onChange={handleInputChange}
      value={formData.description} // Set value to match formData
      rows="3"
    ></textarea>
    {errors?.description ? (<p style={{ color: "red" }}>{errors?.description}</p>) : ''}
  </div>
  <div className="form-group row">
  {formData.page_name !== 'brands' && (
                           <>
    <div className="col-md-4">
      <label htmlFor="fullname">Button Title</label>
      <input
        type="text"
        className="form-control"
        id="button_title"
        name="button_title"
        onChange={handleInputChange}
        value={formData.button_title} // Set value to match formData
        placeholder="Enter Social Icon Link"
      />
      {errors?.button_title ? (<p style={{ color: "red" }}>{errors?.button_title}</p>) : ''}
    </div>
    <div className="col-md-4">
      <label htmlFor="fullname">Button Url</label>
      <input
        type="text"
        className="form-control"
        id="button_url"
        name="button_url"
        onChange={handleInputChange}
        value={formData.button_url} // Set value to match formData
        placeholder="Enter Social Icon Link"
      />
      {errors?.button_url ? (<p style={{ color: "red" }}>{errors?.button_url}</p>) : ''}
    </div>
    <div className="col-md-4">
      <label htmlFor="fullname">Image Show <span style={{ color: "red" }}>*</span></label>
      <select
        className="form-control"
        onChange={(e) => {
          handleInputChange(e, "show"); // Pass event and field name
          handleCategorySelect(e.target.value);
        }}
        id="show"
        name="show"
        value={formData.show} // Set value to match formData
      >
        <option value="">Select Type</option>
        <option value="app">App</option>
        <option value="web">Web</option>
      </select>
      {errors?.show ? <p style={{ color: "red" }}>{errors?.show}</p> : ''}
    </div>
    <div className="col-md-6">
      <label htmlFor="fullname">Banner Image (Left side)</label>
      <ImgCrop rotate aspect={1299 / 659.74}>
  <Upload
    className="avatar-uploader"
    name="banner_image_1"
    onChange={({ fileList }) => handleFileChange1(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.banner_image_1 ? (
      <img
        src={typeof formData.banner_image_1 === 'string' ? `${imageURL}${formData.banner_image_1}` : URL.createObjectURL(formData.banner_image_1)}
        alt="subcategory"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    ) : (
      <div>
        <svg
          className="icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="image"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>

      {formData.show === 'app' ? (
        <p style={{ color: "green" }}>Recommended size: 480X320 (width x height) for the app</p>
      ) : formData.show === 'web' ? (
        <p style={{ color: "blue" }}>Recommended size: 1301x643 (width x height) for web</p>
      ) : null}
      {fileInputs.banner_image_1 && (
        <p>Current File: {fileInputs.banner_image_1}</p>
      )}
    </div>
    <div className="col-md-6">
      <label htmlFor="fullname">Banner Image (Right side)</label>
      <ImgCrop rotate aspect={aspectRatio}>
  <Upload
    className="avatar-uploader"
    name="banner_image_2"
    onChange={({ fileList }) => handleFileChange2(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.banner_image_2 ? (
      <img 
      src={typeof formData.banner_image_2 === 'string' ? `${imageURL}${formData.banner_image_2}` : URL.createObjectURL(formData.banner_image_2)}
      alt="subcategory" 
      style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>

      {formData.show === 'app' ? (
         <p style={{ color: "green" }}>Recommended size: 480X320 (width x height) for the app</p>
         ) : formData.show === 'web' ? (
           <p style={{ color: "blue" }}>Recommended size: 1301x643 (width x height) for web</p>
         ) : null}
      {fileInputs.banner_image_2 && (
        <p>Current File: {fileInputs.banner_image_2}</p>
      )}
    </div>
    </>
                        )}
  </div>
  <div className="form-group row">
{formData.page_name === 'brands' && (
  <>
  <div className="col-md-6">
        <label htmlFor="fullname">Video</label>
        <input
          type="file"
          className="form-control"
          id="video_url"
          name="video_url"
          onChange={handleFileChange3}
        />
       
      </div>
      <div className="col-md-6">
      <label htmlFor="fullname">Video Thumbnail</label>
     <ImgCrop rotate aspect={1301 / 703}>
  <Upload
    className="avatar-uploader"
    name="banner_image_1"
    onChange={({ fileList }) => handleFileChange1(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.banner_image_1 ? (
      <img  src={typeof formData.banner_image_1 === 'string' ? `${imageURL}${formData.banner_image_1}` : URL.createObjectURL(formData.banner_image_1)}
       alt="subcategory" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>
    </div>
    <div className="col-md-4">
    <label htmlFor="fullname">Image Show <span style={{ color: "red" }}>*</span></label>
    <select
      className="form-control"
      onChange={(e) => {
        handleInputChange(e, "show"); // Pass event and field name
        handleCategorySelect(e.target.value);
      }}
      id="show"
      name="show"
    >
      <option value="">Select Type</option>
      <option value="app">App</option>
      <option value="web">Web</option>
    </select>
    {errors?.show ? <p style={{ color: "red" }}>{errors?.show}</p> : ''}
  </div>
  <div className="col-md-6">
                          <label htmlFor="fullname">Banner Image (Right side)</label>
                          <ImgCrop rotate aspect={aspectRatio}>
  <Upload
    className="avatar-uploader"
    name="banner_image_2"
    onChange={({ fileList }) => handleFileChange2(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.banner_image_2 ? (
      <img   src={typeof formData.banner_image_2 === 'string' ? `${imageURL}${formData.banner_image_2}` : URL.createObjectURL(formData.banner_image_2)} 
      alt="subcategory" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>   {context === 'app' ? (
             <p style={{ color: "green" }}>Recommended size: 480X320 (width x height) for the app</p>
             ) : formData.show === 'web' ? (
               <p style={{ color: "blue" }}>Recommended size: 1301x643 (width x height) for web</p>
             ) : null}
  </div>
  
        </>
                      )}
                      </div>
                    
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span> 
                          Processing...
                          </>
                        ) : (
                          "Update"
                        )}
                      </button>
        <Link to="/bannerList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
</form>

                  </div>
                </div>
              </div>
            </div>
            <AddbannerModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBannerData;
