import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getSocialDatafetch = async (options , authToken)=>{
    try{
      const {page, icon_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'common/social-icon-list',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const statusCategoryData = async (options , authToken)=>{
  try{
    const {status,id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'common/status',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 
 /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
export const addSocialIconData = async (options , authToken) => {
    try {
      const { _id, icon_name, icon_link} = options;
      const posrData = {
        url: API_ADMIN_URL + 'common/social-icon',
        postData: options,
        headers: {
          authToken: authToken
        }
      };
      const res = await postRequest(posrData);
      if (res) {
        return res; // Corrected this line
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  }; // End of Function
  /*********************************************************
 *  This function is use to getSocialDataByid api
 *********************************************************/
export const getSocialIconData = async (options , authToken) =>{
  try{
      const {_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'common/get-social-icon',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
/*********************************************************
 *  This function is use to editSocialIconData api
 *********************************************************/
export const editSocialIconData = async (options , authToken) => {
  try {
    const { _id, icon_name, icon_link} = options;
    const posrData = {
      url: API_ADMIN_URL + 'common/edit-social-icon',
      postData: options,
      headers: {
        authToken: authToken
      }
    };
    const res = await postRequest(posrData);
    if (res) {
      return res; // Corrected this line
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
}; // End of Function
/*********************************************************
 *  This function is use to editSocialIconData api
 *********************************************************/
export const deleteSocialIconData = async (options ,authToken) => {
  try {
    const { _id } = options;
    const postData = {
      _id: _id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'common/delete-social-icon',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
