import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import "../Contact Us/ContactUs.css";
import { useParams, useNavigate } from "react-router-dom";
import {deletebulkProductDatafetch} from "../Controller/Product";

const ProductBulkDeletePopUp = ({ popBulkUpOpen, toggleBulkPopUp, itemIdToBulkDelete }) => {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const navigate = useNavigate();
  const adminInformation = JSON.parse(adminInformationString);

  const handleCancel = () => {
    toggleBulkPopUp();
  };

  const handleDelete = async () => {
    if (!itemIdToBulkDelete) return; // Add null check to prevent deletion if itemIdToBulkDelete is null

    const options = { ids: itemIdToBulkDelete };
    try {
      const result = await deletebulkProductDatafetch(options, adminInformation.token);
      if (result) {
        toggleBulkPopUp();
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error deleting social icon data:", error);
    }
  };

  // Check if itemIdToBulkDelete is an empty array
  if (Array.isArray(itemIdToBulkDelete) && itemIdToBulkDelete.length === 0) {
    return (
      <Dialog
        open={popBulkUpOpen}
        onClose={toggleBulkPopUp}
        maxWidth="md"
        PaperProps={{
          className: "myDialogPopUp",
        }}
      >
        <div className="myDrawer">
          <div className="myMainDrawerClass">
            <div>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                className="myFilterClass"
              >
                Confirm
              </Typography>
            </div>
            <div>
              <IconButton onClick={toggleBulkPopUp}>
                <IoMdClose />
              </IconButton>
            </div>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <p>Please select at least one item to delete.</p>
          </Typography>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Ok
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  // If itemIdToBulkDelete is not an empty array, render the regular confirmation dialog
  return (
    <Dialog
      open={popBulkUpOpen}
      onClose={toggleBulkPopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={toggleBulkPopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you really want to remove this item?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ProductBulkDeletePopUp;
