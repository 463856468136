import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import {
  updateProductformData,
  getProductdata,
  getFeacturedata,
  getSizeDatafetch,
  getCategoryDatafetch,
  getSubCategoryDatafetch,
  getCollectionDatafetch,
  getGenderDatafetch,
  getColorDatafetch,
} from "../Controller/Product";
import { useParams, useNavigate } from "react-router-dom";
import AddProductpopup from "./EditProductpopup";
import ImgCrop from "antd-img-crop";
import { imageURL } from "../Config/constant";
function EditProductData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [categories, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    product_name: "",
    category_id: "",
    subcategory_id: "",
    product_collection_id: "",
    gender_id: "",
    size_image: null,
    product_title: "",
    product_description: "",
    return_period: "",
    cancellable: "",
    returnable: "",
    hot_list: "",
    show_home: "",
    latest_arrival: "",
    featured_products: "",
    new_brand_collection: "",
    best_sellers: "",
    gst: "",
    hsn: "",
    sku: "",
    color_size_details: [
      {
        colorname: "",
        color_code: "",
        sizes: [
          {
            size: "",
            mrp_price: "",
            selling_price: "",
            productsizestock: "",
            productshow: false
          }
        ],
        productstock: "",
        show: false,
        images: [null, null, null, null, null],
      },
    ],
  });
  
  
  

  const handleFileChange = (fileList, index, imageIndex) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };
        // Check if color_size_details[index] exists and initialize it if not
        if (!updatedFormData.color_size_details[index]) {
          updatedFormData.color_size_details[index] = {};
        }
        // Update the corresponding image in color_size_details
        updatedFormData.color_size_details[index][`image_${imageIndex}`] = file;
        return updatedFormData;
      });
    }
  };
  // const handleInputChange = (selectedValue, field, index) => {
  //   if (field === "product_description") {
  //     setFormData((prevVal) => ({
  //       ...prevVal,
  //       [field]: selectedValue,
  //     }));
  //   } else if (
  //     field === "cancellable" ||
  //     field === "returnable" ||
  //     field === "hot_list" ||
  //     field === "latest_arrival" ||
  //     field === "featured_products" ||
  //     field === "new_brand_collection" ||
  //     field === "best_sellers" ||
  //     field === "show_home"
  //   ) {
  //     setFormData((prevVal) => ({
  //       ...prevVal,
  //       [field]: selectedValue === false ? "No" : "Yes",
  //     }));
  //   } else if (field.startsWith("variant_")) {
  //     const variantIndex = parseInt(field.split("_")[1], 10);
  //     const fieldName = field.split("_")[2];
  
  //     setFormData((prevVal) => {
  //       const updatedColorSizeDetails = [...prevVal.color_size_details];
  //       const variant = { ...updatedColorSizeDetails[variantIndex] };
  
  //       if (fieldName === "mrpprice" || fieldName === "sellingprice" || fieldName === "productsizestock" || fieldName === "productshow") {
  //         const sizeName = field.split("_")[3];
  //         if (!variant.sizes) {
  //           variant.sizes = [];
  //         }
  //         const sizeIndex = variant.sizes.findIndex((size) => size.size === sizeName);
  //         if (sizeIndex === -1) {
  //           variant.sizes.push({ size: sizeName, [fieldName]: selectedValue });
  //         } else {
  //           variant.sizes[sizeIndex][fieldName] = selectedValue;
  //         }
  //       } else if (fieldName === "size") {
  //         if (!variant.sizes) {
  //           variant.sizes = [];
  //         }
  //         const existingIndex = variant.sizes.findIndex((size) => size.size === selectedValue);
  //         if (existingIndex === -1) {
  //           variant.sizes.push({ size: selectedValue });
  //         } else {
  //           variant.sizes.splice(existingIndex, 1);
  //         }
  //       } else if (fieldName === "colorname") {
  //         const [colorName, colorCode] = selectedValue.split("|");
  //         variant.color_name = colorName;
  //         variant.color_code = colorCode || "";
  //       } else {
  //         variant[fieldName] = selectedValue;
  //       }
  
  //       updatedColorSizeDetails[variantIndex] = variant;
  //       return { ...prevVal, color_size_details: updatedColorSizeDetails };
  //     });
  //   } else {
  //     setFormData((prevVal) => ({
  //       ...prevVal,
  //       [field]: selectedValue,
  //     }));
  //     setErrors((prevError) => ({
  //       ...prevError,
  //       [field]: "",
  //     }));
  //   }
  
  //   if (field === "featured_products" && selectedValue === true) {
  //     if (!formData.featured_products_type) {
  //       setErrors((prevError) => ({
  //         ...prevError,
  //         featured_products_type: "Select Featured Products is required",
  //       }));
  //     }
  //   }
  // };
  const handleInputChange = (selectedValue, field, index) => {
    if (field === "product_description") {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
    } else if (
      field === "cancellable" ||
      field === "returnable" ||
      field === "hot_list" ||
      field === "latest_arrival" ||
      field === "featured_products" ||
      field === "new_brand_collection" ||
      field === "best_sellers" ||
      field === "show_home"
    ) {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue === false ? "No" : "Yes",
      }));
    } else if (field.startsWith("variant_")) {
      const variantIndex = parseInt(field.split("_")[1], 10);
      const fieldName = field.split("_")[2];
      const sizeName = field.split("_")[3];
  
      setFormData((prevVal) => {
        const updatedColorSizeDetails = [...prevVal.color_size_details];
        const variant = { ...updatedColorSizeDetails[variantIndex] };
  
        if (fieldName === "mrpprice" || fieldName === "sellingprice" || fieldName === "productsizestock" || fieldName === "productshow") {
          if (!variant.sizes) {
            variant.sizes = [];
          }
          const sizeIndex = variant.sizes.findIndex((size) => size.size === sizeName);
          if (sizeIndex === -1) {
            variant.sizes.push({ size: sizeName, [fieldName]: selectedValue });
          } else {
            variant.sizes[sizeIndex][fieldName] = selectedValue;
          }
        } else if (fieldName === "size") {
          if (!variant.sizes) {
            variant.sizes = [];
          }
          const existingIndex = variant.sizes.findIndex((size) => size.size === sizeName);
          if (selectedValue) {
            if (existingIndex === -1) {
              variant.sizes.push({ size: selectedValue });
            }
          } else {
            if (existingIndex !== -1) {
              variant.sizes.splice(existingIndex, 1);
            }
          }
        } else if (fieldName === "colorname") {
          const [colorName, colorCode] = selectedValue.split("|");
          variant.color_name = colorName;
          variant.color_code = colorCode || "";
        } else {
          variant[fieldName] = selectedValue;
        }
  
        updatedColorSizeDetails[variantIndex] = variant;
        return { ...prevVal, color_size_details: updatedColorSizeDetails };
      });
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [field]: "",
      }));
    }
  
    if (field === "featured_products" && selectedValue === true) {
      if (!formData.featured_products_type) {
        setErrors((prevError) => ({
          ...prevError,
          featured_products_type: "Select Featured Products is required",
        }));
      }
    }
  };
  
  
  
  
const [fileInputs, setFileInputs] = useState({
    size_image: null,
  });

  const [subCategories, setSubCategoryData] = useState([]);
  const [collection, setCollectionData] = useState([]);
  const [gender, setGenderData] = useState([]);
  const [sizelist, setSizeData] = useState([]);
  const [color, setcolorData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [feactureList, setFeactureData] = useState([]);
  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.product_name) {
        setErrors((preError) => ({
          ...preError,
          product_name: "Product name is required.",
        }));
      } else if (!formData.product_title) {
        setErrors((preError) => ({
          ...preError,
          product_title: "Product title is required.",
        }));
      } else if (!formData.category_id) {
        setErrors((preError) => ({
          ...preError,
          category_id: "Category is required.",
        }));
      } else if (!formData.subcategory_id) {
        setErrors((preError) => ({
          ...preError,
          subcategory_id: "Sub category is required.",
        }));
      } else if (!formData.product_collection_id) {
        setErrors((preError) => ({
          ...preError,
          product_collection_id: "Collection is required.",
        }));
      } else if (!formData.gender_id) {
        setErrors((preError) => ({
          ...preError,
          gender_id: "Gender is required.",
        }));
      } else if (!formData.gst) {
        setErrors((preError) => ({
          ...preError,
          gst: "Gst is required.",
        }));
      }else if (!formData.sku) {
        setErrors((preError) => ({
          ...preError,
          sku: "sku No is required.",
        }));
      }else if (!formData.hsn) {
        setErrors((preError) => ({
          ...preError,
          hsn: "Hsn no is required.",
        }));
      } else if (!formData.product_description) {
        setErrors((preError) => ({
          ...preError,
          product_description: "Product description is required.",
        }));
      } else if (
        formData.featured_products === "Yes" &&
        formData.featured_products_type === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          featured_products_type: "Select Featured Products is required",
        }));
      } else if (!formData.size_image) {
        setErrors((preError) => ({
          ...preError,
          size_image: "Image is required.",
        }));
      } else {
        setIsSubmitting(true);
        formData.return_period = document.getElementById("return_period").value;
        formData.gst = document.getElementById("gst").value;
       const updateResult = await updateProductformData(
           formData,
           adminInformation.token,
           formData.color_size_details
         );
        if (updateResult.data.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            product_name: "",
            category: "",
            ingredients: "",
            recipes: "",
            page_show: "",
            page_show_main: "",
            product_description: "",
            size_image: "",
            color_size_details: prevFormData.color_size_details.map(
              (variant) => ({
                ...variant,
              })
            ),
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
           localStorage.removeItem("TOKEN");
           localStorage.removeItem("ADMIN-INFO");
           navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  // const handleFileChange2 = (e) => {
  //   e.preventDefault(); // Prevent default behavior
  //   const file = e.target.files[0];
  //   setFileInputs((prevFileInputs) => ({
  //     ...prevFileInputs,
  //     size_image: file,
  //   }));
  // };
  // const handleFileChange2 = (e) => {
  //   const file = e.target.files[0];
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     size_image: file,
  //   }));
  // };

  const handleFileChange2 = (fileList) => {
    // Ensure fileList is not empty
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, size_image: file }));
    }
  };

  /*************************************************************
          End Function
   *************************************************************/
  /*************************************************************
   * Function Name  : getblogdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getproductfetchdata = async () => {
    const options = { id: edit };
    try {
      const result = await getProductdata(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData1 = result.data.response.result[0];
  
        // Set selected category and subcategory
        setSelectedCategory(responseData1.category_id);
        fetchSizeCategories(responseData1.gender_id);
        setSelectedSubCategory(responseData1.subcategory_id);
  
        // Prepare sizes array for each color_size_detail
        const updatedColorSizeDetails = responseData1.color_size_details.map((detail) => ({
          ...detail,
          sizes: detail.sizes.map((size) => ({
            ...size,
            mrpprice: parseFloat(size.mrp_price) || 0, // Convert to number, default to 0 if empty or NaN
            sellingprice: parseFloat(size.selling_price) || 0, // Convert to number, default to 0 if empty or NaN
            productsizestock: size.productsizestock || "",
            productshow: size.productshow === "true", // Convert string to boolean
          })),
        }));
        // Update formData state
        setFormData({
          ...responseData1,
          id: responseData1._id, // Set the id property
          color_size_details: updatedColorSizeDetails, // Set updated color_size_details
        });
  
        // Set file inputs state
        setFileInputs({
          size_image: responseData1.size_image || null,
        });
  
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  
  const fetchSubCategories = async (categoryId) => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      category_id: categoryId,
    };

    try {
      const result = await getSubCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSubCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  const fetchCollection = async () => {
    const options = {};
    try {
      const result = await getCollectionDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCollectionData(responseData);
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const fetchGender = async () => {
    const options = {};
    try {
      const result = await getGenderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setGenderData(responseData);
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const fetchColor = async () => {
    const options = {};
    try {
      const result = await getColorDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setcolorData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const fetchSizeCategories = async (gender_id) => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      gender_id: gender_id,
    };

    try {
      const result = await getSizeDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSizeData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };

  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const fetchFeacture = async () => {
    const options = {};
    try {
      const result = await getFeacturedata(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setFeactureData(responseData);
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      color_size_details: [
        ...prevVal.color_size_details,
        {
          colorname: "",
          color_code: "",
          sizes: [
            {
              size: "",
              mrpprice: "",
              sellingprice: "",
              productsizestock: "",
              productshow: false,
            },
          ],
          productstock: "",
          show: false,
          images: [null, null, null, null, null],
        },
      ],
    }));
  };
  

  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedcolor_size_details = [...prevVal.color_size_details];
      updatedcolor_size_details.splice(index, 1);
      return { ...prevVal, color_size_details: updatedcolor_size_details };
    });
  };
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    handleInputChange(categoryId, "category_id");

    // Optional: Clear the selected subcategory when the category changes
    setSelectedSubCategory("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getproductfetchdata();
    fetchCategories();
    fetchCollection();
    fetchGender();
    fetchColor();
    fetchFeacture();
    // Simulate a category change to load initial subcategories
    if (categories.length > 0) {
      const initialCategory = categories[0]._id; // Choose the initial category (you can modify this logic based on your requirements)
      fetchSubCategories(initialCategory);
      setSelectedCategory(initialCategory);
    }
  }, []); // Empty dependency array means this effect runs only on mount

  // ...

  // Modify the second useEffect to handle category and subcategory changes
  useEffect(() => {
    window.scrollTo(0, 0);
    getproductfetchdata();
    fetchCategories();
    fetchCollection();
    fetchGender();
    fetchColor();

    // Fetch subcategories only if a category is selected
    if (selectedCategory) {
      fetchSubCategories(selectedCategory);
      setFormData((prevVal) => ({
        ...prevVal,
        subcategory_id: "",
      }));
    }
  }, [selectedCategory]);

  useEffect(() => {
    setFormData((prevVal) => ({
      ...prevVal,
      subcategory_id: selectedSubCategory,
    }));
  }, [selectedSubCategory]);

  const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column product_create">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Edit Product Content</h5>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group row">
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Product Name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="product_name"
                              name="product_name"
                              value={formData.product_name}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "product_name"
                                )
                              }
                            />
                            {errors?.product_name ? (
                              <p style={{ color: "red" }}>
                                {errors?.product_name}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Product Title
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="product_title"
                              name="product_title"
                              value={formData.product_title}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "product_title"
                                )
                              }
                            />
                            {errors?.product_title ? (
                              <p style={{ color: "red" }}>
                                {errors?.product_title}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">SKU No<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="sku"
                            name="sku"
                            value={formData.sku}
                            onChange={(e) => handleInputChange(e.target.value, "sku")}
                          />
                          {errors?.sku ? <p style={{ color: "red" }}>{errors?.sku}</p> : ""}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">HSN No<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="hsn"
                            name="hsn"
                            value={formData.hsn}
                            onChange={(e) => handleInputChange(e.target.value, "hsn")}
                          />
                          {errors?.hsn ? <p style={{ color: "red" }}>{errors?.hsn}</p> : ""}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Return Period(In Days)
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "return_period"
                                ); // Pass selected value and field name
                              }}
                              id="return_period"
                              name="return_period"
                              value={formData.return_period} // Set the value attribute to the value stored in formData.return_period
                            >
                              <option value="">Select Return Day</option>
                              <option value="7">7</option>
                              <option value="15">15</option>
                              <option value="30">30</option>
                            </select>

                            {errors?.return_period ? (
                              <p style={{ color: "red" }}>
                                {errors?.return_period}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              GST<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                handleInputChange(e.target.value, "gst"); // Pass selected value and field name
                              }}
                              id="gst"
                              name="gst"
                              value={formData.gst} // Set the value attribute to the value stored in formData.return_period
                            >
                              <option value="">Select Gst</option>
                              <option value="5%">5 %</option>
                                <option value="9%">9 %</option>
                                <option value="12%">12 %</option>
                                <option value="18%">18 %</option>
                            </select>

                            {errors?.gst ? (
                              <p style={{ color: "red" }}>{errors?.gst}</p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              Category<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              onChange={handleCategoryChange}
                              value={selectedCategory}
                            >
                              <option value="">Select Category</option>
                              {categories.map((category) => (
                                <option
                                  key={category._id}
                                  value={category._id}
                                  selected={
                                    category._id === formData.category_id
                                  } // Set selected based on the current formData
                                >
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            {errors?.category_id ? (
                              <p style={{ color: "red" }}>
                                {errors?.category_id}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              Sub Category
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "subcategory_id"
                                )
                              }
                              value={formData.subcategory_id}
                            >
                              <option value="">Select Sub Category</option>
                              {subCategories.map((subCategory) => (
                                <option
                                  key={subCategory._id}
                                  value={subCategory._id}
                                  selected={
                                    subCategory._id === formData.subcategory_id
                                  }
                                >
                                  {subCategory.name}
                                </option>
                              ))}
                            </select>
                            {errors?.subcategory_id ? (
                              <p style={{ color: "red" }}>
                                {errors?.subcategory_id}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              Collection<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "product_collection_id"
                                )
                              }
                            >
                              <option value="">Select Collection</option>
                              {collection.map((collections) => (
                                <option
                                  key={collections._id}
                                  value={collections._id}
                                  selected={
                                    collections._id ===
                                    formData.product_collection_id
                                  }
                                >
                                  {collections.name}
                                </option>
                              ))}
                            </select>
                            {errors?.product_collection_id ? (
                              <p style={{ color: "red" }}>
                                {errors?.product_collection_id}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              Gender<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                handleInputChange(e.target.value, "gender_id")
                              }
                            >
                              <option value="">Select Gender</option>
                              {gender.map((genders) => (
                                <option
                                  key={genders._id}
                                  value={genders._id}
                                  selected={genders._id === formData.gender_id}
                                >
                                  {genders.name}
                                </option>
                              ))}
                            </select>
                            {errors?.gender_id ? (
                              <p style={{ color: "red" }}>
                                {errors?.gender_id}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Description<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="product_description"
                            name="product_description"
                            value={formData.product_description}
                            onChange={(value) =>
                              handleInputChange(value, "product_description")
                            }
                          />
                        </div>

                        <div className="form-group product_detials_tables">
                          <table className="table">
                            <thead>
                            <tr>
      <th>#</th>
      <th>Colour</th>
      <th>Size</th>
      <th>MR Price</th>
      <th>Selling Price</th>
      <th>Stock</th>
      <th>Show</th>
      <th>Total Stock</th>
      <th>Active</th>
      <th>Action</th>
    </tr>
                            </thead>
                            <tbody>
  {formData.color_size_details.map((variant, variantIndex) => (
    <React.Fragment key={variantIndex}>
      <tr>
        <td>{variantIndex + 1}</td>
        <td>
         <select
  className="form-control"
  id={`colorname_${variantIndex}`}
  name={`colorname_${variantIndex}`}
  onChange={(e) =>
    handleInputChange(
      e.target.value,
      `variant_${variantIndex}_colorname`,
      variantIndex
    )
  }
  //value={`${variant.color_name}|${variant.color_code || ""}`}
>
  <option value="">Select colour</option>
  {color.map((colors) => (
    <option
      key={colors._id}
      value={`${colors.name}|${colors.color_code}`}
      selected={colors.name === variant.color_name}
    >
      {colors.name}
    </option>
  ))}
</select>

        </td>
        <td>
  {sizelist.map((sizeOption, sizeIndex) => (
    <div key={`size_${variantIndex}_${sizeIndex}`}>
      <div className="form-check">
        <input
          type="checkbox"
          id={`size_${variantIndex}_${sizeOption.name}`}
          name={`size_${variantIndex}_${sizeOption.name}`}
          value={sizeOption.name}
          checked={variant.sizes.some(size => size.size === sizeOption.name)}
          onChange={(e) =>
            handleInputChange(
              e.target.checked ? sizeOption.name : "",
              `variant_${variantIndex}_size_${sizeOption.name}`,
              variantIndex
            )
          }
          className="form-check-input"
        />
        <label
          htmlFor={`size_${variantIndex}_${sizeOption.name}`}
          className="form-check-label"
        >
          {sizeOption.name}
        </label>
      </div>
    </div>
  ))}
</td>

        <td>
          {sizelist.map((sizeOption, sizeIndex) => {
            const sizeDetail = variant.sizes.find((size) => size.size === sizeOption.name) || {};
            return (
              <input
                key={`mrpprice_${variantIndex}_${sizeOption.name}`}
                type="number"
                className="form-control"
                id={`mrpprice_${variantIndex}_${sizeOption.name}`}
                name={`mrpprice_${variantIndex}_${sizeOption.name}`}
                value={sizeDetail.mrpprice || ""}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    `variant_${variantIndex}_mrpprice_${sizeOption.name}`,
                    variantIndex
                  )
                }
              />
            );
          })}
        </td>
        <td>
          {sizelist.map((sizeOption, sizeIndex) => {
            const sizeDetail = variant.sizes.find((size) => size.size === sizeOption.name) || {};
            return (
              <input
                key={`sellingprice_${variantIndex}_${sizeOption.name}`}
                type="number"
                className="form-control"
                id={`sellingprice_${variantIndex}_${sizeOption.name}`}
                name={`sellingprice_${variantIndex}_${sizeOption.name}`}
                value={sizeDetail.sellingprice || ""}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    `variant_${variantIndex}_sellingprice_${sizeOption.name}`,
                    variantIndex
                  )
                }
              />
            );
          })}
        </td>
        <td>
          {sizelist.map((sizeOption, sizeIndex) => {
            const sizeDetail = variant.sizes.find((size) => size.size === sizeOption.name) || {};
            return (
              <input
                key={`productsizestock_${variantIndex}_${sizeOption.name}`}
                type="number"
                className="form-control"
                id={`productsizestock_${variantIndex}_${sizeOption.name}`}
                name={`productsizestock_${variantIndex}_${sizeOption.name}`}
                value={sizeDetail.productsizestock || ""}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    `variant_${variantIndex}_productsizestock_${sizeOption.name}`,
                    variantIndex
                  )
                }
              />
            );
          })}
        </td>
        <td>
          {sizelist.map((sizeOption, sizeIndex) => {
            const sizeDetail = variant.sizes.find((size) => size.size === sizeOption.name) || {};
            return (
              <input
                key={`productshow_${variantIndex}_${sizeOption.name}`}
                type="checkbox"
                id={`productshow_${variantIndex}_${sizeOption.name}`}
                name={`productshow_${variantIndex}_${sizeOption.name}`}
                value={sizeOption.name}
                checked={sizeDetail.productshow || false}
                onChange={(e) =>
                  handleInputChange(
                    e.target.checked,
                    `variant_${variantIndex}_productshow_${sizeOption.name}`,
                    variantIndex
                  )
                }
                className="form-check"
              />
            );
          })}
        </td>
        <td>
          <input
            type="number"
            className="form-control"
            id={`productstock${variantIndex}`}
            name={`productstock${variantIndex}`}
            value={variant.productstock}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                `variant_${variantIndex}_productstock`,
                variantIndex
              )
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            className="form-check"
            id={`show_${variantIndex}`}
            name={`show_${variantIndex}`}
            checked={variant.show}
            onChange={(e) =>
              handleInputChange(
                e.target.checked,
                `variant_${variantIndex}_show`,
                variantIndex
              )
            }
          />
        </td>
        <td>
          {variantIndex > 0 && (
            <button
              type="button"
              className="btn btn-danger remove-variant"
              onClick={() => removeVariant(variantIndex)}
            >
              Remove
            </button>
          )}
        </td>
      </tr>
      <tr>
                                      <td colSpan="8">
                                        <div className="d-flex flex-row">
                                          {[1, 2, 3, 4, 5].map((imageIndex) => (
                                            <ImgCrop
                                              key={`image_crop_${variantIndex}_${imageIndex}`}
                                              rotate
                                              aspect={1000 / 1500}
                                            >
                                              <Upload
                                                className="avatar-uploader"
                                                id={`image_${imageIndex}_${variantIndex}`}
                                                name={`image_${imageIndex}_${variantIndex}`}
                                                onChange={({ fileList }) =>
                                                  handleFileChange(
                                                    fileList,
                                                    variantIndex,
                                                    imageIndex
                                                  )
                                                }
                                                listType="picture-card"
                                                fileList={null}
                                                beforeUpload={() => false}
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              >
                                                {formData.color_size_details[
                                                  variantIndex
                                                ] &&
                                                formData.color_size_details[
                                                  variantIndex
                                                ][`image_${imageIndex}`] ? ( // Check if an image is selected for the current variant and image index
                                                  <img
                                                    src={URL.createObjectURL(
                                                      formData
                                                        .color_size_details[
                                                          variantIndex
                                                      ][`image_${imageIndex}`]
                                                    )} // Create URL for selected image
                                                    alt={`size_image_${imageIndex}`}
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                ) : variant.images &&
                                                  variant.images[
                                                    imageIndex - 1
                                                  ] ? ( // Check if variant.images is defined and the image index exists
                                                  <img
                                                    src={`${imageURL}${
                                                      variant.images[
                                                        imageIndex - 1
                                                      ]
                                                    }`} // Display the previously selected image
                                                    alt={`size_image_${imageIndex}`}
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                ) : ( 
                                                  <div>
                                                    <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 33 }}>
              <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
            </svg>
            <div className="ant-upload-text">Upload</div>
                                                  </div>
                                                )}
                                              </Upload>
                                            </ImgCrop>
                                          ))}
                                        </div>
                                        <p style={{ color: "blue" }}>Recommended size: 1000x1500 pixels</p>
                                      </td>
                                    </tr>
    </React.Fragment>
  ))}
</tbody>;

                          </table>
                          <br />
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={addVariant}
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        <div className="form-group row">
                        <h4 className="col-12">Mark as</h4>
                          <div className="col-md-2">
                            <label htmlFor="exampleInputCheckbox">
                              Hot List
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input"
                              id="hot_list"
                              name="hot_list"
                              checked={formData.hot_list === "Yes"}
                              onChange={(e) =>
                                handleInputChange(e.target.checked, "hot_list")
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="exampleInputCheckbox">
                              Latest Arrival
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="latest_arrival"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              name="latest_arrival"
                              checked={formData.latest_arrival === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked,
                                  "latest_arrival"
                                )
                              }
                            />
                          </div>

                          <div className="col-md-2">
                            <label htmlFor="exampleInputCheckbox">
                              New Brand Collection
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="new_brand_collection"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              name="new_brand_collection"
                              checked={formData.new_brand_collection === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked,
                                  "new_brand_collection"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="exampleInputCheckbox">
                              Best Sellers
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="best_sellers"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              name="best_sellers"
                              checked={formData.best_sellers === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked,
                                  "best_sellers"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="exampleInputCheckbox">
                              Returnable
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input"
                              id="returnable"
                              name="returnable"
                              checked={formData.returnable === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked,
                                  "returnable"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="exampleInputCheckbox">
                              Cancellable
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input"
                              id="cancellable"
                              name="cancellable"
                              checked={formData.cancellable === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked,
                                  "cancellable"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="exampleInputCheckbox">
                              Featured Products
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input"
                              id="featured_products"
                              name="featured_products"
                              checked={formData.featured_products === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked,
                                  "featured_products"
                                )
                              }
                            />
                          </div>

                          {formData.featured_products === "Yes" && (
                            <div className="col-md-4">
                              <label htmlFor="exampleInputCheckbox">
                                Select Featured Products
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.value,
                                    "featured_products_type"
                                  )
                                }
                              >
                                <option value="">Select Feacture </option>
                                {feactureList.map((genders) => (
                                  <option
                                    key={genders._id}
                                    value={genders.feacture_slug}
                                    selected={
                                      genders.feacture_slug ===
                                      formData.featured_products_type
                                    }
                                  >
                                    {genders.name}
                                  </option>
                                ))}
                              </select>
                              {errors?.featured_products_type ? (
                                <p style={{ color: "red" }}>
                                  {errors?.featured_products_type}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="fullname">
                            Size Chart Image
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <label htmlFor="fullname">Size Chart Image</label>
                          <ImgCrop rotate aspect={1200 / 900}>
                            <Upload
                              className="avatar-uploader"
                              name="size_image"
                              onChange={({ fileList }) =>
                                handleFileChange2(fileList)
                              }
                              listType="picture-card"
                              fileList={null}
                              beforeUpload={() => false}
                              style={{ width: "100%", height: "100%" }}
                            >
                              {formData.size_image ? (
                                <img
                                  src={
                                    typeof formData.size_image === "string"
                                      ? `${imageURL}${formData.size_image}`
                                      : URL.createObjectURL(formData.size_image)
                                  }
                                  alt="subcategory"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div>
                                  <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 33 }}>
              <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
            </svg>
                                  <div className="ant-upload-text">Upload </div>
                                </div>
                              )}
                            </Upload>
                          </ImgCrop>
                        </div>
                        <p style={{ color: "blue" }}>
                        Recommended size: 1200X900(width x height)px
                        </p>
                        {errors?.size_image ? (
                          <p style={{ color: "red" }}>{errors?.size_image}</p>
                        ) : (
                          ""
                        )}

<button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                  </>
                ) : (
                  'Update'
                )}
              </button>
        <Link to="/productListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddProductpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}
export default EditProductData;
