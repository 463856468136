import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { Input, Dropdown, Menu } from "antd";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { BsThreeDots } from "react-icons/bs";
import OrderPopUp from "./OrderPopUp";
import OrderShippingPopUp from "./OrderShippingPopUp";
import OrderTrackPopUp from "./OrderTrackPopUp";
import OrdercancelPopUp from "./OrdercancelPopUp";
import Pagination from "@mui/material/Pagination";
import { getOrderDatafetch,getOrderShippingstatus } from "../Controller/Order";

function ListOrder() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdPass, setItemId] = useState(null);
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenshipping, setPopUpOpenshipping] = useState(false);
  const [popUpOpentrack, setPopUpOpentrack] = useState(false);
  const [itemIdPassShipping, setItemIdShipping] = useState(null);
  const [itemIdPassTrack, setItemIdTrack] = useState(null);
  const [popUpOpenstatus, setPopUpOpenstatus] = useState(false);
  const [itemIdPasscancel, setItemIdcancel] = useState(null);
  const [orderList, setOrderData] = useState([]);
  
  const togglePopUp = (itemId, status) => {
    setPopUpOpen(!popUpOpen);
    setItemId(itemId);
    setItemIdStatus(status);
    getOrderdata();
  };

  const togglePopUpstatus = (itemId) => {
    console.log(itemId);
    setPopUpOpenstatus(!popUpOpenstatus);
    setItemIdcancel(itemId);
    getOrderdata();
  };

  const handleViewShipping = (item) => {
    setPopUpOpenshipping(!popUpOpenshipping);
    setItemIdShipping(item);
    getOrderdata();
  };
  const handleTrackDetail = (shipment_id) => {
    setPopUpOpentrack(!popUpOpentrack);
    setItemIdTrack(shipment_id);
    getOrderdata();
  };
  function formatStatus(status) {
    if (typeof status === 'string' && status.length > 0) {
      return status
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    return status;
  }
  const getOrderdata = async (page, product_name) => {
    const options = {
      page: page,
      product_name: product_name,
    };

    try {
      const result = await getOrderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.orders;
        const totalcount = result.data.response.result.totalCount;
        setOrderData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  const getOrderDataship = async (order_id) => {
    try {
      const response = await axios({
        method: 'post',
        url: 'https://api.sportkingfashion.com/front/v1/order/api/orders/show',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            id: order_id,
            auth: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2ODY5OTUsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzE5MDUxNjYzLCJqdGkiOiJhbWpXQlhDaGJqUlhmWXBHIiwiaWF0IjoxNzE4MTg3NjYzLCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxODE4NzY2MywiY2lkIjo0NDk5OTgyLCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.mx8_CitVQEzP5urFdfDcoRl1FfcFQBROchEH61hIq1E"
        },
    });
      if(response.data){
        let statusToSend = response.data.shipments.status;
        // Check if the status is "Pending"
        if (statusToSend === "PENDING") {
          statusToSend = "Processing";
        }
      const options = {
        shipment_order_id: order_id,
        status: formatStatus(statusToSend)
      };
      try {
        const resultstatus = await getOrderShippingstatus(options, adminInformation.token);
      } catch (error) {
        console.error("Error creating order:", error);
      }
    }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getOrderdata(page, searchValue);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    getOrderdata(currentPage, value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderdata(currentPage, searchValue);
  }, [currentPage, searchValue]);

  useEffect(() => {
    orderList.forEach(order => {
      if (order.shipment_order_id) {
        getOrderDataship(order.shipment_order_id);
      }
    });
  }, [orderList]);

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const handleViewDetail = (item) => {
    navigate('/orderDetail', { state: { selectedItem: item } });
  };

  const dropdownMenu = (itemId, status, shipment_order_id, item, shipment_id) => {
    let menuOptions = (
      <>
        {status !== "Pending" && status.toLowerCase() !== "canceled" && (
          <Menu.Item key="3" onClick={() => togglePopUpstatus(shipment_order_id)}>
            <FaEdit />
            <span className="show_span_edit">Cancel</span>
          </Menu.Item>
        )}
        <Menu.Item key="4" onClick={() => handleViewDetail(item)}>
          <FaEdit />
          <span className="show_span_edit">View Details</span>
        </Menu.Item>
         {status === "Pending" && ( 
        <Menu.Item key="5" onClick={() => handleViewShipping(item)}>
          <FaEdit />
          <span className="show_span_edit">Order Shipping</span>
        </Menu.Item>
         )} 
      </>
    );

    return <Menu>{menuOptions}</Menu>;
  };

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Order List</h1>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6 className="m-0 font-weight-bold">Order Table List</h6>
                    <div className="my_add_event_form_field" style={{ float: "right" }}>
                      <Form.Group controlId="formDate">
                        <div className="my_date_picker" style={{ marginTop: "-30px" }}>
                          <Form.Label>Search</Form.Label>
                          <Search
                            placeholder="input search all text"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchValue}
                            className="input_type_search_common"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Order Id</th>
                            <th scope="col">User Details</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price (Rs)</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Payment Mode</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.length > 0 ? (
                            orderList.map((item, index) => (
                              <tr key={index}>
                                <td>{(currentPage - 1) * 10 + index + 1}</td>
                                <td>{item.order_id}</td>
                                <td style={{ textTransform: 'capitalize' }}>
                                  {item.firstName} {item.lastName}, {item.email}, {item.phone}
                                </td>
                                <td style={{ textTransform: 'capitalize' }}>
                                  {item.product_name.join(", ")}
                                </td>
                                <td>{item.qty}</td>
                                <td>{item.price}</td>
                                <td>{formatDate(item.createdAt)}</td>
                                <td style={{ textTransform: 'capitalize' }}>{item.payment_mode}</td>
                                <td style={{ color: item.payment_status === "Pending" ? "red" : "#19a170" }}>
                                  {item.payment_status}
                                </td>
                                {/* <td style={{ color: item.status === "Canceled" || item.status === "Pending" ? "red" : "green" }}>
                                  {item.status}
                                </td> */}
                               <td
  style={{
    color: item.status === "Canceled" || item.status === "Pending" ? "red" : "green",
    textTransform: "none" // Ensure textTransform doesn't interfere
  }}
>
  {formatStatus(item.status)}
</td>
                                <td>
                                  <Dropdown
                                    overlay={() => dropdownMenu(item._id, item.status, item.shipment_order_id, item, item.shipment_id)}
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="11">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) => handlePageChange(event, page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderPopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdPass={itemIdPass}
        itemIdToStatus={itemIdToStatus}
      />
      <OrderTrackPopUp
        popUpOpentrack={popUpOpentrack}
        handleTrackDetail={handleTrackDetail}
        itemIdPassTrack={itemIdPassTrack}
      />
      <OrderShippingPopUp
        popUpOpenshipping={popUpOpenshipping}
        handleViewShipping={handleViewShipping}
        itemIdPassShipping={itemIdPassShipping}
      />
      <OrdercancelPopUp
        popUpOpenstatus={popUpOpenstatus}
        togglePopUpstatus={togglePopUpstatus}
        itemIdPasscancel={itemIdPasscancel}
      />
    </div>
  );
}

export default ListOrder;
