import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import {
  updateFeactureformData,getFeacturedata
} from "../Controller/Feacture";
import AddGenderpopup from "./EditFeacturepopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Feacture.css";
import { useParams,useNavigate,Link } from "react-router-dom";

function EditFeactureData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  
  const [formData, setFormData] = useState({
    name: "",
  });

  const handleInputChange = (value, field) => {
  if (field === 'name') {
      setFormData((prevVal) => ({
        ...prevVal,
        name: value,
      }));
    } else {
      const name = field;
      const inputValue = value;
    
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: '',
      }));
    }
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.name) {
        setErrors((prevError) => ({
          ...prevError,
          name: 'Feacture is required.',
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateFeactureformData(formData, adminInformation.token);
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
          setErrors((prevError) => ({
            ...prevError,
            name: "Size name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  
  /*************************************************************
          End Function
   *************************************************************/
          const getgenderformdata = async () => {
            try {
              const result = await getFeacturedata({ id: edit }, adminInformation.token);
              if (result.data && result.data.response) {
                const responseData = result.data.response.result;
                // Replace _id with id in the response data
                const modifiedData = { ...responseData, id: responseData._id };
                delete modifiedData._id; // Remove the _id property
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  name: responseData.name, // Change here
                  ...modifiedData,
                }));
     
              } else {
                localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
                console.error("Unexpected response format:", result.data);
              }
            } catch (error) {
              console.error("Error fetching profile data:", error);
            }
          };
          
  useEffect(() => {
    window.scrollTo(0, 0);
    getgenderformdata();
  }, []);
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Feacture </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="fullname"> Feacture<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Enter Feacture"
                          value={formData.name}  // Add this line
                          onChange={(e) => handleInputChange(e.target.value, 'name')}  // Modify this line
                        />
                        {errors.name && (
                          <p style={{ color: "red" }}>{errors.name}</p>
                        )}
                      </div>
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/feactureListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            
            </div>
            <AddGenderpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditFeactureData;
