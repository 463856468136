import React, { useState } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { addBannerformData,getbrandDatafetch} from '../../Controller/Banner';
import AddbannerModel from "./Addbannerpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import "react-dates/initialize";
import "./Banner.css";
import ImgCrop from 'antd-img-crop';
function AddBannerData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
    const [errors, setErrors] = useState('');
    const [subCategories, setSubCategoryData] = useState([]);
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [context, setContext] = useState(""); // State to hold the context (App or Web)
     const [aspectRatio, setAspectRatio] = useState(2500 / 900); // Default aspect ratio for 'web'
     const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      page_name: "",
      title: "",
      description: "",
      show:"",
      button_title: "",
      video_url:"",
      brand_name:"",
      video_url:"",
      button_url: "",
      banner_image_1: null,
      banner_image_2: null,
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
    
    const handleFileChange1 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, banner_image_1: file }));
      }
    };
     const handleFileChange2 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, banner_image_2: file }));
      }
    };
    const handleFileChange3 = (e) => {
      const file = e.target.files[0];
      
      // Check if file exists
      if (file) {
        // Check file size (in bytes)
        const fileSizeInBytes = file.size;
        // Convert bytes to megabytes (MB)
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    
        // Check if file size exceeds 100 MB
        if (fileSizeInMB > 100) {
          alert("File size exceeds 100 MB. Please select a smaller file.");
          // Optionally, you can reset the input field
          e.target.value = null; // Reset the input field to clear the selected file
        } else {
          // Update the state with the file object
          setFormData((prevFormData) => ({ ...prevFormData, video_url: file }));
        }
      }
    };
    
    // const handleFileChange3 = (e) => {
    //   const file = e.target.files[0];
    //   setFormData((prevFormData) => ({ ...prevFormData, video_url: file }));
    // };
  
    const handleInputChange = (e, field) => {
      const { name, value, files } = e.target;
      if (field === 'show') {
        setContext(value); // Update the context based on the selected page name
      }
      if (value === 'app') {
        setAspectRatio(480 / 320); // Aspect ratio for 'app'
      } else if (value === 'web') {
        setAspectRatio(2500 / 900); // Aspect ratio for 'web'
      }
      if (field === 'banner_image') {
        const file = files.length > 0 ? files[0] : null;
    
        if (file) {
          // Update the state with the file object
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: file,
          }));
        } else {
          // Handle the case where no file is selected (optional)
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: null,
          }));
        }
      } else {
        // Handling the case for brand_name separately
        if (field === 'brand_name') {
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: value, // Update the brand_name field with the selected value
          }));
        } else {
          setFormData((prevVal) => ({
            ...prevVal,
            [name]: value,
          }));
        }
    
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
      }
    };
    
    
    const fetchSubCategories = async (page_name) => {
      const options = {
        // Add any additional options needed for fetching sub-categories
        brand: page_name,
      };
    
      try {
        const result = await getbrandDatafetch(options, adminInformation.token);
        console.log(result);
        if (result.data && result.data.response) {
          const responseData = result.data.response.result;
          setSubCategoryData(responseData);
        } else {
          console.error("Unexpected response format:", result.data);
        }
      } catch (error) {
        console.error("Error fetching sub-category data:", error);
      }
    };
    const handleCategorySelect = (page_name) => {
  
      fetchSubCategories(page_name);
    };
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.page_name) {
        setErrors((preError) => ({
          ...preError,
          page_name: 'Page name is required.',
        }));
      } /*else if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: 'Title is required.',
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: 'Detail is required.',
        }));
      }else if (!formData.button_title) {
        setErrors((preError) => ({
          ...preError,
          button_title: 'Button title is required.',
        }));
      } else if (!formData.button_url) {
        setErrors((preError) => ({
          ...preError,
          button_url: 'Button url is required.',
        }));
      }*/else{
        setIsSubmitting(true);
        const updateResult = await addBannerformData(formData, adminInformation.token);
        if(updateResult.status === 200){
            setFormData((prevFormData) => ({
                ...prevFormData,
               page_name: "",
              title: "",
              description: "",
              button_title: "",
              button_url: "",
              banner_image_1: "",
              banner_image_2: "",
            }));
            setIsSubmitting(false);
          togglePopUp();
        }else{
        //   localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
 

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Banner </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                      {/*<div className="col-md-6">
                        <label htmlFor="fullname">Page Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="page_name"
                          name="page_name"
                          placeholder="Enter Social Icon Name"
                          onChange={handleInputChange}
                        />
                         {errors?.page_name?(<p style={{color:"red"}}>{errors?.page_name}</p>):''}
                      </div>*/}
                    <div className="col-md-6">
  <label htmlFor="fullname">Page Name <span style={{ color: "red" }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e, "page_name"); // Pass event and field name
      handleCategorySelect(e.target.value);
    }}
    id="page_name"
    name="page_name"
  >
    <option value="">Select Page Name</option>
    <option value="home">Home Page</option>
    <option value="list">Product List</option>
    <option value="brands">Brand Page</option>
  </select>
  {errors?.page_name ? <p style={{ color: "red" }}>{errors?.page_name}</p> : ''}
</div>


<div className="col-md-6">
  <label htmlFor="exampleInputText">Brand</label>
  <select
  className="form-control"
  id="brand_name"
  name="brand_name"
  onChange={(e) => handleInputChange(e, "brand_name")}
>
  <option value="">Select Brand Name</option>
  {subCategories.map((subCategory) => (
    <option key={subCategory._id} value={subCategory.subcategory_slug}>
      {subCategory.name}
    </option>
  ))}
</select>

 
</div>

   <div className="col-md-6">
                        <label htmlFor="fullname">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          name="title"
                          onChange={handleInputChange}
                          placeholder="Enter Title"
                        />
                         {errors?.title?(<p style={{color:"red"}}>{errors?.title}</p>):''}
                      </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleTextarea">Details</label>
                        <textarea
                          className="form-control"
                          id="description"
                          name="description"
                          onChange={handleInputChange}
                          rows="3"
                        ></textarea>
                           {errors?.description?(<p style={{color:"red"}}>{errors?.description}</p>):''}
                      </div>
                      <div className="form-group row">
                        {formData.page_name !== 'brands' && (
                           <>
                      <div className="col-md-4">
                        <label htmlFor="fullname">Button Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="button_title"
                          name="button_title"
                          onChange={handleInputChange}
                          placeholder="Enter Social Icon Link"
                        />
                         {errors?.button_title?(<p style={{color:"red"}}>{errors?.button_title}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">Button Url</label>
                        <input
                          type="text"
                          className="form-control"
                          id="button_url"
                          name="button_url"
                          onChange={handleInputChange}
                          placeholder="Enter Social Icon Link"
                        />
                         {errors?.button_url?(<p style={{color:"red"}}>{errors?.button_url}</p>):''}
                      </div>
                    
                     
                      <div className="col-md-4">
  <label htmlFor="fullname">Image Show <span style={{ color: "red" }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e, "show"); // Pass event and field name
      handleCategorySelect(e.target.value);
    }}
    id="show"
    name="show"
  >
    <option value="">Select Type</option>
    <option value="app">App</option>
    <option value="web">Web</option>
  </select>
  {errors?.show ? <p style={{ color: "red" }}>{errors?.show}</p> : ''}
</div>
<div className="col-md-6">
                        <label htmlFor="fullname">Banner Image (Left side)</label>
                      {/* <input
  type="file"
  className="form-control"
  id="banner_image_1"
  name="banner_image_1"
  onChange={handleFileChange1}
/> */}
<ImgCrop rotate aspect={aspectRatio}>
  <Upload
    className="avatar-uploader"
    name="banner_image_1"
    onChange={({ fileList }) => handleFileChange1(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.banner_image_1 ? (
      <img src={URL.createObjectURL(formData.banner_image_1)} alt="subcategory" 
      style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>
 {context === 'app' ? (
           <p style={{ color: "green" }}>Recommended size: 480X320 (width x height) for the app</p>
           ) : formData.show === 'web' ? (
             <p style={{ color: "blue" }}>Recommended size: 1301x643 (width x height) for web</p>
           ) : null}
</div>
<div className="col-md-6">
        <label htmlFor="fullname">Banner Image (Right side)</label>
        {/* <input
          type="file"
          className="form-control"
          id="banner_image_2"
          name="banner_image_2"
          onChange={handleFileChange2}
        /> */}
        <ImgCrop rotate aspect={aspectRatio}>
  <Upload
    className="avatar-uploader"
    name="banner_image_2"
    onChange={({ fileList }) => handleFileChange2(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.banner_image_2 ? (
      <img src={URL.createObjectURL(formData.banner_image_2)} alt="subcategory" 
      style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>

        {context === 'app' ? (
          <p style={{ color: "green" }}>Recommended size: 480X320 (width x height) for the app</p>
          ) : formData.show === 'web' ? (
            <p style={{ color: "blue" }}>Recommended size: 1301x643 (width x height) for web</p>
          ) : null}
      </div>
      </>
                        )}
</div>
<div className="form-group row">
{formData.page_name === 'brands' && (
  <>
  <div className="col-md-6">
        <label htmlFor="fullname">Video</label>
        <input
          type="file"
          className="form-control"
          id="video_url"
          name="video_url"
          onChange={handleFileChange3}
        />
        <p style={{ color: "blue" }}>Recommended size minimum 100 Mb</p>
      </div>
      <div className="col-md-6">
      <label htmlFor="fullname">Video Thumbnail</label>
     <ImgCrop rotate aspect={1301 / 703}>
  <Upload
    className="avatar-uploader"
    name="banner_image_1"
    onChange={({ fileList }) => handleFileChange1(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.banner_image_1 ? (
      <img src={URL.createObjectURL(formData.banner_image_1)} alt="subcategory" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>
    </div>
    <div className="col-md-4">
    <label htmlFor="fullname">Image Show <span style={{ color: "red" }}>*</span></label>
    <select
      className="form-control"
      onChange={(e) => {
        handleInputChange(e, "show"); // Pass event and field name
        handleCategorySelect(e.target.value);
      }}
      id="show"
      name="show"
    >
      <option value="">Select Type</option>
      <option value="app">App</option>
      <option value="web">Web</option>
    </select>
    {errors?.show ? <p style={{ color: "red" }}>{errors?.show}</p> : ''}
  </div>
  <div className="col-md-6">
                          <label htmlFor="fullname">Banner Image (Right side)</label>
                          <ImgCrop rotate aspect={aspectRatio}>
  <Upload
    className="avatar-uploader"
    name="banner_image_2"
    onChange={({ fileList }) => handleFileChange2(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.banner_image_2 ? (
      <img src={URL.createObjectURL(formData.banner_image_2)} alt="subcategory" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>   {context === 'app' ? (
             <p style={{ color: "green" }}>Recommended size: 480X320 (width x height) for the app</p>
             ) : formData.show === 'web' ? (
               <p style={{ color: "blue" }}>Recommended size: 1301x643 (width x height) for web</p>
             ) : null}
  </div>
  
        </>
                      )}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span> 
                          Processing...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
        <Link to="/bannerList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddbannerModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBannerData;
