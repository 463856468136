import React, { useEffect } from "react";
import { Dialog, DialogActions, IconButton, Button, Typography } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import "../Contact Us/ContactUs.css";
import { useNavigate } from "react-router-dom";

const Editprofilepopmodel = ({ popUpOpen, togglePopUp }) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    togglePopUp();
  };

  const handleOk = () => {
    togglePopUp();
    navigate("/dashboard"); // Redirect to dashboard
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirmation
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Profile Updated Successfully</p>
        </Typography>
        <DialogActions>
          {/* <Button onClick={handleCancel} color="primary">
            Cancel
          </Button> */}
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Editprofilepopmodel;
