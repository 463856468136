import React, { useState,useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { addHomeformData,getbrandDatafetch,getHomeProductData,getcollectionDatafetch} from '../../Controller/Home';
import { getproductDatafetch,getproductcolorDatafetch} from '../../Controller/Brand';
import { getFeacturedata} from "../../Controller/Product";
import { Link, useNavigate } from "react-router-dom";
import AddbannerModel from "./AddHomepopup";
import ImgCrop from 'antd-img-crop';
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./Home.css";

function AddHomeData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();
    const [subCategories, setSubCategoryData] = useState([]);
    const [productlist, setproductCategoryData] = useState([]);
    const [collectionlist, setCollectionData] = useState([]);
    const [productcolorlist, setproductColorData] = useState([]);
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [context, setContext] = useState(""); // State to hold the context (App or Web)
    const [feactureList, setFeactureData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fetchFeacture = async () => {
      const options = {};
      try {
        const result = await getFeacturedata(options, adminInformation.token);
        if (result.data && result.data.response) {
          const responseData = result.data.response.result;
          setFeactureData(responseData);
        } else {
          console.error("Unexpected response format:", result.data);
        }
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
              page_name: "",
              title: "",
              type_name: "",
              product_id: "",
              brand_name: "",
              description:"",
              button_title:"",
              product_color: "",
              image: "",
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
    //  const handleFileChange1 = (e) => {
    //   const file = e.target.files[0];
    //   setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    // };
  
    const handleFileChange1 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, image: file }));
      }
    };
  
    const handleInputChange = (e, field) => {
      const { name, value, files } = e.target;
    
      if (field === 'image') {
        const file = files.length > 0 ? files[0] : null;
    
        if (file) {
          // Update the state with the file object
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: file,
          }));
        } else {
          // Handle the case where no file is selected (optional)
          setFormData((prevVal) => ({
            ...prevVal,
            [field]: null,
          }));
        }
      } else {
        setFormData((prevVal) => ({
          ...prevVal,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '', // Clear the error message when the field is being updated
        }));
      }
    };
    
    
    
    const fetchSubCategories = async (page_name) => {
      const options = {
        // Add any additional options needed for fetching sub-categories
        brand: page_name,
      };
    
      try {
        const result = await getbrandDatafetch(options, adminInformation.token);
        if (result.data && result.data.response) {
          const responseData = result.data.response.result;
          setSubCategoryData(responseData);
        } else {
          console.error("Unexpected response format:", result.data);
        }
      } catch (error) {
        console.error("Error fetching sub-category data:", error);
      }
    };
    const handleCategorySelect = (page_name) => {
  
      fetchSubCategories(page_name);
    };
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.page_name) {
        setErrors((preError) => ({
          ...preError,
          page_name: 'Page name is required.',
        }));
      }else if (!formData.type_name) {
        setErrors((preError) => ({
          ...preError,
          type_name: 'Type is required.',
        }));
      }else{
        setIsSubmitting(true);
        const updateResult = await addHomeformData(formData, adminInformation.token);
        if(updateResult.status === 200){
            setFormData((prevFormData) => ({
                ...prevFormData,
               page_name: "",
              title: "",
              type_name: "",
              product_id: "",
              brand_name: "",
              product_color: "",
              image: "",
            }));
            setIsSubmitting(false);
          togglePopUp();
        }else{
        //   localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };


  const fetchProductcolor = async (brand_name) => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      id: brand_name,
    };
  
    try {
      const result = await getproductcolorDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.colors;
        setproductColorData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  const handleProductcolorSelect = (brand_name) => {

    fetchProductcolor(brand_name);
  };

  const getbannerdata = async () => {
    const options = {  };
    try {
      const result = await getHomeProductData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setproductCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const getcollectiondata = async () => {
    const options = {  };
    try {
      const result = await getcollectionDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCollectionData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getbannerdata();
    getcollectiondata();
    fetchFeacture();
  }, []);
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
 

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Data </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                      
                    <div className="col-md-6">
  <label htmlFor="fullname">Page Name <span style={{ color: "red" }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e, "page_name"); // Pass event and field name
      handleCategorySelect(e.target.value);
    }}
    id="page_name"
    name="page_name"
  >
    <option value="">Select Page Name</option>
    <option value="home">Home Page</option>
  </select>
  {errors?.page_name ? <p style={{ color: "red" }}>{errors?.page_name}</p> : ''}
</div>


{/*<div className="col-md-6">
  <label htmlFor="exampleInputText">Brand</label>
  <select
  className="form-control"
  id="brand_name"
  name="brand_name"
  onChange={(e) => handleInputChange(e, "brand_name")}
>
  <option value="">Select Brand Name</option>
  {subCategories.map((subCategory) => (
    <option key={subCategory._id} value={subCategory.subcategory_slug}>
      {subCategory.name}
    </option>
  ))}
</select>

 
</div>*/}
<div className="col-md-6">
  <label htmlFor="fullname">Type <span style={{ color: "red" }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e, "type_name"); 
    }}
    id="type_name"
    name="type_name"
  >
    <option value="">Select Type</option>
    <option value="hot_list">Hot List</option>
    <option value="latest_arrival">Latest Arrival</option>
    <option value="featured_product">Featured Product</option>
    <option value="new_brand_collection">New Brand Collection</option>
    <option value="bestsellers">Bestsellers</option>
     {collectionlist.map((product) => (
      <option key={product._id} value={`${product.name}|${product._id}`}>
        {product.name}
      </option>
     ))}
  </select>
  {errors?.type_name ? <p style={{ color: "red" }}>{errors?.type_name}</p> : ''}
</div>
{formData.type_name === "featured_product" && (
   <div className="col-md-6">
     <label htmlFor="fullname">Title</label>
    <select
                                  className="form-control"
                                  id="title"
                                  name="title"
                                  onChange={handleInputChange}
                                >
    <option value="">Select Feacture </option>
                            {feactureList.map((genders) => (
                              <option key={genders._id} value={genders.name}>
                                {genders.name}
                              </option>
                            ))}
                            </select>
  </div>
)}

{formData.type_name != "new_brand_collection"   && formData.type_name != "bestsellers" && formData.type_name != "featured_product"  && (
 <>
 <div className="col-md-6">
                        <label htmlFor="fullname">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          name="title"
                          onChange={handleInputChange}
                          placeholder="Enter Title"
                        />
                         {errors?.title?(<p style={{color:"red"}}>{errors?.title}</p>):''}
                      </div>
                      <div className="col-md-6">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        onChange={handleInputChange}
                        rows="3"
                      ></textarea>
                      {errors?.description && <p style={{ color: "red" }}>{errors?.description}</p>}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="button_title">Button Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="button_title"
                        name="button_title"
                        onChange={handleInputChange}
                        placeholder="Enter Button Title"
                      />
                      {errors?.button_title && <p style={{ color: "red" }}>{errors?.button_title}</p>}
                    </div>
                    </>
                         )}
                      </div>
                      <div className="form-group row">
                      {formData.type_name === "bestsellers" && (
                          <div className="form-group row">
                         <div className="col-md-6">
                            <label htmlFor="fullname">Product</label>
                            <select
                                className="form-control"
                              
                                onChange={(e) => {
                                  handleInputChange(e, "product_id"); // Pass event and field name
                                  handleProductcolorSelect(e.target.value);
                                }}
                                id="product_id"
                                name="product_id"
                              >
                                <option value="">Select Product</option>
                                {productlist.map((product) => (
                                  <option key={product._id} value={product._id}>
                                    {product.product_name}
                                  </option>
                                ))}
                              </select>
 
                            {errors.product_id && <p style={{ color: "red" }}>{errors.product_id}</p>}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="fullname">Product Color</label>
                            <select
  className="form-control"
  onChange={(e) => {
    handleInputChange(e, "product_color"); // Pass event and field name
  }}
  id="product_color"
  name="product_color"
>
  <option value="">Select Color</option>
  {productcolorlist.map((color, index) => ( // Map over productColorData
    <option key={index} value={color}>
      {color}
    </option>
  ))}
</select>

 
                           
                            {errors.product_color && <p style={{ color: "red" }}>{errors.product_color}</p>}
                          </div>
                          </div>
                      )}
{formData.type_name !== "bestsellers" && (
<div className="col-md-6">
                        <label htmlFor="fullname">Image</label>
                      {/* <input
  type="file"
  className="form-control"
  id="image"
  name="image"
  onChange={handleFileChange1}
/> */}
<ImgCrop rotate aspect={1299/864}>
<Upload
  className="avatar-uploader"
  name="image"
  onChange={({ fileList }) => handleFileChange1(fileList)}
  listType="picture-card"
  fileList={null}
  beforeUpload={() => false}
  style={{ width: '100%', height: '100%' }}
>
  {formData.image ? (
    <img src={URL.createObjectURL(formData.image)} alt="subcategory" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
  ) : (
    <div>
      <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
      </svg>
      <div className="ant-upload-text">Upload</div>
    </div>
  )}
</Upload>
</ImgCrop>
<p style={{ color: "blue" }}>Recommended size: 636x850(width x height)px</p>

</div>
  )}
</div>
<button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span> 
                          Processing...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
        <Link to="/homeListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddbannerModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddHomeData;
