import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./Component/DashBoardMain/DashBaord";
import Login from "./Component/LoginPage/Login";
import ConatctUsTable from "./Component/Contact Us/ConatctUsTable";
import Forgotpage from "./Component/ForgotPage/Forgotpage";
import ContatcUsAddressTable from "./Component/Contact Us/ContatcUsAddressTable";
import ContatcUsEnquiryTable from "./Component/Contact Us/ContactUsEnquiry";
import HomePage from "./Component/Home Page/HomePage";
import CreateCategories from "./Component/Blog Page/CreateCategories";

import EDITPROFILE from "./Component/LoginPage/EditProfile";

// Routes
import SOCIALICON from "./Component/GeneralData/SocialIcon/SocialIcon";
import SOCIALICONCREATEFORM from "./Component/GeneralData/SocialIcon/SocialIconFormCreate";
import SOCIALICONEDITFORM from "./Component/GeneralData/SocialIcon/SocialiconEdit";
import NewsletterList from "./Component/GeneralData/Newsletter/newsletterList";
import GETINTOUCH from "./Component/GeneralData/GetInTouch/GetInTouch";
import GETINTOUCHEDITFORM from "./Component/GeneralData/GetInTouch/GetInTouchEdit";
import CREATEGETINTOUCH from "./Component/GeneralData/GetInTouch/GetInTouchFormCreate";

import BANNER from "./Component/Cms/Banner/ListBannerData";
import CREATEBANNER from "./Component/Cms/Banner/AddBannerData";
import EDITBANNERDATA from "./Component/Cms/Banner/EditBannerData";

import HEADINGListPage from "./Component/Cms/Heading/ListHeadingData";
import HEADINGCREATEPage from "./Component/Cms/Heading/AddHeadingData";
import HEADINGEditPage from "./Component/Cms/Heading/EditHeadingData";

import CATEGORY from "./Component/Category/ListCategoryData";
import CREATECATEGORY from "./Component/Category/AddCategoryData";
import EDITCATEGORY from "./Component/Category/EditCategoryData";
import SUBCATEGORY from "./Component/SubCategory/ListSubCategoryData";
import CREATESUBCATEGORY from "./Component/SubCategory/AddSubCategoryData";
import EDITSUBCATEGORY from "./Component/SubCategory/EditSubCategoryData";
import SIZE from "./Component/Size/ListSizeData";
import CREATESIZE from "./Component/Size/AddSizeData";
import EDITSIZE from "./Component/Size/EditSizeData";
import GENDER from "./Component/Gender/ListGenderData";
import CREATEGENDER from "./Component/Gender/AddGenderData";
import EDITGENDER from "./Component/Gender/EditGenderData";
import COLOUR from "./Component/Color/ListColorData";
import CREATECOLOUR from "./Component/Color/AddColorData";
import EDITCOLOUR from "./Component/Color/EditColorData";
import COLLECTION from "./Component/Collection/ListCategoryData";
import CREATECOLLECTION from "./Component/Collection/AddCollectionData";
import EDITCOLLECTION from "./Component/Collection/EditCollectionData";
import UserListPage from "./Component/User/ListUser";
import ORDERDETAIL from "./Component/Order/orderDetail";



import BlogCREATEPage from "./Component/Blog Page/BlogCreate";
import BLogListPage from "./Component/Blog Page/ListBlog";
import BlogEditPage from "./Component/Blog Page/EditBlogData";
import BlogCategoryCREATEPage from "./Component/Blog Page/Blog_category/BlogcategoryCreate";
import BLogCategoryListPage from "./Component/Blog Page/Blog_category/ListBlogcategory";
import BlogCategoryEditPage from "./Component/Blog Page/Blog_category/EditBlogcategoryData";
import EmailCREATEPage from "./Component/Email/AddEmailData";
import EmailListPage from "./Component/Email/ListEmailData";
import EmailEditPage from "./Component/Email/EditEmailData";
import PRODUCTCREATEPage from "./Component/Product/ProductCreate";
import PRODUCTListPage from "./Component/Product/ListProduct";
import PRODUCTEditPage from "./Component/Product/EditProductData";
import TESTIMONIALCREATEPage from "./Component/Testmonial/TestimonialCreate";
import TESTIMONIALListPage from "./Component/Testmonial/ListTestimonial";
import TESTIMONIALEditPage from "./Component/Testmonial/EditTestimonalData";
import REFUNDLISTPAGE from "./Component/Refund_Shipping/ListRefund";
import REFUNDCREATEPage from "./Component/Refund_Shipping/RefundCreate";
import REFUNDEditPage from "./Component/Refund_Shipping/EditRefundData";
import PRODUCTBULKDATA from "./Component/Product/ProductBulkCreate";
import ORDERLIST from "./Component/Order/ListOrder";
import ORDERSHIPPINGLIST from "./Component/Order/ListShippingOrder";
import BULKORDERLIST from "./Component/Order/BulkOrder";
import COUPENCREATEPage from "./Component/Coupen/AddCoupenData";
import COUPENListPage from "./Component/Coupen/ListCoupenData";
import COUPENEditPage from "./Component/Coupen/EditCoupenData";
import CONTACTGET from "./Component/ContactGetInTouch/ListGetInTouch";
import CONTACTWITH from "./Component/ContactGetInTouch/ContactWithUs";
import HOMECREATEPage from "./Component/Cms/Home/AddHomeData";
import HOMEListPage from "./Component/Cms/Home/ListHomeData";
import HOMEEditPage from "./Component/Cms/Home/EditHomeData";
import BRANDCREATEPage from "./Component/Cms/Brand/AddBrandData";
import BRANDListPage from "./Component/Cms/Brand/ListBrandData";
import BRANDEditPage from "./Component/Cms/Brand/EditBrandData";
import TRICKERCREATEPage from "./Component/Tricker/AddTrickerData";
import TRICKERListPage from "./Component/Tricker/ListTrickerData";
import TRICKEREditPage from "./Component/Tricker/EditTrickerData";
import FeactureCREATEPage from "./Component/Feacture/AddFeactureData";
import FeactureListPage from "./Component/Feacture/ListFeactureData";
import FeactureEditPage from "./Component/Feacture/EditFeactureData";



import DISCOUNTCREATEPage from "./Component/Discount/AddDiscountData";
import DISCOUNTListPage from "./Component/Discount/ListDiscountData";
import DISCOUNTEditPage from "./Component/Discount/EditDiscountData";


const AuthenticatedRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("ADMIN-INFO");
  const navigate = useNavigate();
 
  if(isAuthenticated == ''){
    navigate("/login");
  }else{
    return isAuthenticated ? element : <Navigate to="/login" />;
  }
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem("ADMIN-INFO") !== "";
  });

  const navigate = useNavigate();

  useEffect(() => {
    const adminInfo = localStorage.getItem("ADMIN-INFO");
    const currentPath = window.location.pathname;

    if (adminInfo !== "") {
      setIsAuthenticated(true);
    } else if (currentPath !== "/login" && currentPath !== "/forgot-password") {
      setIsAuthenticated(false);
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          {/*}  <Route path="/dashboard" element={<Dashboard />} />*/}
          <Route path="/dashboard" element={[<AuthenticatedRoute/>,<Dashboard/>]} />
          <Route path="/login" element={[<AuthenticatedRoute/>, <Login />]} />
          <Route path="/contactUsTable" element={[<AuthenticatedRoute/>, <ConatctUsTable/>]} />
          {/*main route*/}
          {/*General Data */}
          <Route path="/socialIconList" element={[<AuthenticatedRoute/>, <SOCIALICON/>]} />
          <Route path="/socialIconCreateForm" element={ [<AuthenticatedRoute/>, <SOCIALICONCREATEFORM/>]} />
          <Route path="/socialiconEdit/:edit" element={[<AuthenticatedRoute/>, <SOCIALICONEDITFORM />]} />
          <Route path="/newsletterList" element={[<AuthenticatedRoute/>, <NewsletterList />]} />
          <Route path="/getInTouchList" element={[<AuthenticatedRoute/>, <GETINTOUCH />]} />
          <Route path="/createGetInTouch" element={[<AuthenticatedRoute/>, <CREATEGETINTOUCH />]} />
          <Route path="/GetInTouchEdit/:edit" element={<GETINTOUCHEDITFORM />} />
          <Route path="/forgot-password" element={[<AuthenticatedRoute/>, <Forgotpage />]} />
          {/*Cms Data */}
          <Route path="/bannerList" element={[<AuthenticatedRoute/>, <BANNER />]} />
          <Route path="/createBanner" element={[<AuthenticatedRoute/>,<CREATEBANNER />]} />
          <Route path="/editBannerData/:edit" element={[<AuthenticatedRoute/>, <EDITBANNERDATA/>]} />
          {/*Cms Data */}
          <Route path="/categoryList" element={[<AuthenticatedRoute/>, <CATEGORY />]} />
          <Route path="/createCategory" element={[<AuthenticatedRoute/>, <CREATECATEGORY />]} />
          <Route path="/editCategoryData/:edit" element={[<AuthenticatedRoute/>,<EDITCATEGORY />]} />
          <Route path="/subcategoryList" element={[<AuthenticatedRoute/>,<SUBCATEGORY />]} />
          <Route path="/createSubCategory" element={[<AuthenticatedRoute/>,<CREATESUBCATEGORY />]} />
          <Route path="/editSubCategoryData/:edit" element={[<AuthenticatedRoute/>,<EDITSUBCATEGORY />]} />
          <Route path="/sizeList" element={[<AuthenticatedRoute/>,<SIZE />]} />
          <Route path="/createSize" element={[<AuthenticatedRoute/>,<CREATESIZE />]} />
          <Route path="/editSizeData/:edit" element={[<AuthenticatedRoute/>,<EDITSIZE />]} />
          <Route path="/genderList" element={[<AuthenticatedRoute/>,<GENDER />]} />
          <Route path="/createGender" element={[<AuthenticatedRoute/>,<CREATEGENDER />]} />
          <Route path="/editGenderData/:edit" element={[<AuthenticatedRoute/>,<EDITGENDER />]} />
          <Route path="/colourList" element={[<AuthenticatedRoute/>,<COLOUR />]} />
          <Route path="/createColour" element={[<AuthenticatedRoute/>,<CREATECOLOUR />]} />
          <Route path="/editColourData/:edit" element={[<AuthenticatedRoute/>,<EDITCOLOUR />]} />
          <Route path="/collectionList" element={[<AuthenticatedRoute/>,<COLLECTION />]} />
          <Route path="/createCollection" element={[<AuthenticatedRoute/>,<CREATECOLLECTION />]} />
          <Route path="/editCollectionData/:edit" element={[<AuthenticatedRoute/>,<EDITCOLLECTION />]} />

          <Route path="/bLogListPage" element={[<AuthenticatedRoute/>,<BLogListPage />]} />
          <Route path="/createblogdata" element={[<AuthenticatedRoute/>,<BlogCREATEPage />]} />
          <Route path="/editBlogData/:edit" element={[<AuthenticatedRoute/>,<BlogEditPage />]} />
          <Route path="/bLogCategoryListPage" element={[<AuthenticatedRoute/>,<BLogCategoryListPage />]} />
          <Route path="/createBlogCategorydata" element={[<AuthenticatedRoute/>,<BlogCategoryCREATEPage />]} />
          <Route path="/editBlogCategoryData/:edit" element={[<AuthenticatedRoute/>,<BlogCategoryEditPage />]} />
          <Route path="/emailListPage" element={[<AuthenticatedRoute/>,<EmailListPage />]} />
          <Route path="/createEmaildata" element={[<AuthenticatedRoute/>,<EmailCREATEPage />]} />
          <Route path="/editEmailData/:edit" element={[<AuthenticatedRoute/>,<EmailEditPage />]} />

          <Route path="/userList" element={[<AuthenticatedRoute/>,<UserListPage />]} />
          <Route path="/productListPage" element={[<AuthenticatedRoute/>,<PRODUCTListPage />]} />
          <Route path="/productBulkData" element={[<AuthenticatedRoute/>,<PRODUCTBULKDATA />]} />
          <Route path="/createProductData" element={[<AuthenticatedRoute/>,<PRODUCTCREATEPage />]} />
          <Route path="/editProductData/:edit" element={[<AuthenticatedRoute/>,<PRODUCTEditPage />]} />
          <Route path="/orderDetail" element={[<AuthenticatedRoute/>,<ORDERDETAIL />]} />
          <Route path="/homeListPage" element={[<AuthenticatedRoute/>,<HOMEListPage />]} />
          <Route path="/createHomeData" element={[<AuthenticatedRoute/>,<HOMECREATEPage />]} />
          <Route path="/editHomeData/:edit" element={[<AuthenticatedRoute/>,<HOMEEditPage />]} />
          <Route path="/discountListPage" element={[<AuthenticatedRoute/>,<DISCOUNTListPage />]} />
          <Route path="/createDiscountData" element={[<AuthenticatedRoute/>,<DISCOUNTCREATEPage />]} />
          <Route path="/editDiscountData/:edit" element={[<AuthenticatedRoute/>,<DISCOUNTEditPage />]} />

          <Route path="/brandListPage" element={[<AuthenticatedRoute/>,<BRANDListPage />]} />
          <Route path="/createBrandData" element={[<AuthenticatedRoute/>,<BRANDCREATEPage />]} />
          <Route path="/editBrandData/:edit" element={[<AuthenticatedRoute/>,<BRANDEditPage />]} />
          <Route path="/headingListPage" element={[<AuthenticatedRoute/>,<HEADINGListPage />]} />
          <Route path="/createHeadingData" element={[<AuthenticatedRoute/>,<HEADINGCREATEPage />]} />
          <Route path="/editHeadingData/:edit" element={[<AuthenticatedRoute/>,<HEADINGEditPage />]} />
          <Route path="/trickerListPage" element={[<AuthenticatedRoute/>,<TRICKERListPage />]} />
          <Route path="/createTrickerData" element={[<AuthenticatedRoute/>,<TRICKERCREATEPage />]} />
          <Route path="/editTrickerData/:edit" element={[<AuthenticatedRoute/>,<TRICKEREditPage />]} />
         
          <Route path="/feactureListPage" element={[<AuthenticatedRoute/>,<FeactureListPage />]} />
          <Route path="/createFeactureData" element={[<AuthenticatedRoute/>,<FeactureCREATEPage />]} />
          <Route path="/editFeactureData/:edit" element={[<AuthenticatedRoute/>,<FeactureEditPage />]} />
         
          
          <Route path="/testimonialListPage" element={[<AuthenticatedRoute/>,<TESTIMONIALListPage />]} />
          <Route path="/createTestimonialData" element={[<AuthenticatedRoute/>,<TESTIMONIALCREATEPage />]} />
          <Route path="/editTestimonialData/:edit" element={[<AuthenticatedRoute/>,<TESTIMONIALEditPage />]} />
          <Route path="/orderList" element={[<AuthenticatedRoute/>,<ORDERLIST />]} />
          <Route path="/orderShippingList" element={[<AuthenticatedRoute/>,<ORDERSHIPPINGLIST />]} />
          <Route path="/bulkOrderList" element={[<AuthenticatedRoute/>,<BULKORDERLIST />]} />
          <Route path="/coupenListPage" element={[<AuthenticatedRoute/>,<COUPENListPage />]} />
          <Route path="/createCoupenData" element={[<AuthenticatedRoute/>,<COUPENCREATEPage />]} />
          <Route path="/editCoupenData/:edit" element={[<AuthenticatedRoute/>,<COUPENEditPage />]} />
          <Route path="/refundListPage" element={[<AuthenticatedRoute/>,<REFUNDLISTPAGE />]} />
          <Route path="/createRefundData" element={[<AuthenticatedRoute/>,<REFUNDCREATEPage />]} />
          <Route path="/editRefundData/:edit" element={[<AuthenticatedRoute/>,<REFUNDEditPage />]} />

          <Route path="/contactGetList" element={[<AuthenticatedRoute/>,<CONTACTGET />]} />
          <Route path="/contactWithList" element={[<AuthenticatedRoute/>,<CONTACTWITH />]} />
          {/*main route*/}
          <Route path="/contactUsAddresstable" element={[<AuthenticatedRoute/>,<ContatcUsAddressTable />]} />
          <Route path="/contactUsEnquiryTable" element={[<AuthenticatedRoute/>,<ContatcUsEnquiryTable />]} />
          <Route path="/homePage" element={[<AuthenticatedRoute/>,<HomePage />]} />
          <Route path="/createCategories" element={[<AuthenticatedRoute/>,<CreateCategories />]} />
          <Route path="/editProfile" element={[<AuthenticatedRoute/>,<EDITPROFILE />]} />
        </Routes>
      </div>
    </>
  );
};
export default App;
