import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import GenderDelete from "./GenderDelete";
import Changestatus from "./ChangeGenderpopup";
import { getGenderDatafetch, getCategoryDataExcel } from "../Controller/Gender";
import "./Gender.css";
import { BsThreeDots } from "react-icons/bs";
import Excel from "exceljs";

function ListGenderData() {
  // Delete Popup
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [popUpOpenchange, setPopUpOpenchange] = useState(false);
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);

  const [genderList, setGenderData] = useState([]);
  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getsizedata();
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getsizedata(currentPage, value);
  };

  const handlePageChange = (event, page) => {
    console.log("Page changed to", page);
    setCurrentPage(page);
    getsizedata(page);
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getsizedata = async (page, name) => {
    const options = {
      page: page,
      name: name, // Include additional search options
    };
    try {
      const result = await getGenderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.gender;
        const totalcount = result.data.response.result.totalcount;
        setGenderData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleRowClick = (itemId) => {
    navigate(`/editGenderData/${itemId}`);
  };
  const togglePopUpchange = (itemId, status) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    // Toggle status based on the current status
    setstatuschange(status === "A" ? "I" : "A");
    getsizedata();
  };

  const downloadExcel = async () => {
    try {
      const result = await getCategoryDataExcel({}, adminInformation.token);
      if (result.status === true || result.status === 200) {
        const categories = result.data.response.result; // Extract categories from result.data
        if (categories && categories.length > 0) {
          const workbook = new Excel.Workbook();
          const worksheet = workbook.addWorksheet("genders");

          // Define column headers
          worksheet.columns = [
            { header: "ID", key: "_id", width: 15 },
            { header: "Name", key: "name", width: 30 },
            { header: "Gender Slug", key: "gender_slug", width: 30 },
            { header: "Status", key: "status", width: 30 },
            // Add more columns as needed
          ];

          // Add data rows
          categories.forEach((category, index) => {
            worksheet.addRow({
              _id: category._id, // Use _id consistently here
              name: category.name,
              gender_slug: category.gender_slug,
              status: category.status,
              // Add more properties as needed
            });
          });

          // Write workbook to blob
          const buffer = await workbook.xlsx.writeBuffer();

          // Create a blob from buffer
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = URL.createObjectURL(blob);
          anchor.download = "genders.xlsx";

          // Programmatically trigger a click event on the anchor element
          anchor.click();
        } else {
          console.error("Error downloading Excel data: No data available");
        }
      } else {
        console.error("Error downloading Excel data:", result.message);
      }
    } catch (error) {
      console.error("Error downloading Excel data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getsizedata(currentPage, searchValue);
  }, [currentPage, searchValue]);

  const dropdownMenu = (itemId, status) => (
    <Menu>
      <Menu.Item key="2" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUpchange(itemId, status)}>
        <FaEdit />
        <span className="show_span_edit">
          {status === "A" ? "Inactive" : "Active"}
        </span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Gender List</h1>
                <p className="mb-4"></p>
                {/* <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search text"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div> */}
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6 className="m-0 font-weight-bold">Gender List Table </h6>
                    <div className="my_add_event_form_field subcatgory_select_option">
                      <Form.Group controlId="formDate">
                        <div className="my_date_picker">
                          <Form.Label>Search</Form.Label>
                          <Search
                            placeholder="input search gender"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchValue}
                            className="input_type_search_common
                            "
                          />
                        </div>
                      </Form.Group>
                      <div>
                        <Link to="/createGender">
                          <button type="button" className="btn btn-primary">
                            Add Gender Data
                          </button>
                        </Link>
                        <button
                          onClick={downloadExcel}
                          className="btn btn-success ml-2"
                        >
                          Download Excel
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheckTitle"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheckTitle"
                                >
                                  S No
                                </label>
                              </div> */}
                              S.No
                            </th>
                            <th scope="col">Gender</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {genderList.length > 0 ? (
                            genderList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customCheckTitle${item.id}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheckTitle${item.id}`}
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </label>
                                  </div> */}
                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>{item.name}</td>
                                <td
                                  style={{
                                    color:
                                      item.status === "A" ? "green" : "red",
                                  }}
                                >
                                  {item.status === "A" ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={() =>
                                      dropdownMenu(item._id, item.status)
                                    }
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    {/* <FaRegCommentDots /> */}
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GenderDelete
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      ></GenderDelete>

      <Changestatus
        popUpOpenchange={popUpOpenchange}
        togglePopUpchange={togglePopUpchange}
        itemIdToChange={itemIdToChange}
        status={itemChange} // Assuming status is available in your data
      />
    </div>
  );
}
export default ListGenderData;
