import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import {
  updateDiscountformData,
  getDiscountData,
} from "../Controller/Discount";
import AddCoupenpopup from "./AddDiscountpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Discount.css";
import { useParams,useNavigate } from "react-router-dom";

function EditDiscountData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    discount_type: "",
    discount: "",
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const handleInputChange = (e, field) => {
    const { name, value } = e.target;
    setFormData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
    setErrors((prevError) => ({
      ...prevError,
      [name]: "",
    }));
  };
  

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.discount) {
        setErrors((preError) => ({
          ...preError,
          discount: "Discount is required.",
        }));
      } else if (!formData.discount_type) {
        setErrors((preError) => ({
          ...preError,
          discount_type: "Discount Type is required.",
        }));
      }else {
        const updateResult = await updateDiscountformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            discount_type: "",
            discount: "",
          }));
          togglePopUp();
        } else {
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
          setErrors((preError) => ({
            ...preError,
            category_name:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
   * Function Name  : getCoupenData
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getCoupenfetchData = async () => {
    const options = { id: edit };
    try {
      const result = await getDiscountData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        // Replace _id with id in the response data
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id; // Remove the _id property
        setFormData(modifiedData);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCoupenfetchData();
  }, []);
  /*************************************************************
          End Function
   *************************************************************/
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Search } = Input;
  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Discount </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="exampleInputText">Type</label>
                        <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e, "discount_type")}
                            value={formData.discount_type}
                          >
                            <option value="">Select Type</option>
                            <option value="first_time_user">First Time User</option>
                          </select>

                        {errors?.discount_type ? <p style={{ color: "red" }}>{errors?.discount_type}</p> : ""}
                      </div>
                     
                        <div className="col-md-12">
                          <label htmlFor="discount">Discount (Percentage)</label>
                          <input
                            type="number"
                            className="form-control"
                            id="discount"
                            name="discount"
                            value={formData.discount}
                            placeholder="Enter Discount"
                            onChange={handleInputChange}
                          />
                          {errors?.discount ? (
                            <p style={{ color: "red" }}>{errors?.discount}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                       
                      >
                        Update
                      </button>
                    </form>
                  </div>
                </div>
              </div>

             
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDiscountData;
