import React, { useState,useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
// import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Upload } from "antd";
import { Link,useNavigate } from "react-router-dom";
import { updateBlogformData,getblogdata,getAllcategoryDatafetch} from  "../../Component/Controller/Blog";
import { useParams} from "react-router-dom";
import ImgCrop from 'antd-img-crop';
import {imageURL} from '../Config/constant';
import AddblogModel from "./Editblogpopup";
function EditBlogData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [categories, setCategoryData] = useState([]);
  const { edit } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState('');
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen); 
  };
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    blog_image: null,
    
  });

  
  const handleFileChange1 = (fileList) => {
    // Ensure fileList is not empty
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, blog_image: file }));
    }
  };
  const handleInputChange = (value, field) => {
    if (field === 'description') {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === 'title') {
      setFormData((prevVal) => ({
        ...prevVal,
        title: value,
      }));
    } else {
      const name = field;
      const inputValue = value;
    
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: '',
      }));
    }
  };
  
  
  const [fileInputs, setFileInputs] = useState({
    blog_image: null,
  });
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: 'Title is required.',
        }));
      }else if (!formData.blog_category) {
        setErrors((preError) => ({
          ...preError,
          blog_category: 'Blog category is required.',
        }));
      }else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: 'Detail is required.',
        }));
      }else if (!formData.blog_image) {
        setErrors((preError) => ({
          ...preError,
          blog_image: 'Image is required.',
        }));
      }else{
        setIsSubmitting(true);
        const updateResult = await updateBlogformData(formData, adminInformation.token);
        if(updateResult.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
              title: "",
              description: "",
              blog_image: "",
            }));
            setIsSubmitting(false);
            togglePopUp();
        }else{
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
   /*************************************************************
          End Function
   *************************************************************/
  /*************************************************************
 * Function Name  : getblogdata
 * Purposs        : This function is used get data
 * Created Date   : 09-01-2024
 *************************************************************/
 const getblogfetchdata = async () => {
  const options = { id: edit };
  try {
    const result = await getblogdata(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      // Replace _id with id in the response data
      const modifiedData = { ...responseData, id: responseData._id };
      delete modifiedData._id; // Remove the _id property
      setFormData(modifiedData);
      setFileInputs({
        blog_image: responseData.blog_image || null,
      });
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};
const fetchCategories = async () => {
  const options = {};
  try {
    const result = await getAllcategoryDatafetch(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      setCategoryData(responseData);
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching banner data:", error);
  }
};
          useEffect(() => {
            window.scrollTo(0, 0);
            getblogfetchdata();
            fetchCategories();
          }, []);
  // const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Add Blog Content</h5>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form onSubmit={handleSubmit} encType="multipart/form-data">
                          <div className="form-group">
                            <label htmlFor="exampleInputText">Title<span style={{ color: 'red' }}>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              name="title" value={formData.title}
                              onChange={(e) => handleInputChange(e.target.value, 'title')}
                            />
                              {errors?.title?(<p style={{color:"red"}}>{errors?.title}</p>):''}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputText">Blog Category<span style={{ color: 'red' }}>*</span></label>
                            <select
                                className="form-control"
                                onChange={(e) => {
                                  handleInputChange(e.target.value, "blog_category");
                                }}
                              >
                                <option value="">Select Category</option>
                                {categories.map((category) => (
                                  <option key={category._id} value={category._id}  selected={category._id === formData.blog_category}>
                                    {category.blog_category_name}
                                  </option>
                                ))}
                              </select>
                              {errors?.blog_category?(<p style={{color:"red"}}>{errors?.blog_category}</p>):''}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="description"
                              name="description" value={formData.description}
                              onChange={(value) => handleInputChange(value, 'description')}
                            />
                            {errors?.description?(<p style={{color:"red"}}>{errors?.description}</p>):''}
                          </div>
                          <div className="form-group">
                            <label htmlFor="fullname">Image<span style={{ color: 'red' }}>*</span></label>
                    <ImgCrop rotate aspect={636/850}>
<Upload
  className="avatar-uploader"
  name="blog_image"
  onChange={({ fileList }) => handleFileChange1(fileList)}
  listType="picture-card"
  fileList={null}
  beforeUpload={() => false}
  style={{ width: '100%', height: '100%' }}
>
{formData.blog_image ? (
  <img
  src={typeof formData.blog_image === 'string' ? `${imageURL}${formData.blog_image}` : URL.createObjectURL(formData.blog_image)}
  alt="subcategory"
  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
/>
) : (
  <div>
    <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
    </svg>
    <div className="ant-upload-text">Upload</div>
  </div>
)}

</Upload>
</ImgCrop>
{errors?.blog_image?(<p style={{color:"red"}}>{errors?.blog_image}</p>):''}
<p style={{ color: "blue" }}>Recommended size: 636x850(width x height)px</p>

                            </div>
                            <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span> 
                          Processing...
                          </>
                        ) : (
                          "Update"
                        )}
                      </button>
                           <Link to="/bLogListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddblogModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
        </div>
      </div>
    </div>
  );
}
export default EditBlogData;
