import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { updatecolorformData, getcolordata } from "../Controller/Color";
import AddColorModel from "./EditColorpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Color.css";
import { SketchPicker } from "react-color";
import colorName from "color-name"; // Import color-name library
import ntc from 'ntcjs'; // Import ntcjs library
import { useParams, useNavigate, Link } from "react-router-dom";

function EditColorData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    color_code: "",
    name: "",
  });

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  const calculateDistance = (color1, color2) => {
    const rDiff = color1.r - color2[0];
    const gDiff = color1.g - color2[1];
    const bDiff = color1.b - color2[2];
    return Math.sqrt(rDiff * rDiff + gDiff * gDiff + bDiff * bDiff);
  };

  const handleInputChange = (value, field) => {
    if (field === "name") {
      setFormData((prevVal) => ({
        ...prevVal,
        name: value,
      }));
      updateColorCode(value);
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    }
    setErrors((prevError) => ({
      ...prevError,
      [field]: "", // Clear error when input changes
    }));
  };

  const getClosestColorName = (hex) => {
    let closestName = "";
    let closestDistance = Infinity;
    const colorEntries = Object.entries(colorName);
    const hexRgb = hexToRgb(hex);

    for (const [name, value] of colorEntries) {
      const distance = calculateDistance(hexRgb, value);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestName = name;
      }
    }

    return closestName;
  };

  const updateColorCodeByName = (name) => {
    const colorEntries = Object.entries(colorName);
    const matchedColor = colorEntries.find(([colorName]) => colorName.toLowerCase() === name.toLowerCase());

    if (matchedColor) {
      const [colorName, rgb] = matchedColor;
      const colorCode = `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)}`;
      setFormData((prevVal) => ({
        ...prevVal,
        color_code: colorCode,
      }));
    }
  };

  const handleColorChange = (color) => {
    const n_match = ntc.name(color.hex);
    const closestColorName = n_match[1];
    setFormData({
      ...formData,
      color_code: color.hex,
      name: closestColorName,
    });
  };

  const updateColorCode = (name) => {
    const hexCode = getColorCodeByName(name);
    setFormData((prevVal) => ({
      ...prevVal,
      color_code: hexCode || "",
    }));
  };

  const getColorCodeByName = (name) => {
    const n_match = ntc.name(name);
    const allColors = ntc.names;
    const color = allColors.find(color => color[1].toLowerCase() === name.toLowerCase());
    return color ? color[0] : "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.name) {
        setErrors((prevError) => ({
          ...prevError,
          name: "Colour Name is required.",
        }));
      } else if (!formData.color_code) {
        setErrors((prevError) => ({
          ...prevError,
          color_code: "Colour Code is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updatecolorformData(formData, adminInformation.token);
        if (updateResult.status === true) {
          setIsSubmitting(false);
          togglePopUp();
          setFormData((prevFormData) => ({
            ...prevFormData,
            color_code: "",
            name: "",
          }));
        } else {
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("ADMIN-INFO");
          navigate("/login");
          setErrors((prevError) => ({
            ...prevError,
            name: "Size name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const getcolorformdata = async () => {
    try {
      const result = await getcolordata({ id: edit }, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id;
        setFormData((prevFormData) => ({
          ...prevFormData,
          name: responseData.name,
          color_code: responseData.color_code,
          ...modifiedData,
        }));
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getcolorformdata();
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Colour</h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group">
                        <label>Choose Color:</label>
                        <SketchPicker color={formData.color_code} onChange={handleColorChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">
                          Colour Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Enter Colour Name"
                          value={formData.name}
                          onChange={(e) => handleInputChange(e.target.value, "name")}
                        />
                        {errors?.name && !formData.name && <p style={{ color: "red" }}>{errors?.name}</p>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="color_code">
                          Colour Code<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="color_code"
                          name="color_code"
                          placeholder="Enter Colour Code"
                          value={formData.color_code}
                          readOnly
                        />
                        {errors?.color_code && !formData.color_code && <p style={{ color: "red" }}>{errors?.color_code}</p>}
                      </div>
                     

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span> 
                          Processing...
                          </>
                        ) : (
                          "Update"
                        )}
                      </button>
                      <Link to="/colourList">
                        <button type="button" className="btn btn-primary" style={{ marginLeft: "12px" }}>
                          <span>Cancel</span>
                        </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddColorModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditColorData;
