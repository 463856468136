import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import {
  updatesizeformData,getsizedata
} from "../Controller/Size";
import AddSizeModel from "./EditSizepopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Size.css";
import { getGenderDatafetch } from "../Controller/Product";
import { useParams ,useNavigate,Link} from "react-router-dom";

function EditSizeData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const navigate = useNavigate();
  const [gender, setGenderData] = useState([]);
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  
  const [formData, setFormData] = useState({
    gender_id: "",
    name: "",
    header_show:"",
  });

  const handleInputChange = (value, field) => {
    if (field === 'gender_id') {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === 'name') {
      setFormData((prevVal) => ({
        ...prevVal,
        name: value,
      }));
    }else if (field === "header_show") {
      setFormData((prevVal) => ({
          ...prevVal,
          [field]: value === false ? "No" : "Yes", 
        }));
      } else {
      const name = field;
      const inputValue = value;
    
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: '',
      }));
    }
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.name) {
        setErrors((prevError) => ({
          ...prevError,
          name: 'Size Name is required.',
        }));
      }else if (!formData.gender_id) {
        setErrors((prevError) => ({
          ...prevError,
          gender_id: "Gender is required.",
        }));
      }else {
        setIsSubmitting(true);
        const updateResult = await updatesizeformData(formData, adminInformation.token);
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            quantity: "",
            name: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
          setErrors((prevError) => ({
            ...prevError,
            name: "Size name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  
  const fetchGender = async () => {
    const options = {};
    try {
      const result = await getGenderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setGenderData(responseData);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
          const getsizeformdata = async () => {
            try {
              const result = await getsizedata({ id: edit }, adminInformation.token);
              if (result.data && result.data.response) {
                const responseData = result.data.response.result[0];
                // Replace _id with id in the response data
                const modifiedData = { ...responseData, id: responseData._id };
                delete modifiedData._id; // Remove the _id property
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  name: responseData.name, // Change here
                  quantity: responseData.quantity,
                  ...modifiedData,
                }));
     
              } else {
                localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
                console.error("Unexpected response format:", result.data);
              }
            } catch (error) {
              console.error("Error fetching profile data:", error);
            }
          };
          
  useEffect(() => {
    window.scrollTo(0, 0);
    getsizeformdata();
    fetchGender();
  }, []);
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Size </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="fullname"> Name<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Enter Size Name"
                          value={formData.name}  // Add this line
                          onChange={(e) => handleInputChange(e.target.value, 'name')}  // Modify this line
                        />
                         {errors?.name && !formData.name && (
  <p style={{ color: "red" }}>
    {errors?.name}
  </p>
)}

                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Gender<span style={{ color: 'red' }}>*</span></label>
                        <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e.target.value, "gender_id")}
                          >
                            <option value="">Select Gender</option>
                            {gender.map((genders) => (
                              <option key={genders._id} value={genders._id}  selected={genders._id === formData.gender_id}>
                                {genders.name}
                              </option>
                            ))}
                          </select>
                          {errors?.gender_id && !formData.gender_id && (
  <p style={{ color: "red" }}>
    {errors?.gender_id}
  </p>
)}
                      </div>
                      <div className="col-md-6">
                          <label htmlFor="exampleInputCheckbox">Header (Active/Inactive)</label>
                          <input
                              type="checkbox"
                              style={{ marginLeft: "14px", borderColor: "#df1616" }}
                              className="form-check-input"
                              id="header_show"
                              name="header_show"
                              checked={formData.header_show === "Yes"}
                              onChange={(e) => handleInputChange(e.target.checked, "header_show")}
                            />

                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/sizeList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            
            </div>
            <AddSizeModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSizeData;
