import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import ImgCrop from 'antd-img-crop';



import { Form } from "react-bootstrap";
import {
  addSubCategoryformData,
  getCategoryDatafetch,
} from "../Controller/Subcategory";
import AddCategoryModel from "./AddSubCategorypopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./SubCategory.css";
import ReactQuill from "react-quill";
import { useParams ,useNavigate,Link} from "react-router-dom";

function AddSubCategoryData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [categories, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  
  const [formData, setFormData] = useState({
    category_id: "",
    subcategory_name: "",
    subcategory_description: "",
    subategory_image: null, // Initialize category_image with null
  });

  const handleInputChange = (value, field) => {
    if (field === 'subcategory_description') {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === 'subcategory_name') {
      setFormData((prevVal) => ({
        ...prevVal,
        subcategory_name: value,
      }));
    }else if (field === 'category_id') {
      setFormData((prevVal) => ({
        ...prevVal,
        category_id: value,
      }));
    } else {
      const name = field;
      const inputValue = value;
    
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: '',
      }));
    }
  };
  

  
  

  // const handleFileChange1 = (e) => {
  //   const file = e.target.files[0];
  //   setFormData((prevFormData) => ({ ...prevFormData, subcategory_image: file }));
  // };
  const handleFileChange1 = (fileList) => {
    // Ensure fileList is not empty
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, subcategory_image: file }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.subcategory_name) {
        setErrors((prevError) => ({
          ...prevError,
          subcategory_name: "SubCategory name is required.",
        }));
      }else if (!formData.category_id) {
        setErrors((prevError) => ({
          ...prevError,
          category_id: "Category name is required.",
        }));
      // } else if (!formData.subcategory_description) {
      //   setErrors((prevError) => ({
      //     ...prevError,
      //     subcategory_description: 'Detail is required.',
      //   }));
      // } 
      }else {
        setIsSubmitting(true);
        const updateResult = await addSubCategoryformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            category_id: "",
            subcategory_name: "",
            subcategory_description: "",
            subcategory_image: null, // Clear the uploaded file
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
        //   localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
          setErrors((prevError) => ({
            ...prevError,
            subcategory_name:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
          const fetchCategories = async () => {
            const options = {};
            try {
              const result = await getCategoryDatafetch(options, adminInformation.token);
              if (result.data && result.data.response) {
                const responseData = result.data.response.result;
                setCategoryData(responseData);
              } else {
                localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
                console.error("Unexpected response format:", result.data);
              }
            } catch (error) {
              console.error("Error fetching banner data:", error);
            }
          };
          const handleSelectClick = () => {
            fetchCategories();
          };
  
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Dragger } = Upload;
  
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Sub Category </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="fullname">Sub Category Name <span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="subcategory_name"
                          name="subcategory_name"
                          placeholder="Enter SubCategory Name"
                          value={formData.subcategory_name}  // Add this line
                          onChange={(e) => handleInputChange(e.target.value, 'subcategory_name')}  // Modify this line
                        />
                        {errors?.subcategory_name ? (
                          <p style={{ color: "red" }}>
                            {errors?.subcategory_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Category Name <span style={{ color: 'red' }}>*</span></label>
                        <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e.target.value, "category_id")}
                            onClick={handleSelectClick}
                          >
                            <option value="">Select Category</option>
                            {categories.map((category) => (
                              <option key={category._id} value={category._id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        {errors?.category_id ? (
                          <p style={{ color: "red" }}>
                            {errors?.category_id}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                       <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description
                            </label>
                            <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="subcategory_description"
                            name="Subcategory_description"
                            onChange={(value) => handleInputChange(value, 'subcategory_description')}
                          />


                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="fullname">Image</label>
                                <input type="file" className="form-control"  id="subcategory_image" name="subcategory_image"
                                  onChange={handleFileChange1}
                            />
                            </div> */}
{/* <div className="form-group">
  <label htmlFor="subcategory_image">Image</label>
  <ImgCrop rotate>
    <Upload
      className="avatar-uploader"
      name="subcategory_image"
      onChange={({ fileList }) => handleFileChange1(fileList)}
      listType="picture-card"
      fileList={null}
      beforeUpload={() => false}
    >
      {formData.subcategory_image ? (
        <img src={URL.createObjectURL(formData.subcategory_image)} alt="subcategory" style={{ width: '100%' }} />
      ) : (
        <div>
          <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
          </svg>
          <div className="ant-upload-text">Upload</div>
        </div>
      )}
    </Upload>
  </ImgCrop>
</div> */}
<ImgCrop rotate aspect={1299 / 659.74}>
  <Upload
    className="avatar-uploader"
    name="subcategory_image"
    onChange={({ fileList }) => handleFileChange1(fileList)}
    listType="picture-card"
    fileList={null}
    beforeUpload={() => false}
    style={{ width: '100%', height: '100%' }}
  >
    {formData.subcategory_image ? (
      <img src={URL.createObjectURL(formData.subcategory_image)} alt="subcategory" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    ) : (
      <div>
        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
        </svg>
        <div className="ant-upload-text">Upload</div>
      </div>
    )}
  </Upload>
</ImgCrop>
<p style={{ color: "blue" }}>Recommended size: 1299x659.74(width x height)px</p>

<button type="submit" className="btn btn-primary" disabled={isSubmitting}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button>
        <Link to="/subcategoryList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            
            </div>
            <AddCategoryModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubCategoryData;
