import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation ,Link} from "react-router-dom";
import { getOrderDetailDatafetch } from "../Controller/Order";
import { useParams ,useNavigate} from "react-router-dom";
import {imageURL} from '../Config/constant';

function OrderDetail() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const location = useLocation();
  const selectedItem = location.state.selectedItem;
  const navigate = useNavigate();

  const { edit } = useParams();
  const [responseData, setResponseData] = useState(null); // Define responseData state
  
  /*************************************************************
   * Function Name  : getblogdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getblogfetchdata = async () => {
    const options = { order_id: edit };
    try {
      const result = await getOrderDetailDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        console.log(responseData);
        setResponseData(responseData); // Set responseData state
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getblogfetchdata();
  }, []);
  
  const { Dragger } = Upload;
  
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Product Detail</h5>
                  </div>
                 
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <div
                      className="my_add_event_form_field"
                      style={{ float: "right" }}
                    >
                      <Link to="/orderList">
                        <button type="button" className="btn btn-primary">
                          Back
                        </button>
                      </Link>
                    </div>
                    <div className="responsive">
                    {responseData && (
  <div>
    <div className="form-group">
      <label htmlFor="exampleInputText">User Detail :- </label>
      <h3>Name :- {selectedItem.firstName} {selectedItem.lastName}</h3>
      <p>Email :- {selectedItem.email}</p>
      <p>Phone: {selectedItem.phone}</p>
      <p>Shipping Address: {selectedItem.address1} {selectedItem.address2} {selectedItem.city} {selectedItem.state} , {selectedItem.pincode} {selectedItem.country} </p>
    </div>
    <div className="form-group">
  <label htmlFor="exampleInputText">Order Details:</label>
  <table className="table table-bordered">
    <thead>
      <tr>
        <th  scope="col">Product Image</th>
        <th  scope="col">Product Name</th>
        <th  scope="col">Product MRP Price</th>
        <th  scope="col">Price</th>
        <th  scope="col">Color</th>
        <th scope="col">Size</th>
        <th  scope="col">Quantity</th>
        <th  scope="col">Payment Mode</th>
        <th  scope="col">Payment Status</th>
        <th  scope="col">Coupon Code</th>
        <th  scope="col">Discount</th>
        <th  scope="col">Order Date</th>
      </tr>
    </thead>
    <tbody>
  {selectedItem.orderdetails.map((orderDetail, index) => {
    // Check if color_size_details exists and has length greater than 0
    if (orderDetail.product_id.color_size_details && orderDetail.product_id.color_size_details.length > 0) {
      // Find the color_size_details object where color_name matches the orderDetail color
      const colorSizeDetail = orderDetail.product_id.color_size_details.find(
        (colorSize) => colorSize.color_name === orderDetail.color
      );

      return (
        <tr key={index}>
          <td>
            <img
              src={`${imageURL}${colorSizeDetail.images[0]}`}
              alt={orderDetail.product_name}
              style={{ width: '100px', height: '100px' }}
            />
          </td>
          <td>{orderDetail.product_name}</td>
          <td>{colorSizeDetail ? colorSizeDetail.mrp_price : 'N/A'}</td>
          <td>{orderDetail.price}</td>
          <td>{orderDetail.color}</td>
          <td>{orderDetail.size}</td>
          <td>{orderDetail.qty}</td>
          <td>{orderDetail.payment_mode}</td>
          <td>{orderDetail.payment_status}</td>
          <td>{orderDetail.coupon}</td>
          <td>{orderDetail.discount}</td>
          <td>{formatDate(orderDetail.createdAt)}</td>
        </tr>
      );
    } else {
      // Handle case where color_size_details is not defined or empty
      return (
        <tr key={index}>
          <td colSpan="11">Color details not available</td>
        </tr>
      );
    }
  })}
</tbody>


    {/* <tbody>
      {selectedItem.orderdetails.map((orderDetail, index) => (
        <tr key={index}>
          <td>
            <img src={`${imageURL}${orderDetail.product_image}`} alt={orderDetail.product_name} style={{width: '100px', height: '100px'}} />
        </td>
          <td>{orderDetail.product_name}</td>
          <td>{orderDetail.price}</td>
          <td>{orderDetail.color}</td>
          <td>{orderDetail.size}</td>
          <td>{orderDetail.qty}</td>
          <td>{orderDetail.payment_mode}</td>
          <td>{orderDetail.payment_status}</td>
          <td>{orderDetail.coupon}</td>
          <td>{orderDetail.discount}</td>
          <td>{formatDate(orderDetail.createdAt)}</td>
        </tr>
      ))}
    </tbody> */}
  </table>
</div>



  </div>
)}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;
