import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { IoMdClose } from "react-icons/io";
import "../Contact Us/ContactUs.css";
import OrderPopUp from "./OrderPopUp";
import { getOrderShipping, getOrderShippingcreate,shippingOrderData } from "../Controller/Order";

const OrderShippingPopUp = ({
  popUpOpenshipping,
  handleViewShipping,
  itemIdPassShipping,
}) => {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [height, setHeight] = useState("");
  const [breadth, setBreadth] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [comment, setComment] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);

  const handleCancel = () => {
    handleViewShipping();
    setHeight("");
    setBreadth("");
    setWeight("");
    setLength("");
    setComment("");
  };

  // const handleDelete = async () => {
  //   const options = {
  //     order_id: itemIdPassShipping?.order_id,
  //     order_detail_id: itemIdPassShipping?._id,
  //     user_id: itemIdPassShipping?.user_id,
  //     channel_id: "4977155",
  //     order_date: itemIdPassShipping?.created_date,
  //     height,
  //     breadth,
  //     weight,
  //     length,
  //   };
  //   try {
  //     const result = await getOrderShipping(options, adminInformation.token);
  //     if (result) {
  //       console.log(itemIdPassShipping);
  //       const orderItems = itemIdPassShipping?.orderdetails.map((product) => ({
  //         name: product.product_name,
  //         sku: product.product_id.sku,
  //         units: product.qty,
  //         selling_price: product.unitPrice,
  //         hsn: product.product_id.hsn,
  //       }));
  //       const optionsorder = {
  //         order_id: itemIdPassShipping?.order_id,
  //         order_date: itemIdPassShipping?.created_date,
  //         pickup_location: "Primary",
  //         channel_id: "4977155",
  //         billing_customer_name: itemIdPassShipping?.firstName,
  //         billing_last_name: itemIdPassShipping?.lastName,
  //         billing_address: itemIdPassShipping?.address1,
  //         billing_city: itemIdPassShipping?.city,
  //         billing_pincode: itemIdPassShipping?.pincode,
  //         billing_state: itemIdPassShipping?.state,
  //         billing_country: itemIdPassShipping?.country,
  //         billing_email: itemIdPassShipping?.email,
  //         billing_phone: itemIdPassShipping?.phone,
  //         shipping_is_billing: true,
  //         order_items: orderItems,
  //         payment_method: itemIdPassShipping?.payment_mode,
  //         shipping_charges: 0,
  //         giftwrap_charges: 0,
  //         transaction_charges: 0,
  //         total_discount: 0,
  //         sub_total: itemIdPassShipping?.price,
  //         height,
  //         breadth,
  //         weight,
  //         length,
  //         auth:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2ODY5OTUsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzE3Mzk0NDE4LCJqdGkiOiJyenB6bXdzVE11dVJYRDVFIiwiaWF0IjoxNzE2NTMwNDE4LCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxNjUzMDQxOCwiY2lkIjo0NDk5OTgyLCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.Y5yH_jo4KYhXuF4TavzS13vEWaWEQ1LFCAr71vhcoaI"
  //       };
  //       const result1 = await shippingOrderData(optionsorder);
  //       console.log(result1);
  //         if (result1) {
  //           const options22 = {
  //             shipment_order_id: result1.order_id,
  //             shipment_id: result1.shipment_id,
  //             order_id: itemIdPassShipping?.order_id,
  //             order_detail_id: itemIdPassShipping?._id,
  //           };
  //           try {
  //             const resultorder = await getOrderShippingcreate(
  //               options22,
  //               adminInformation.token
  //             );
  //             if (resultorder) {
  //               setPopUpOpen(true); // Open OrderPopUp on successful order creation
  //               handleViewShipping();
  //               setHeight("");
  //               setBreadth("");
  //               setWeight("");
  //               setLength("");
  //               setComment("");
  //             }
  //           } catch (error) {
  //             console.error("Error creating order:", error);
  //           }
  //         } else {
  //           console.error("Error creating order:", result1);
  //         }
  //       // } catch (error) {
  //       //   console.error("Error creating order:", error);
  //       // }
  //     } else {
  //       console.error("Unexpected response format:", result);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting social icon data:", error);
  //   }
  // };
  
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const handleDelete = async () => {
     const formattedDate = formatDate(new Date(itemIdPassShipping?.created_date));
    const options = {
      order_id: itemIdPassShipping?.order_id,
      order_detail_id: itemIdPassShipping?._id,
      user_id: itemIdPassShipping?.user_id,
      channel_id: "4977155",
      order_date: formattedDate,
      height,
      breadth,
      weight,
      length,
    };
  
    try {
      const result = await getOrderShipping(options, adminInformation.token);
      if (result) {
        
        const orderItems = itemIdPassShipping?.orderdetails.map((product) => {
          if (product.product_id) {
            return {
              name: product.product_name,
              sku: product.product_id.sku,
              units: product.qty,
              selling_price: product.unitPrice,
              hsn: product.product_id.hsn,
            };
          } else {
            console.error("Product ID is missing for product:", product);
            return null;
          }
        }).filter(item => item !== null);
  
        const optionsorder = {
          order_id: itemIdPassShipping?.order_id,
          order_date: formattedDate,
          pickup_location: "Primary", 
          channel_id: "4977155",
          billing_customer_name: itemIdPassShipping?.firstName,
          billing_last_name: itemIdPassShipping?.lastName,
          billing_address: itemIdPassShipping?.address1,
          billing_city: itemIdPassShipping?.city,
          billing_pincode: itemIdPassShipping?.pincode,
          billing_state: itemIdPassShipping?.state,
          billing_country: itemIdPassShipping?.country,
          billing_email: itemIdPassShipping?.email,
          billing_phone: itemIdPassShipping?.phone,
          shipping_is_billing: true,
          order_items: orderItems,
          payment_method: itemIdPassShipping?.payment_mode,
          shipping_charges: 0,
          giftwrap_charges: 0,
          transaction_charges: 0,
          total_discount: 0,
          sub_total: itemIdPassShipping?.price,
          height,
          breadth,
          weight,
          length,
          auth: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2NTE0NDEsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzIwMDk2NTM0LCJqdGkiOiJBdDJVM0l5ZU56SlZuZHlNIiwiaWF0IjoxNzE5MjMyNTM0LCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxOTIzMjUzNCwiY2lkIjo0NDk5OTgyLCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.YUs0hDUUYhFxIKZGwpE90nd-wjyCeNdrdK99YJgzsdE"
        };
  
        const result1 = await shippingOrderData(optionsorder);
        if (result1) {
          const options22 = {
            shipment_order_id: result1.data.result.order_id,
            shipment_id: result1.data.result.shipment_id,
            order_id: itemIdPassShipping?.order_id,
            order_detail_id: itemIdPassShipping?._id,
          };
          try {
            const resultorder = await getOrderShippingcreate(
              options22,
              adminInformation.token
            );
            if (resultorder) {
              setPopUpOpen(true); // Open OrderPopUp on successful order creation
              handleViewShipping();
              setHeight("");
              setBreadth("");
              setWeight("");
              setLength("");
              setComment("");
            }
          } catch (error) {
            console.error("Error creating order:", error);
          }
        } else {
          console.error("Error creating order:", result1);
        }
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error deleting social icon data:", error);
    }
  };
  
  return (
    <>
      <Dialog
        open={popUpOpenshipping}
        onClose={handleViewShipping}
        maxWidth="md"
        PaperProps={{
          className: "myDialogPopUp",
        }}
      >
        <div className="myDrawer">
          <div className="myMainDrawerClass">
            <div>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                className="myFilterClass"
              >
                Shipping Detail
              </Typography>
            </div>
            <div>
              <IconButton onClick={handleViewShipping}>
                <IoMdClose />
              </IconButton>
            </div>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            {/* <p>Do you really want to accept the order?</p> */}
          </Typography>
          {/* Input fields for height, breadth, weight, length, and comment */}
          <div className="order_shipping_popup_main_section_for_height_">
            <TextField
              label="Height(cm)"
              variant="outlined"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Breadth(cm)"
              variant="outlined"
              value={breadth}
              onChange={(e) => setBreadth(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Weight(kg)"
              variant="outlined"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Length(cm)"
              variant="outlined"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              fullWidth
              margin="normal"
            />
            {/* <TextField
            label="Comment"
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            margin="normal"
          /> */}
          </div>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="primary">
              Ok
            </Button>
          </DialogActions>
        </div>
      </Dialog>

        <OrderPopUp
          popUpOpen={popUpOpen}
          togglePopUp={() => setPopUpOpen(false)}
        />
    </>
  );
};

export default OrderShippingPopUp;
