import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import SubCategoryDelete from "./SubCategoryDelete";
import SubCategoryChange from "./SubCategorychange";
import {
  getSubCategoryDatafetch,
  getCategoryDatafetch,
  getCategoryDataExcel,
} from "../Controller/Subcategory";
import "./SubCategory.css";
import { BsThreeDots } from "react-icons/bs";
import Excel from "exceljs";

function ListCategoryData() {
  const { Option } = Select;
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [categories, setCategoryData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [popUpOpenchange, setPopUpOpenchange] = useState(false);
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);
  const [subcategoryList, setSubCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null); // Added state for selected category

  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getsubcategorydata();
  };

  const togglePopUpchange = (itemId, status) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    setstatuschange(status === "A" ? "I" : "A");
    // Reset filters and re-fetch data without any filters applied
    setSearchValue("");
    setSelectedCategory(null);
    getsubcategorydata(currentPage, "", null);
  };

  const handleSearch = (value) => {
    setSearchValue(value); // Store the search value
    getsubcategorydata(currentPage, value, selectedCategory?._id);
  };

  const handleCategoryChange = (categoryId) => {
    const selectedCategory = categories.find(
      (category) => category._id === categoryId
    );
    setSelectedCategory(selectedCategory);
    // Update subcategory data based on the selected category and current search value
    getsubcategorydata(currentPage, searchValue, categoryId);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getsubcategorydata(page, searchValue, selectedCategory);
  };

  const getsubcategorydata = async (page, name, category) => {
    const options = {
      page: page,
      name: name,
      category_id: category, // Use the category ID
    };
    try {
      console.log(options);
      const result = await getSubCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.subcategory;
        const totalcount = result.data.response.result.totalcount;
        setSubCategoryData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleRowClick = (itemId) => {
    navigate(`/editSubCategoryData/${itemId}`);
  };

  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getsubcategorydata(currentPage, searchValue, selectedCategory?._id);
    fetchCategories();
  }, [currentPage, searchValue, selectedCategory]);

  const dropdownMenu = (itemId, status) => (
    <Menu>
      <Menu.Item key="2" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUpchange(itemId, status)}>
        <FaEdit />
        <span className="show_span_edit">
          {status === "A" ? "Inactive" : "Active"}
        </span>
      </Menu.Item>
      {/* <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item> */}
    </Menu>
  );

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Sub Category List</h1>
                <p className="mb-4"></p>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6 className="m-0 font-weight-bold">
                      Sub Category List Table
                    </h6>
                    <div
                      className="my_add_event_form_field subcatgory_select_option"
                      style={{ float: "right" }}
                    >
                      <div className="sub_category_and_input_field">
                        <div className="select_common_data_for_all">
                          <Select
                            placeholder="Select Category"
                            style={{ width: 200 }}
                            onChange={handleCategoryChange}
                            value={
                              selectedCategory
                                ? selectedCategory._id
                                : undefined
                            } // Handle undefined value
                          >
                            <Select.Option value="">
                              Select Category
                            </Select.Option>{" "}
                            {/* Added value attribute */}
                            {categories.map((category) => (
                              <Select.Option
                                key={category._id}
                                value={category._id}
                              >
                                {category.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>

                        <Form.Group controlId="formDate">
                          <div
                            className="my_date_picker"
                            style={{ marginTop: "-30px" }}
                          >
                            <Form.Label>Search</Form.Label>
                            <Search
                              placeholder="input search sub category"
                              className="input_type_search_common"
                              onChange={(e) => handleSearch(e.target.value)} // Pass the input value to handleSearch
                            />
                          </div>
                        </Form.Group>
                      </div>

                      <Link to="/createSubCategory">
                        <button type="button" className="btn btn-primary">
                          Add Sub Category Data
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th scope="col">Category Name</th>
                            <th scope="col">Sub Category Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subcategoryList.length > 0 ? (
                            subcategoryList.map((item, index) => (
                              <tr key={index}>
                                <td>{(currentPage - 1) * 10 + index + 1}</td>
                                <td>
                                  {categories.find(
                                    (category) =>
                                      category._id === item.category_id
                                  )?.name || ""}
                                </td>
                                <td>{item.name}</td>
                                <td
                                  style={{
                                    color:
                                      item.status === "A" ? "green" : "red",
                                  }}
                                >
                                  {item.status === "A" ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={() =>
                                      dropdownMenu(item._id, item.status)
                                    }
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <p>
                        Show {(currentPage - 1) * 10 + 1} to{" "}
                        {Math.min(currentPage * 10, totalItems)} of {totalItems}
                      </p>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubCategoryDelete
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      ></SubCategoryDelete>
      <SubCategoryChange
        popUpOpenchange={popUpOpenchange}
        togglePopUpchange={togglePopUpchange}
        itemIdToChange={itemIdToChange}
        status={itemChange}
      />
    </div>
  );
}
export default ListCategoryData;
