import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import SocialicondataDelete from "./SocialicondataDelete";
import {
  addSocialIconData,
  getSocialDatafetch,
} from "../../Controller/SocialIcon";
import AddSocialIconModel from "./AddSocialIconModel";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./SocialiconData.css";
import { BsThreeDots } from "react-icons/bs";

function SocialIconFormCreate() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
    setFormData((prevFormData) => ({
            ...prevFormData,
            icon_name: "",
            icon_link: "",
          }));
    getsocialdata();
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    icon_name: "",
    icon_link: "",
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((preVal) => ({
      ...preVal,
      [name]: value,
    }));
    setErrors((preError) => ({
      ...preError,
      [name]: "",
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.icon_name) {
        setErrors((preError) => ({
          ...preError,
          icon_name: "Icon name is required.",
        }));
      } else if (!formData.icon_link) {
        setErrors((preError) => ({
          ...preError,
          icon_link: "Icon link is required.",
        }));
      } else {
        const updateResult = await addSocialIconData(
          formData,
          adminInformation.token
        );

        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            icon_name: "",
            icon_link: "",
          }));
          togglePopUp();
        }else{
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [popUpOpend, setPopUpOpenD] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [socialList, setSocialData] = useState([]);
  const togglePopUpd = (itemId) => {
    setPopUpOpenD(!popUpOpend);
    setItemIdToDelete(itemId);
    getsocialdata();
  };
  const handleSearch = (value) => {
    console.log("Search Value:", value);
    setSearchValue(value);
    getsocialdata(currentPage, value);
  };

  const handlePageChange = (event, page) => {
    console.log("Page changed to", page);
    setCurrentPage(page);
    getsocialdata(page);
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getsocialdata = async (page, icon_name) => {
    const options = {
      page: page,
      icon_name: icon_name, // Include additional search options
    };
    try {
      const result = await getSocialDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.socialIcon;
        const totalcount = result.data.response.result.totalcount;
        setSocialData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleRowClick = (itemId) => {
    // history.push(`/createEventEditPage/${itemId}`);
    navigate(`/socialiconEdit/${itemId}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getsocialdata(currentPage, searchValue);
  }, [currentPage, searchValue]);

  const dropdownMenu = (itemId) => (
    <Menu>
      <Menu.Item key="2" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUpd(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );

  const { Search } = Input;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Social Icon </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="fullname">Social Icon Name<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="icon_name"
                          name="icon_name"
                          placeholder="Enter Social Icon Name"
                          onChange={handleInputChange}
                        />
                        {errors?.icon_name ? (
                          <p style={{ color: "red" }}>{errors?.icon_name}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Social Icon Link<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="icon_link"
                          name="icon_link"
                          onChange={handleInputChange}
                          placeholder="Enter Social Icon Link"
                        />
                        {errors?.icon_link ? (
                          <p style={{ color: "red" }}>{errors?.icon_link}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                       
                      >
                        Submit
                      </button>
                      <Link to="/socialIconList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
             
            </div>
            <AddSocialIconModel
              popUpOpen={popUpOpen}
              togglePopUp={togglePopUp}
            />
            <SocialicondataDelete
              popUpOpend={popUpOpend}
              togglePopUpd={togglePopUpd}
              itemIdToDelete={itemIdToDelete}
            ></SocialicondataDelete>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialIconFormCreate;
