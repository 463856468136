import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Link, useNavigate } from "react-router-dom";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { addCategoryformData } from "../Controller/Collection";
import { getGenderDatafetch } from "../Controller/Product";
import { getbrandDatafetch } from "../Controller/Brand";
import AddCategoryModel from "./AddCollectionpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Collection.css";
import ImgCrop from "antd-img-crop";
function AddCollectionData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [subCategories, setSubCategoryData] = useState([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [gender, setGenderData] = useState([]);
  const [formData, setFormData] = useState({
    collection_name: "",
    collection_image: "",
    title:"",
    variants: [
      {
        gender_id: "",
        name: "",
        slug: "",
      },
    ],
    page_show: [
      {
        subcategory_id: "",
        subcategory_name: "",
        subcategory_slug: "",
      },
    ],
  });

  const handleInputChange = (value, field) => {
    setFormData((prevVal) => ({
      ...prevVal,
      [field]: value,
    }));
    setErrors((prevError) => ({
      ...prevError,
      [field]: "",
    }));
  };

  const handleFileChange1 = (fileList) => {
    // Ensure fileList is not empty
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({
        ...prevFormData,
        collection_image: file,
      }));
    }
  };

  const fetchGender = async () => {
    const options = {};
    try {
      const result = await getGenderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setGenderData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const handleVariantChange = (value, index) => {
    const [gender_id, name, slug] = value.split("-");
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      updatedVariants[index] = { gender_id, name, slug };
      return { ...prevFormData, variants: updatedVariants };
    });
  };

  const handleVariantChangePage = (value, index) => {
    let [subcategory_name, subcategory_id, subcategory_slug] = value.split("-");

    // Check if "Home" is selected
    if (subcategory_name === "home") {
      subcategory_id = null; // Set subcategory_id to null
      subcategory_slug = "home"; // Set subcategory_slug to "home-store"
    }

    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.page_show];
      updatedVariants[index] = {
        subcategory_name,
        subcategory_id,
        subcategory_slug,
      };
      return { ...prevFormData, page_show: updatedVariants };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.collection_name) {
        setErrors((prevError) => ({
          ...prevError,
          collection_name: "Collection name is required.",
        }));
      }else if (!formData.title) {
        setErrors((prevError) => ({
          ...prevError,
          title: "Title is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await addCategoryformData(
          {
            ...formData,
            variants: formData.variants.map((variant) => ({
              gender_id: variant.gender_id,
              name: variant.name,
              slug: variant.slug,
            })),
            page_show: formData.page_show.map((variant) => ({
              subcategory_id: variant.subcategory_id,
              subcategory_name: variant.subcategory_name,
              subcategory_slug: variant.subcategory_slug,
            })),
          },
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData({
            collection_name: "",
            title:"",
            variants: [],
            page_show: [],
          });
           setIsSubmitting(false);
          togglePopUp();
        } else {
          setErrors((prevError) => ({
            ...prevError,
            collection_name:
              "Collection name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      variants: [...prevVal.variants, { gender_id: "", name: "", slug: "" }],
    }));
  };

  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.variants];
      updatedVariants.splice(index, 1);
      return { ...prevVal, variants: updatedVariants };
    });
  };

  const addVariantPage = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      page_show: [
        ...prevVal.page_show,
        { subcategory_id: "", subcategory_name: "", subcategory_slug: "" },
      ],
    }));
  };

  const removeVariantPage = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.page_show];
      updatedVariants.splice(index, 1);
      return { ...prevVal, page_show: updatedVariants };
    });
  };

  const fetchSubCategories = async () => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      brand: "brands",
    };

    try {
      const result = await getbrandDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSubCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchGender();
    fetchSubCategories();
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Collection</h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group row">
                    <div className="col-md-6">
                        <label htmlFor="collection_name">
                          Collection Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="collection_name"
                          name="collection_name"
                          placeholder="Enter Collection Name"
                          value={formData.collection_name}
                          onChange={(e) =>
                            handleInputChange(e.target.value, "collection_name")
                          }
                        />
                        {errors.collection_name && (
                          <p style={{ color: "red" }}>
                            {errors.collection_name}
                          </p>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="title">
                          Title<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          name="title"
                          placeholder="Enter Title"
                          value={formData.title}
                          onChange={(e) =>
                            handleInputChange(e.target.value, "title")
                          }
                        />
                        {errors.title && (
                          <p style={{ color: "red" }}>
                            {errors.title}
                          </p>
                        )}
                      </div>
                      </div>

                      <div className="row">
                        {formData.variants.map((variant, index) => (
                          <div key={index} className="col-md-2">
                            <div className="form-group">
                              <label htmlFor={`gender_${index}`}>Gender</label>
                              <select
                                className="form-control"
                                id={`gender_${index}`}
                                name={`gender_${index}`}
                                value={`${variant.gender_id}-${variant.name}-${variant.slug}`}
                                onChange={(e) =>
                                  handleVariantChange(e.target.value, index)
                                }
                              >
                                <option value="">Select Gender</option>
                                {gender.map((genders) => (
                                  <option
                                    key={genders._id}
                                    value={`${genders._id}-${genders.name}-${genders.gender_slug}`}
                                  >
                                    {genders.name}
                                  </option>
                                ))}
                              </select>
                              <br />
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="btn btn-danger remove-variant"
                                  onClick={() => removeVariant(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={addVariant}
                          >
                            Add Gender
                          </button>
                        </div>
                      </div>

                      <div className="row">
                        {formData.page_show.map((page_show, index) => (
                          <div key={index} className="col-md-2">
                            <div className="form-group">
                              <label htmlFor={`page_show_${index}`}>Page</label>
                              <select
                                className="form-control"
                                id={`page_show_${index}`}
                                name={`page_show_${index}`}
                                value={`${page_show.subcategory_name}-${page_show.subcategory_id}-${page_show.subcategory_slug}`}
                                onChange={(e) =>
                                  handleVariantChangePage(e.target.value, index)
                                }
                              >
                                <option value="">Select Page</option>
                                <option value="home">Home</option>
                                {subCategories.map((subcategory) => (
                                  <option
                                    key={subcategory._id}
                                    value={`${subcategory.name}-${subcategory._id}-${subcategory.subcategory_slug}`}
                                  >
                                    {subcategory.name}
                                  </option>
                                ))}
                              </select>
                              <br />
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="btn btn-danger remove-variant"
                                  onClick={() => removeVariantPage(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={addVariantPage}
                          >
                            Add Page
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="fullname">Image</label>

                        <ImgCrop rotate aspect={1299 / 864}>
                          <Upload
                            className="avatar-uploader"
                            name="collection_image"
                            onChange={({ fileList }) =>
                              handleFileChange1(fileList)
                            }
                            listType="picture-card"
                            fileList={null}
                            beforeUpload={() => false}
                            style={{ width: "100%", height: "100%" }}
                          >
                            {formData.collection_image ? (
                              <img
                                src={URL.createObjectURL(
                                  formData.collection_image
                                )}
                                alt="subcategory"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div>
                                <svg
                                  className="icon"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="image"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                  ></path>
                                </svg>
                                <div className="ant-upload-text">Upload</div>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                        <p style={{ color: "blue" }}>
                          Recommended size: 1299x864(width x height)px
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span> 
                          Processing...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
        <Link to="/collectionList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCategoryModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCollectionData;
