import React,{useState,useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import "../Contact Us/ContactUs.css";
import { statusCategoryData } from '../Controller/Gender';
import { Link, useNavigate } from "react-router-dom";

const ChangeGenderpopup = ({  popUpOpenchange, togglePopUpchange, itemIdToChange, status }) => {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const handleCancel = () => {
    togglePopUpchange();
  };

  const handleDelete = async () => {
    const options = { id: itemIdToChange, status: status };
    try {
      const result = await statusCategoryData(options, adminInformation.token);
      if (result.data.status === true) {
        togglePopUpchange(); // Close the dialog only if result.data.status is true
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error updating category status:", error);
    }
  };

  return (
    <Dialog
      open={popUpOpenchange}
      onClose={togglePopUpchange}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUpchange}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you really want to change status?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Ok
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ChangeGenderpopup;
