import React, { useState,useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { updateHomeformData,getHomeData,getbrandDatafetch,getproductDatafetch,getproductcolorDatafetch} from '../../Controller/Brand';
import { getcollectionDatafetch} from '../../Controller/Home';
import { getFeacturedata} from "../../Controller/Product";
import AddBrandpopup from "./EditBrandpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Brand.css";
import { useParams,useNavigate,Link} from "react-router-dom";
import {imageURL} from '../../Config/constant';
import ImgCrop from 'antd-img-crop';

function EditBrandData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [subCategories, setSubCategoryData] = useState([]);
  const [feactureList, setFeactureData] = useState([]);
  const { edit } = useParams();
  const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const [productlist, setproductCategoryData] = useState([]);
    const [productcolorlist, setproductColorData] = useState([]);
    const [collectionlist, setCollectionData] = useState([]);
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      page_name: "",
      title: "",
      type: "",
      product_id: "",
      brand_name: "",
      description:"",
              button_title:"",
      product_color: "",
      image: "",
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
     const [fileInputs, setFileInputs] = useState({
      image: null,
    });
    
    // const handleFileChange1 = (e) => {
    //   const file = e.target.files[0];
    //   setFileInputs((prevFileInputs) => ({
    //     ...prevFileInputs,
    //     image: file,
    //   }));
    // };
    const fetchFeacture = async () => {
      const options = {};
      try {
        const result = await getFeacturedata(options, adminInformation.token);
        if (result.data && result.data.response) {
          const responseData = result.data.response.result;
          setFeactureData(responseData);
        } else {
          console.error("Unexpected response format:", result.data);
        }
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    const handleFileChange1 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, image: file }));
      }
    };
   
     const handleInputChange = (e) => {
      const { name, value} = e.target;
        setFormData((prevVal) => ({
          ...prevVal,
          [name]: value,
        }));
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
    };
    
    
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.page_name) {
        setErrors((preError) => ({
          ...preError,
          page_name: 'Page name is required.',
        }));
      }else if (!formData.type) {
        setErrors((preError) => ({
          ...preError,
          type: 'Type is required.',
        }));
      }else{
         setIsSubmitting(true);
        const updateResult = await updateHomeformData(formData, adminInformation.token);
        if(updateResult.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
                page_name: "",
              title: "",
              type: "",
              product_id: "",
              brand_name: "",
              description:"",
              button_title:"",
              product_color: "",
              image: null,
            }));
            setIsSubmitting(false);
          togglePopUp();
        }else{
        //   localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
 /*************************************************************
 * Function Name  : getbannerdata
 * Purposs        : This function is used get data
 * Created Date   : 09-01-2024
 *************************************************************/
 const getbannerdata = async () => {
  const options = { id: edit };
  try {
    const result = await getHomeData(options, adminInformation.token);
    console.log( result.data.response.result);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      // Replace _id with id in the response data
      const modifiedData = { ...responseData, id: responseData._id };
      delete modifiedData._id; // Remove the _id property

      setFormData(modifiedData);
      setFileInputs({
        image: responseData.image || null,
      });
        fetchSubCategories(responseData.page_name);
        fetchProductCategories(responseData.brand_name);
        fetchProductcolor(responseData.product_id);
    } else {
      // localStorage.removeItem('TOKEN');
      //   localStorage.removeItem('ADMIN-INFO');
      //   navigate("/login");
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};
const fetchProductCategories = async (brand_name) => {
  const options = {
    // Add any additional options needed for fetching sub-categories
    subcategory_slug: brand_name,
  };

  try {
    const result = await getproductDatafetch(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      setproductCategoryData(responseData);
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching sub-category data:", error);
  }
};
const fetchSubCategories = async (page_name) => {
  const options = {
    // Add any additional options needed for fetching sub-categories
    brand: page_name,
  };

  try {
    const result = await getbrandDatafetch(options, adminInformation.token);
    console.log(result);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      setSubCategoryData(responseData);
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching sub-category data:", error);
  }
};


const handleProductSelect = (brand_name) => {
  
  fetchProductCategories(brand_name);
};
const fetchProductcolor = async (brand_name) => {
  const options = {
    // Add any additional options needed for fetching sub-categories
    id: brand_name,
  };

  try {
    const result = await getproductcolorDatafetch(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.colors;
      console.log(responseData);
      setproductColorData(responseData);
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching sub-category data:", error);
  }
};
const handleProductcolorSelect = (brand_name) => {

  fetchProductcolor(brand_name);
};
const handleCategorySelect = (page_name) => {
  
  fetchSubCategories(page_name);
};
const getcollectiondata = async () => {
  const options = {  };
  try {
    const result = await getcollectionDatafetch(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      setCollectionData(responseData);
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};
  useEffect(() => {
    window.scrollTo(0, 0);
    getbannerdata();
    fetchFeacture();
    getcollectiondata();
  }, []);
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
 

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Brand </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                      
                    <div className="col-md-6">
  <label htmlFor="fullname">Page Name <span style={{ color: "red" }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e, "page_name"); // Pass event and field name
      handleCategorySelect(e.target.value);
    }}
    id="page_name"   value={formData.page_name}
    name="page_name"
  >
    <option value="">Select Page Name</option>
    <option value="home">Home Page</option>
    <option value="brands">Brand Page</option>
  </select>
  {errors?.page_name ? <p style={{ color: "red" }}>{errors?.page_name}</p> : ''}
</div>


<div className="col-md-6">
  <label htmlFor="exampleInputText">Brand</label>
  <select
  className="form-control"
  id="brand_name"
  name="brand_name"
  
  onChange={(e) => {
    handleInputChange(e, "brand_name"); // Pass event and field name
    handleProductSelect(e.target.value);
  }}
  value={formData.brand_name}
>
  <option value="">Select Brand Name</option>
  {subCategories.map((subCategory) => (
    <option 
      key={subCategory._id} 
      value={subCategory.subcategory_slug} 
      selected={subCategory.subcategory_slug === formData.brand_name}
    >
      {subCategory.name}
    </option>
  ))}
</select>


 
</div>
<div className="col-md-6">
  <label htmlFor="fullname">Type <span style={{ color: "red" }}>*</span></label>
  <select
    className="form-control"
    onChange={(e) => {
      handleInputChange(e, "type"); 
    }}
    id="type"   value={formData.type}
    name="type"
  >
    <option value="">Select Type</option>
    <option value="hot_list">Hot List</option>
    <option value="latest_arrival">Latest Arrival</option>
    <option value="featured_product">Featured Product</option>
    <option value="new_brand_collection">New Brand Collection</option>
    <option value="bestsellers">Bestsellers</option>
    {collectionlist.map((product) => (
      <option key={product._id} value={product.name} selected={product.name === formData.type}>
        {product.name}
      </option>
     ))}
  </select>
  {errors?.type ? <p style={{ color: "red" }}>{errors?.type}</p> : ''}
</div>
{formData.type === "featured_product" &&  (
   <div className="col-md-6">
     <label htmlFor="fullname">Title</label>
    <select
                                  className="form-control"
                                  id="title"
                                  name="title" value={formData.title}
                                  onChange={handleInputChange}
                                >
    <option value="">Select Feacture </option>
                            {feactureList.map((genders) => (
                              <option key={genders._id} value={genders.name}>
                                {genders.name}
                              </option>
                            ))}
                            </select>
  </div>
)}
{formData.type !== "new_brand_collection" && formData.type !== "bestsellers" && formData.type !== "featured_product" && (
   <>
   <div className="col-md-6">
     <label htmlFor="title">Title</label>
     <input
       type="text"
       className="form-control"
       id="title"
       name="title"
       value={formData.title}
       onChange={handleInputChange}
       placeholder="Enter Title"
     />
     {errors?.title && <p style={{ color: "red" }}>{errors?.title}</p>}
   </div>
   <div className="col-md-6">
     <label htmlFor="description">Description</label>
     <textarea
       className="form-control"
       id="description"
       value={formData.description}
       name="description"
       onChange={handleInputChange}
       rows="3"
     ></textarea>
     {errors?.description && <p style={{ color: "red" }}>{errors?.description}</p>}
   </div>
   <div className="col-md-6">
     <label htmlFor="button_title">Button Title</label>
     <input
       type="text"
       className="form-control"
       id="button_title"
       name="button_title"
       value={formData.button_title}
       onChange={handleInputChange}
       placeholder="Enter Button Title"
     />
     {errors?.button_title && <p style={{ color: "red" }}>{errors?.button_title}</p>}
   </div>
 </>
                        )}
                      </div>
                      <div className="form-group row">
  {formData.type === "bestsellers" && (
    <>
      <div className="col-md-6">
        <label htmlFor="fullname">Product</label>
        <select
          className="form-control"
          onChange={(e) => {
            handleInputChange(e, "product_id"); // Pass event and field name
            handleProductcolorSelect(e.target.value);
          }}
          id="product_id"
          name="product_id"
          value={formData.product_id} // Set the value attribute to ensure correct selection
        >
          <option value="">Select Product</option>
          {productlist.map((product) => (
            <option key={product._id} value={product._id}>
              {product.product_name}
            </option>
          ))}
        </select>
        {errors.product_id && <p style={{ color: "red" }}>{errors.product_id}</p>}
      </div>
      <div className="col-md-6">
        <label htmlFor="fullname">Product Color</label>
        <select
          className="form-control"
          onChange={(e) => {
            handleInputChange(e, "product_color"); // Pass event and field name
          }}
          id="product_color"
          name="product_color"
          value={formData.product_color} // Set the value attribute to ensure correct selection
        >
          <option value="">Select Color</option>
          {productcolorlist.map((color, index) => (
            <option key={index} value={color}>
              {color}
            </option>
          ))}
        </select>
        {errors.product_color && <p style={{ color: "red" }}>{errors.product_color}</p>}
      </div>
    </>
  )}

  {formData.type !== "bestsellers" && (
    <div className="col-md-6">
      <label htmlFor="fullname">Image</label>
      <ImgCrop rotate aspect={1299/864}>
<Upload
  className="avatar-uploader"
  name="image"
  onChange={({ fileList }) => handleFileChange1(fileList)}
  listType="picture-card"
  fileList={null}
  beforeUpload={() => false}
  style={{ width: '100%', height: '100%' }}
>
{formData.image ? (
  <img
  src={typeof formData.image === 'string' ? `${imageURL}${formData.image}` : URL.createObjectURL(formData.image)}
  alt="subcategory"
  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
/>
) : (
  <div>
    <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
    </svg>
    <div className="ant-upload-text">Upload</div>
  </div>
)}

</Upload>
</ImgCrop>
<p style={{ color: "blue" }}>Recommended size: 1299x864(width x height)px</p>

    </div>
  )}
</div>

<button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span> 
                          Processing...
                          </>
                        ) : (
                          "Update"
                        )}
                      </button>
                      <Link to="/brandListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddBrandpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBrandData;
