import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
// import { BsThreeDots } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import OrderPopUp from "./OrderPopUp";
import OrdercancelPopUp from "./OrdercancelPopUp";
import Pagination from "@mui/material/Pagination";
import { getBulkOrderDatafetch } from "../Controller/Order";
import { BsThreeDots } from "react-icons/bs";

function BulkOrder() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdPass, setItemId] = useState(null);
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenstatus, setPopUpOpenstatus] = useState(false);
  const [itemIdPasscancel, setItemIdcancel] = useState(null);
  const [itemIdTocancelstatus, setItemIdcancelstatus] = useState(null);
  const [orderList, setOrderData] = useState([]);
  const togglePopUp = (itemId, deliveryStatus) => {
    setPopUpOpen(!popUpOpen);
    setItemId(itemId);
    setItemIdStatus(deliveryStatus);
    getOrderdata();
  };

  const togglePopUpstatus = (itemId, deliveryStatus) => {
    setPopUpOpenstatus(!popUpOpenstatus);
    setItemIdcancel(itemId);
    setItemIdcancelstatus(deliveryStatus);
    getOrderdata();
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getOrderdata = async (page, customer_email) => {
    const options = {
      page: page,
      customer_email: customer_email,
    };

    try {
      const result = await getBulkOrderDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.bulkOrder;
        const totalcount = result.data.response.result.totalcount;
        setOrderData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getOrderdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getOrderdata(currentPage, value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderdata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const capitalizeFirstChar = (str) => {
    if (str) {
      return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    }
    return "";
  };

  const dropdownMenu = (itemId, deliveryStatus) => {
    let menuOptions;

    if (deliveryStatus === "initialize") {
      menuOptions = (
        <>
          <Menu.Item key="2" onClick={() => togglePopUp(itemId, "processing")}>
            <FaEdit />
            <span className="show_span_edit">Processing</span>
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => togglePopUpstatus(itemId, "order cancel")}
          >
            <FaEdit />
            <span className="show_span_edit">Cancel Order</span>
          </Menu.Item>
        </>
      );
    } else if (deliveryStatus === "processing") {
      menuOptions = (
        <>
          <Menu.Item
            key="2"
            onClick={() => togglePopUp(itemId, "out of delivery")}
          >
            <FaEdit />
            <span className="show_span_edit">Out of Delivery</span>
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => togglePopUpstatus(itemId, "order cancel")}
          >
            <FaEdit />
            <span className="show_span_edit">Cancel Order</span>
          </Menu.Item>
        </>
      );
    } else if (deliveryStatus === "out of delivery") {
      menuOptions = (
        <>
          <Menu.Item key="4" onClick={() => togglePopUp(itemId, "delivered")}>
            <MdDelete />
            <span className="show_span_edit">Delivered</span>
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={() => togglePopUpstatus(itemId, "order cancel")}
          >
            <FaEdit />
            <span className="show_span_edit">Cancel Order</span>
          </Menu.Item>
        </>
      );
    } else if (deliveryStatus === "delivered") {
      menuOptions = (
        <Menu.Item key="5">
          <span className="show_span_edit">Delivered</span>
        </Menu.Item>
      );
    }

    return <Menu>{menuOptions}</Menu>;
  };

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Bulk Order List </h1>
                <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search email"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                      style={{ color: "#891621", fontWeight: "bold" }}
                    >
                      Bulk Order Table List
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S. No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Shop Name</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Product Price</th>
                            <th scope="col">Product Qty</th>
                            <th scope="col">Received on Date</th>
                            <th scope="col">Last Status Updated On</th>
                            {/*}    <th scope="col">Status</th>
                            <th scope="col">Action</th>*/}
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.length > 0 ? (
                            orderList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customCheckTitle${item.id}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheckTitle${item.id}`}
                                    >
                                      {(currentPage - 1) * 5 + index + 1}
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  {capitalizeFirstChar(item.customer_name)}
                                </td>
                                <td>{item.customer_email}</td>
                                <td>{item.customer_phone}</td>
                                <td>
                                  {capitalizeFirstChar(item.customer_address)}
                                </td>
                                <td>
                                  {capitalizeFirstChar(item.product_name)}
                                </td>
                                <td>{item.price}</td>
                                <td>{item.qty}</td>
                                <td>{formatDate(item.createdAt)}</td>
                                <td>{formatDate(item.updatedAt)}</td>
                                {/*} <td style={{ color: item.delivery_status === "order cancel" ? 'red' : '#19a170' }}>
                                {capitalizeFirstChar(item.delivery_status)}
                              </td>
                              <td>
                                {item.delivery_status !== "delivered" && item.delivery_status !== "cancel order" ? (
                                  <Dropdown overlay={() => dropdownMenu(item._id, item.delivery_status)} placement="bottomLeft" arrow>
                                    // <FaRegCommentDots />
                                    <BsThreeDots />
                                    <BsThreeDots />
                                  </Dropdown>
                                ) : null}
                                </td>*/}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 5)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderPopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdPass={itemIdPass}
        itemIdToStatus={itemIdToStatus}
      />
      <OrdercancelPopUp
        popUpOpenstatus={popUpOpenstatus}
        togglePopUpstatus={togglePopUpstatus}
        itemIdPasscancel={itemIdPasscancel}
        itemIdTocancelstatus={itemIdTocancelstatus}
      />
    </div>
  );
}

export default BulkOrder;
